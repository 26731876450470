import { BACKEND_URL } from "../../views/common/utils/constants";
import { IAPIResponse } from "../../views/common/utils/interfaces";
import { errorToastify } from "../../views/common/utils/Toasty";
import { CustomAxios } from "../customAxios";

const tempDummy = {
  status: true,
  message: "Developers List",
  count: 3,
  next: null,
  previous: null,
  data: [
    {
      url: "http://localhost:8000/api/admin/sellers/c840fb01-0a7d-4b1d-aa59-83ccfb11a843/",
      first_name: "Habeeb",
      last_name: "Oluwo",
      status: "pending",
      submission_date: null,
      slug: "c840fb01-0a7d-4b1d-aa59-83ccfb11a843",
      user_type: "seller",
    },
    {
      url: "http://localhost:8000/api/admin/sellers/beba2249-8a03-4a0e-948a-bd8310d0f410/",
      first_name: "Habeeb",
      last_name: "Oluwo",
      status: "pending",
      submission_date: null,
      slug: "beba2249-8a03-4a0e-948a-bd8310d0f410",
      user_type: "seller",
    },
    {
      url: "http://localhost:8000/api/admin/sellers/b5641819-eaa6-4c32-8e4e-b37a17ab103f/",
      first_name: "Habeeb",
      last_name: "Oluwo",
      status: "pending",
      submission_date: "2024-10-13T00:53:41.739199+01:00",
      slug: "b5641819-eaa6-4c32-8e4e-b37a17ab103f",
      user_type: "seller",
    },
  ],
};

export const getFeaturesList = async () => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/listings/features/`
    );

    // const result = tempDummy;

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      // errorToastify(error);
    }
  }
};

export const getPropertyTypesList = async () => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/listings/property-types`
    );

    // const result = tempDummy;

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      // errorToastify(error);
    }
  }
};

export const getPropertyTypeClassificationsList = async () => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/listings/property-types/classifications/`
    );

    // const result = tempDummy;

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      // errorToastify(error);
    }
  }
};

export const getPropertyTypeSubClassificationsList = async () => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/listings/property-types/classifications/subs`
    );

    // const result = tempDummy;

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      // errorToastify(error);
    }
  }
};
