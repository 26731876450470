import React from "react";
import ErrorScreen from "../components/ErrorScreen/ErrorScreen";
import { withErrorBoundary } from "./ErrorBoundary";
import IdleTimer from "./IdleTimer";

const UnprotectedRoute = ({
  children,
  isAuthenticated = false,
}: {
  children: JSX.Element;
  isAuthenticated?: boolean;
}) => {
  return withErrorBoundary(
    <IdleTimer isAuthenticated={isAuthenticated}>{children}</IdleTimer>,
    {
      fallback: <ErrorScreen />,
    }
  );
};

export default UnprotectedRoute;
