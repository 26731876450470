import { BACKEND_URL } from "../../views/common/utils/constants";
import {
  IAPIResponse,
  IFilterFetch,
} from "../../views/common/utils/interfaces";
import { errorToastify } from "../../views/common/utils/Toasty";
import { CustomAxios } from "../customAxios";

const tempDummyList = {
  status: true,
  message: "Developers List",
  count: 3,
  next: null,
  previous: null,
  data: [
    {
      id: `${BACKEND_URL}/admin/sellers/c840fb01-0a7d-4b1d-aa59-83ccfb11a843/`,
      url: `${BACKEND_URL}/admin/sellers/c840fb01-0a7d-4b1d-aa59-83ccfb11a843/`,
      first_name: "Habeeb",
      last_name: "Oluwo",
      status: "pending",
      submission_date: null,
      slug: "c840fb01-0a7d-4b1d-aa59-83ccfb11a843",
      user_type: "seller",
    },
    {
      id: `${BACKEND_URL}/admin/sellers/beba2249-8a03-4a0e-948a-bd8310d0f410/`,
      url: `${BACKEND_URL}/admin/sellers/beba2249-8a03-4a0e-948a-bd8310d0f410/`,
      first_name: "Habeeb",
      last_name: "Oluwo",
      status: "pending",
      submission_date: null,
      slug: "beba2249-8a03-4a0e-948a-bd8310d0f410",
      user_type: "seller",
    },
    {
      id: `${BACKEND_URL}/admin/sellers/b5641819-eaa6-4c32-8e4e-b37a17ab103f/`,
      url: `${BACKEND_URL}/admin/sellers/b5641819-eaa6-4c32-8e4e-b37a17ab103f/`,
      first_name: "Habeeb",
      last_name: "Oluwo",
      status: "pending",
      submission_date: "2024-10-13T00:53:41.739199+01:00",
      slug: "b5641819-eaa6-4c32-8e4e-b37a17ab103f",
      user_type: "seller",
    },
  ],
};

const tempDummyDetail = {
  status: true,
  message: "Profile Detail",
  data: {
    url: `${BACKEND_URL}/admin/sellers/b5641819-eaa6-4c32-8e4e-b37a17ab103f/`,
    first_name: "Habeeb",
    last_name: "Oluwo",
    status: "pending",
    submission_date: "2024-10-13T00:53:41.739199+01:00",
    slug: "b5641819-eaa6-4c32-8e4e-b37a17ab103f",
    user_type: "seller",
    phone_number: "+2348131097519",
    company_name: "Habeeb and Sons",
    company_registration_number: "12348543",
    business_type: "sole_proprietor",
    website_url: "https://google.com",
    address: "Oluyole, Ibadan",
    city: {
      id: 1,
      name: "Mbot Abasi Kingdom",
    },
    lga: {
      id: 1,
      name: "Aba North",
    },
    state: {
      id: 1,
      name: "Abia State",
      code: "ABIA",
    },
    document: [],
    certificate_of_incorporation:
      "http://localhost:8000/media/documents/user_9/habeeb_and_sons_certificate_of_incorporation.pdf",
    real_estate_license:
      "http://localhost:8000/media/documents/user_9/habeeb_and_sons_real_estate_license.pdf",
    proof_of_address:
      "http://localhost:8000/media/documents/user_9/habeeb_and_sons_proof_of_address.pdf",
    tax_identification_number:
      "http://localhost:8000/media/documents/user_9/habeeb_and_sons_tax_identification_number.pdf",
    tax_clearance_cert:
      "http://localhost:8000/media/documents/user_9/habeeb_and_sons_tax_clearance_certificate.pdf",
    directors_valid_id:
      "http://localhost:8000/media/documents/user_9/habeeb_and_sons_directors_valid_id.pdf",
    update_status_url: `${BACKEND_URL}/admin/sellers/b5641819-eaa6-4c32-8e4e-b37a17ab103f/update-status`,
  },
};

export const getSellersList = async (filter?: IFilterFetch) => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/admin/sellers`
    );

    // const result = tempDummyList;

    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const getSellersDetails = async (inputData: any) => {
  const { id, url, slug } = inputData || {};

  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/admin/sellers/${slug}`
      // url
    );

    // const result = tempDummyDetail;

    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const postSellerApplicationAction = async ({
  slug,
  action,
  rejection_reason,
}: {
  slug: string;
  action: string;
  rejection_reason?: string;
}) => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().post(
      `${BACKEND_URL}/admin/sellers/${slug}/update-status`,
      {
        action,
        ...(action === "reject" ? { rejection_reason } : {}),
      }
    );
    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};
