import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListingInterface, ListingsInterface } from "../../../types";

interface ListingsState {
  loading: boolean;
  listings: ListingsInterface[] | ListingInterface[];
  filteredListings: (ListingsInterface | ListingInterface)[];
  error: string | null;
}

const initialState: ListingsState = {
  loading: false,
  listings: [],
  filteredListings: [],
  error: null,
};

const listingsSlice = createSlice({
  name: "listings",
  initialState,
  reducers: {
    fetchListings(state) {
      state.loading = true;
      state.error = null;
    },
    fetchListingsSuccess(state, action: PayloadAction<ListingsInterface[]>) {
      state.loading = false;
      state.error = null;
      state.listings = action.payload;
      state.filteredListings = action.payload;
    },
    setFilteredListings(state, action: PayloadAction<(ListingsInterface | ListingInterface)[]>) {
      state.filteredListings = action.payload;
    },
    clearFilteredListings(state) {
      state.filteredListings = state.listings
    },
    fetchListingsFailure(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    toggleFavoriteListing(state, action: PayloadAction<string>) {
      state.listings.map((listing) => {
        if (listing.lid !== action.payload) return listing;
        else {
          listing.saved_by_user = !listing.saved_by_user;
          return listing;
        }
      });
    },
  },
});

export const { fetchListings, fetchListingsFailure, fetchListingsSuccess , setFilteredListings, clearFilteredListings} =
  listingsSlice.actions;

export default listingsSlice.reducer;
