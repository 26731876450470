import { useEffect, useRef, useState } from "react";
import { getSavedListings } from "../../../../../../apis/listings/buyer";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import SingleListing from "../../../../../common/components/SingleListing";
import { USER_TYPES } from "../../../../../common/utils/constants";
import {
  IAPIResponse,
  IListings,
} from "../../../../../common/utils/interfaces";
import BuyerDashboardLayout from "../../components/DashboardLayout";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";

const SavedItems = () => {
  const { layoutSearchText } = useSelector((state: RootState) => state.search);

  const [listings, setListings] = useState<IListings[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const [showStatesModal, setShowStatesModal] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const filterRef = useRef<HTMLDivElement | null>(null);
  const sortRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    fetchListings();
  }, [layoutSearchText]);

  const fetchListings = async () => {
    setIsLoading(true);
    const response: IAPIResponse = await getSavedListings(layoutSearchText);
    setIsLoading(false);

    if (response?.status) {
      setListings(response.data);
    } else {
    }
  };

  const hideModals = (event: React.MouseEvent<HTMLDivElement>) => {
    if (showStatesModal) return;
    if (
      showFilter &&
      filterRef.current &&
      !filterRef.current.contains(event?.target as Node)
    ) {
      setShowFilter(false);
    }
    if (
      showSort &&
      sortRef.current &&
      !sortRef.current.contains(event?.target as Node)
    ) {
      setShowSort(false);
    }
  };

  return (
    <BuyerDashboardLayout>
      <div className="buyer-dashboard-core">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <span className="_page-title">Saved Properties</span>
          <Link to="/property-search">
            <Button
              variant="text"
              style={{
                padding: 9,
                minWidth: "unset",
              }}
            >
              Browse Properties
            </Button>
          </Link>
        </div>
        {/* <div className="grid sm:grid-cols-2 gap-10"> */}
        <div className="dashboard-layout">
          <div className="listing-group">
            {isLoading ? (
              <span className="body-description no-records">Loading...</span>
            ) : listings?.length ? (
              (listings as any[]).map((listing) => (
                //   listt.map((listing) => (
                //   <FeaturedProperty key={listing.name} listing={listing} />
                <SingleListing
                  section={USER_TYPES.BUYER}
                  listingData={listing}
                  isDefaultSaved
                  removeOnUnSave
                />
              ))
            ) : (
              <span className="body-description no-records">
                There are no listings yet
              </span>
            )}
          </div>
        </div>
      </div>
    </BuyerDashboardLayout>
  );
};

export default SavedItems;
