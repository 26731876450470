import React, { useState } from "react";
import {
  APP_CURRENCIES,
  APP_CURRENCIES_RATES,
  APP_DEFAULT_CURRENCY,
} from "../utils/constants";
import { ClickAwayListener, Tooltip } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrency } from "../../../store/slices/general/currencySlice";

const CurrencySwitch = () => {
  const { activeCurrency } = useSelector((state: RootState) => state.currency);

  const dispatch: AppDispatch = useDispatch();

  const [selectedCurrency, setSelectedCurrency] = useState(activeCurrency);
  const [showList, setShowList] = useState(false);

  const selectCurrency = async (currency: string) => {
    setShowList(false);
    setSelectedCurrency(currency);
    localStorage.setItem("defaultCurrency", currency);
    dispatch(updateCurrency(currency));
  };

  return (
    <div className="currency-switch">
      <Tooltip title="Set currency">
        <div
          className="currency-selected"
          onClick={() => setShowList((currentState) => !currentState)}
        >
          {selectedCurrency}
          <KeyboardArrowDown />
        </div>
      </Tooltip>

      {showList && (
        <ClickAwayListener onClickAway={() => setShowList(false)}>
          <div className="_list">
            {Object.values(APP_CURRENCIES).map((currency: string) => (
              <div
                key={currency}
                className="_each"
                onClick={() => selectCurrency(currency)}
              >
                {currency}
              </div>
            ))}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default CurrencySwitch;

export const DisplayAmount = ({
  amount,
  format = "jsx",
}: {
  amount: number | string;
  format?: "jsx" | "string";
}) => {
  const { activeCurrency } = useSelector((state: RootState) => state.currency);
  const exchangeRate = APP_CURRENCIES_RATES[activeCurrency];

  const doCurrencyConvert = (amount: number | string) => {
    if (typeof amount === "string") {
      amount = Number(amount);
    } else if (typeof amount !== "number") {
      return amount;
    }

    let outAmount;
    if (activeCurrency === APP_DEFAULT_CURRENCY) {
      outAmount = amount;
    } else {
      outAmount = amount / exchangeRate;
    }

    const formattedAmount = new Intl.NumberFormat('en-Us', {maximumFractionDigits: 2, minimumFractionDigits: 2}).format(outAmount);
    return formattedAmount;
  };

  const getCurrencySymbol = () => {
    switch (activeCurrency) {
      case "USD":
        return "\u{0024}";

      case "GBP":
        return "\u{00A3}";

      case "EUR":
        return "\u{20AC}";

      case "CAD":
        return "C\u{0024}";

      default:
        return "\u{020A6}"; // NGN
    }
  };

  return format === "jsx" ? (
    <span className="flex">
      <span className="mr-1">{getCurrencySymbol()}</span>
      <span>{doCurrencyConvert(amount)}</span>
    </span>
  ) : (
    `${getCurrencySymbol()} ${doCurrencyConvert(amount)}`
  );
};
