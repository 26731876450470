import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../types";

const BuyPropertyModal: React.FC<CustomModalProps> = ({
  handleClose,
  open,
}) => {
  return (
    <Modal onClose={handleClose} open={open}>
      <div className="absolute top-[50%] w-[90%] max-w-[600px] translate-y-[-50%] left-[50%] translate-x-[-50%] sm:w-[1124px] outline-none">
        <div className=" bg-[#E8EDE9] overflow-y-auto h-fit w-full rounded-[10px] py-10 px-6 sm:px-[50px]">
          <img
            onClick={handleClose}
            src="/assets/images/close-circle.svg"
            className="block ml-auto mb-4 cursor-pointer"
            alt=""
          />
          <h2 className=" text-2xl sm:text-3xl leading-10 text-center">
            This property is for demo purpose. <br />Kindly check back later
          </h2>
        </div>
      </div>
    </Modal>
  );
};

export default BuyPropertyModal;
