import { Link, useNavigate } from "react-router-dom";
import useInput from "../../../../hooks/useInput";
import HomeLayout, { Navbar } from "../../commonComponents/HomeLayout";
import { useState } from "react";
import PurchaseRequest from "../../modals/PurchaseRequest";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import PhoneInput from 'react-phone-number-input'

const Purchase = () => {
  const navigate = useNavigate();
  const firstName = useInput("");
  const lastName = useInput("");
  const email = useInput("");
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const address = useInput("");
  const message = useInput("");
  const [showModal, setShowModal] = useState(false);
  const listing = useSelector((state: RootState) => state.listing);
  return (
    <>
      <Navbar />
      <div className="flex flex-col sm:flex-row pb-5 sm:pb-0 px-6 sm:px-[74px] gap-y-10 gap-x-[30px]">
        <div className="card-shadow flex-1 p-5 sm:p-[30px]">
          <div
            onClick={() => navigate(-1)}
            className="flex gap-x-[22px] mb-[9px] cursor-pointer"
          >
            <img src="/assets/images/arrow-left.svg" alt="" />
            <p className="text-[16px] text-textBlack">Go back</p>
          </div>
          <h3 className="h3">Purchase Property</h3>
          <div className="flex flex-col gap-y-[35px] mt-[60px]">
            <div className="contents sm:grid grid-cols-2 gap-x-[32px]">
              <div>
                <label htmlFor="firstName" className="custom__label">
                  First Name{" "}
                  <span className="text-[#CD0101] font-semibold">*</span>
                </label>
                <input
                  type="text"
                  onChange={firstName.onChange}
                  value={firstName.value}
                  id="firstName"
                  className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
                />
              </div>
              <div>
                <label htmlFor="lastName" className="custom__label">
                  Last Name{" "}
                  <span className="text-[#CD0101] font-semibold">*</span>
                </label>
                <input
                  type="text"
                  onChange={lastName.onChange}
                  value={lastName.value}
                  id="lastName"
                  className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
                />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="custom__label">
                Email address{" "}
                <span className="text-[#CD0101] font-semibold">*</span>
              </label>
              <input
                type="email"
                onChange={email.onChange}
                value={email.value}
                id="email"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div>
              <label htmlFor="phoneNumber" className="custom__label">
                Phone Number
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                international
                value={phoneNumber}
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
                onChange={setPhoneNumber}
                defaultCountry="NG"
              />
            </div>
            <div>
              <label htmlFor="address" className="custom__label">
                Address
              </label>
              <input
                type="text"
                onChange={address.onChange}
                value={address.value}
                id="address"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div>
              <label htmlFor="message" className="custom__label">
                Message (optional)
              </label>
              <input
                type="text"
                onChange={message.onChange}
                value={message.value}
                id="message"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>

            <button
              className="filled-button w-full"
              onClick={() => setShowModal(true)}
            >
              SUBMIT PURCHASE REQUEST
            </button>
          </div>
        </div>
        <section className="sm:w-[561px] py-[15px] px-5 card-shadow">
          <img
            src={listing.listing?.files[0].file}
            className="w-full sm:h-[354px]"
            alt=""
          />
          <div className="mt-[25px] text-textBlack">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[#686868] text-[16px]">Price</p>
                <div className="mt-[5px] font-semibold text-[28px] flex gap-x-1 items-center">
                  {" "}
                  <img src="/assets/images/naira.svg" alt="" />{" "}
                  {listing.listing?.price}
                </div>
              </div>
              <div className="flex gap-x-[22px]">
                <span className="font-medium text-[22px] text-textBlack">
                  {listing.listing?.rating}
                </span>
                <img src="/assets/images/star-filled.svg" alt="" />
              </div>
            </div>
            <h3 className="font-semibold text-[20px] text-textBlack mt-[30px] mb-[5px]">
              {listing.listing?.name}
            </h3>
            <p className="text-[14px] h-[64px] overflow-y-hidden">
              {listing.listing?.description}
            </p>
            <h3 className="font-semibold text-[16px] mt-[30px] mb-[5px]">
              Location
            </h3>
            <p className="text-[16px] ">{listing.listing?.address}</p>
            <div className="flex items-center mt-[30px] pt-10 border-t">
              <img src="/assets/images/diaspora.png" alt="" />
              <div className="ml-[18px] mr-auto">
                <p className="text-textBlack font-medium text-[16px]">
                  DIASPORA
                </p>
                <p className="text-[#686868] text-[12.5px]">Realty</p>
              </div>
              <div className="flex gap-x-8">
                <img src="/assets/images/mail3.svg" alt="" />
                <img src="/assets/images/phone2.svg" alt="" />
              </div>
            </div>
          </div>
        </section>
        <PurchaseRequest
          open={showModal}
          handleClose={() => setShowModal(false)}
        />
      </div>
    </>
  );
};

export default Purchase;
