import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import DeleteIcon from "@mui/icons-material/Delete";
// import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Link } from "react-router-dom";
import CustomBadge from "../../../../../common/components/CustomBadge";
import {
  IconDocumentPage,
  IconViewEye,
} from "../../../../../common/components/LayoutIcons";
import CustomTable, {
  HeadCell,
} from "../../../../../common/components/CustomTable/CustomTable";
import { IDeveloperDetails } from "../../../../../common/utils/interfaces";
import { Download } from "@mui/icons-material";
import { developerDocuments } from "../../../../../common/utils/constants";
import { Button } from "@mui/material";

interface Data {
  id: number;
  calories: number;
  carbs: "approved" | "pending" | "rejected";
  fat: number;
  name: string;
  actions: string;
}

function createData(
  id: number,
  name: string,
  calories: number,
  fat: number,
  carbs: "approved" | "pending" | "rejected",
  actions: string
): Data {
  return {
    id,
    name,
    calories,
    fat,
    carbs,
    actions,
  };
}

const rows = [
  createData(1, "Real Estate License", 305, 3.7, "approved", "null"),
  createData(2, "Certificate of Incorporation", 452, 25.0, "pending", "null"),
  createData(3, "Proof of Address", 262, 16.0, "rejected", "null"),
  createData(4, "Tax Identification Number", 159, 6.0, "approved", "null"),
];

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Document Title",
  },
  /* temporarily hidden */

  /* 
  {
    id: "carbs",
    numeric: false,
    disablePadding: false,
    label: "Approval Status",
    align: "center",
  }, */
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];

export default function DocumentsTable({
  developerData,
}: {
  developerData: IDeveloperDetails | null;
}) {
  const tableTitle = { mainText: "Submitted Documents", subText: "" };

  const docsData = Object.keys(developerDocuments).map((doc: string) => ({
    name: doc,
    identifier: developerDocuments[doc],
    url: (developerData as any)?.[developerDocuments[doc]],
  }));

  const getBadgeMode = (text: string) => {
    switch (text) {
      case "approved":
        return "success";

      case "rejected":
        return "error";

      default:
        return "info";
    }
  };

  const MappedTableRows = ({
    row,
    labelId,
    isItemSelected,
  }: {
    row: any;
    labelId: string;
    isItemSelected: boolean;
  }) => (
    <>
      <TableCell padding="checkbox">
        <div className="icon-circle-grey _small">
          <IconDocumentPage
            style={{
              width: 14,
              filter: "grayscale(1) brightness(2.5)",
            }}
          />
        </div>
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.name}
      </TableCell>
      {/* temporarily hidden */}
      {/* <TableCell align="center">
        <CustomBadge mode={getBadgeMode(row.carbs)}>{row.carbs}</CustomBadge>
      </TableCell> */}
      <TableCell align="center">
        {row.url ? (
          <a
            href={row.url}
            download={row.name}
            rel="noreferrer"
            target="_blank"
            style={{ marginLeft: "auto" }}
          >
            <Button
              variant="text"
              style={{
                padding: 4,
                minWidth: "unset",
                fontSize: 12,
              }}
            >
              <Download /> Download
            </Button>
          </a>
        ) : (
          <Button
            variant="text"
            disabled
            style={{
              padding: 4,
              minWidth: "unset",
              fontSize: 12,
              marginLeft: "auto",
            }}
          >
            <Download /> Download
          </Button>
        )}
      </TableCell>
    </>
  );

  return (
    <CustomTable
      tableType="card-table"
      tableTitle={tableTitle}
      headCells={headCells}
      rows={docsData}
      mappedTableRows={MappedTableRows}
      hideChangePerPage
      hidePagination
      headerEndIcon={<IconDocumentPage style={{ width: 25 }} />}
    />
  );
}
