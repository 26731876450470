import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../../../../store/store";
import DashboardNavBar from "../DashboardNav/DashboardNavBar";
import { buyerDashboardNavList } from "../../../../../common/global/DashboardRoutes";
import StateModal from "../../../../modals/StateModal";
import { logoutUser } from "../../../../../../apis/accounts/auth";
import AccountVerification from "../../../../modals/AccountVerification";
import { setLayoutSearchText } from "../../../../../../store/slices/general/searchSlice";

const BuyerDashboardLayout = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();

  const [showFilter, setShowFilter] = useState(false);
  const [showStatesModal, setShowStatesModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSort, setShowSort] = useState(false);
  const filterRef = useRef<HTMLDivElement | null>(null);
  const sortRef = useRef<HTMLDivElement | null>(null);
  const toggleShowSort = () => setShowSort(!showSort);
  const debouncedSearchTerm = useDebouncedValue(searchQuery, 1000);
  const localUser = JSON.parse(localStorage.getItem("user") || "{}");
  const [showOtpModal, setShowOtpModal] = useState(
    !localUser?.is_account_verified
  );

  useEffect(() => {
    dispatch(setLayoutSearchText(""))
  }, [location]);

  useEffect(() => {
    dispatch(setLayoutSearchText(debouncedSearchTerm))
  }, [debouncedSearchTerm]);

  const hideModals = (event: React.MouseEvent<HTMLDivElement>) => {
    if (showStatesModal) return;
    if (
      showFilter &&
      filterRef.current &&
      !filterRef.current.contains(event?.target as Node)
    ) {
      setShowFilter(false);
    }
    if (
      showSort &&
      sortRef.current &&
      !sortRef.current.contains(event?.target as Node)
    ) {
      setShowSort(false);
    }
  };

  const closeOtpModal = () => {
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...localUser,
        is_account_verified: true,
      })
    );
    setShowOtpModal(false);
  };

  return (
    <div className="buyer">
      <AccountVerification
        isLoggedIn
        open={showOtpModal}
        handleClose={() => {}}
        submitAction={closeOtpModal}
        email={localUser.email}
      />
      <div className="h-[100vh] flex flex-col" onClick={hideModals}>
        <DashboardNavBar />
        <div className="sm:flex items-center px-6 sm:px-[50px] mt-[17px] buyer-dashboard-searchbar">
          <div
            className="card-shadow relative h-[60px] max-w-full w-[430px] rounded-[50px]"
            style={{ boxShadow: "0px 1px 3px 1px #4682B426" }}
          >
            <input
              type="text"
              className="h-full px-[18.5px] w-full text-textBlack2 text-[14px] sm:text-[16px] x-search"
              style={{ backgroundColor: "transparent", fontWeight: 300 }}
              placeholder="Search property names"
              value={searchQuery}
              onChange={(evt) =>  setSearchQuery(evt.target.value)}
            />
            <div className="h-full flex items-center justify-center absolute top-0 right-5">
              <img src="/assets/images/search.svg" alt="" />
            </div>
          </div>
          {/* <div className="flex gap-y-3 pt-6 sm:pt-0 flex-wrap sm:contents items-center">
            <div
              onClick={() => setShowFilter(!showFilter)}
              className="flex items-center gap-x-2 ml-6 text-[16px] cursor-pointer"
            >
              <img
                src="/assets/images/filter.svg"
                className="w-[18px] h-[18px]"
                alt=""
              />
              <span>Filter</span>
            </div>
            <span className="underline shrink-0 ml-6 mr-[39px] text-[16px] cursor-pointer">
              Clear all
            </span>
            <button className="filled-button w-full sm:w-fit">
              SAVE SEARCH
            </button>
          </div> */}
        </div>
        <div className="buyer-dashboard-main">
          <div className="buyer-dashboard-sidenav dashboard-nav-items _pc">
            {buyerDashboardNavList.map((navItem, index: number) => (
              <NavLink
                to={navItem.url}
                key={index}
                className="dashboard-nav-item"
                style={{
                  ...(navItem.title === "Logout"
                    ? { borderTop: "solid 1px #EAECF0" }
                    : {}),
                }}
                onClick={
                  navItem.title === "Logout"
                    ? (evt) => {
                        evt.stopPropagation();
                        evt.preventDefault();

                        dispatch(logoutUser());
                        navigate("/");
                      }
                    : () => {}
                }
              >
                <div className="icon-box">{navItem.icon}</div>
                <Typography style={{ color: "inherit" }}>
                  {navItem.title}
                </Typography>
              </NavLink>
            ))}
          </div>
          {children}
        </div>
        <StateModal
          open={showStatesModal}
          handleClose={() => setShowStatesModal(false)}
        />
      </div>
    </div>
  );
};

export default BuyerDashboardLayout;


const useDebouncedValue = (inputValue: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};
