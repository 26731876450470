import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../types";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import {
  loginFailure,
  signUpFailure,
} from "../../../store/slices/buyer/userSlice";

interface Props extends CustomModalProps {
  uploadDocument: () => Promise<any>;
  closeOnboardingModal?: () => void;
  isLoggedIn?: boolean;
}
const DocumentVerification: React.FC<Props> = ({
  open,
  handleClose,
  closeOnboardingModal,
  submitAction,
  isLoggedIn,
  uploadDocument,
}) => {
  const navigate = useNavigate();
  const { error } = useSelector((state: RootState) => state.user);
  const [documentSent, setDocumentSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const submitDocument = async () => {
    setIsLoading(true);
    const submitRequest = await uploadDocument();
    setIsLoading(false);
    if (submitRequest) setDocumentSent(true);
    // else handleClose()
  };

  const closeModal = () => {
    if(isLoggedIn) {
      closeOnboardingModal && closeOnboardingModal()
    } else {
      navigate("/sign-in")
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal__container text-center">
        {!documentSent && (
          <div className="my-10">
            <h2 className="modal__title">
              We'll verify your documents within 7 days
            </h2>
            <span className="modal__subtitle">
              You will receive a confirmation email once approved
            </span>
            {error && <span className="text-red-500 block">{error}</span>}
            <button onClick={submitDocument} className="modal__action">
              {isLoading ? (
                <span className="loader"></span>
              ) : (
                "SUBMIT FOR VERIFICATION"
              )}
            </button>
          </div>
        )}
        {documentSent && (
          <div className="flex flex-col items-center">
            <h3 className="modal__title">Thank You!</h3>
            <p className="modal__subtitle mt-3  mb-[25px]">
              Your account is under review. You’ll be notified once your
              verification is complete and you can start listing properties
            </p>
            <img src="/assets/images/success-icon.svg" alt="" />

            <button
              className="modal__action"
              onClick={closeModal}
            >
              {isLoggedIn ? "CLOSE" : "RETURN TO LOGIN"}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DocumentVerification;
