import * as React from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import { MoreHoriz } from "@mui/icons-material";
import {
  IAPIResponse,
  IFilterFetch,
  IListings,
} from "../../../../common/utils/interfaces";
import CustomTable, {
  HeadCell,
} from "../../../../common/components/CustomTable/CustomTable";
import { getMyListings } from "../../../../../apis/listings/sellers";
import CustomBadge from "../../../../common/components/CustomBadge";

type Props = {
  setDetailData: (arg: IListings) => void;
  filter: IFilterFetch;
};

const testt = [
  {
    transactionDate: "12/05/2024 5:40pm",
    paymentType: "Virtual Tour",
    propertyName: "Ms Homes Ltd",
    paymentStautus: "pending",
  },
  {
    transactionDate: "12/05/2024 5:40pm",
    paymentType: "Virtual Tour",
    propertyName: "Ms Homes Ltd",
    paymentStautus: "pending",
  },
  {
    transactionDate: "12/05/2024 5:40pm",
    paymentType: "Virtual Tour",
    propertyName: "Ms Homes Ltd",
    paymentStautus: "pending",
  },
  {
    transactionDate: "12/05/2024 5:40pm",
    paymentType: "Virtual Tour",
    propertyName: "Ms Homes Ltd",
    paymentStautus: "pending",
  },
  {
    transactionDate: "12/05/2024 5:40pm",
    paymentType: "Virtual Tour",
    propertyName: "Ms Homes Ltd",
    paymentStautus: "pending",
  },
];

const headCells: readonly HeadCell[] = [
  {
    id: "transactionDate",
    numeric: false,
    disablePadding: true,
    label: "Transaction Date",
  },
  {
    id: "paymentType",
    numeric: false,
    disablePadding: false,
    label: "Payment Type",
  },
  {
    id: "propertyName",
    numeric: false,
    disablePadding: false,
    label: "Property Name",
  },
  {
    id: "paymentStautus",
    numeric: false,
    disablePadding: false,
    label: "Payment Status",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];

const TransactionsTable: React.FC<Props> = ({
  setDetailData,
  filter,
}: Props) => {
  const [tableData, setTableData] = React.useState<IListings[]>([]);

  const tableTitle = { mainText: "My Transactions", subText: "transactions" };

  React.useEffect(() => {
    // fetchTableData();
  }, [filter]);

  const fetchTableData = async () => {
    const result: IAPIResponse | undefined = await getMyListings(filter);
    if (result) {
      const { data, count } = result;
      setTableData(data);
    }
  };

  const getBadgeMode = (text: string) => {
    switch (text) {
      case "approved":
        return "success";

      case "rejected":
        return "error";

      default:
        return "info";
    }
  };

  const selectData = (
    evt: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    selectedData: IListings
  ) => {
    evt.stopPropagation();

    setDetailData(selectedData);
  };

  const MappedTableRows = ({
    row,
    labelId,
    isItemSelected,
  }: {
    row: any;
    labelId: string;
    isItemSelected: boolean;
  }) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.transactionDate}
      </TableCell>
      <TableCell align="left">{row.paymentType}</TableCell>
      <TableCell align="left">{row.propertyName}</TableCell>
      <TableCell align="center">
        <CustomBadge mode={getBadgeMode(row.purchaseStautus)}>
          {row.paymentStatus}
        </CustomBadge>
      </TableCell>
      <TableCell align="center">
        <MoreHoriz />
      </TableCell>
    </>
  );

  return (
    <CustomTable
      tableTitle={tableTitle}
      headCells={headCells}
      rows={tableData}
      // rows={testt}
      mappedTableRows={MappedTableRows}
    />
  );
};

export default TransactionsTable;
