import React from "react";

const Loading = ({ show }: { show: boolean }) => {
  return show ? (
    <span
      style={{
        position: "absolute",
        top: "20%",
        right: 10,
      }}
    >
      <div className="loader"></div>
    </span>
  ) : null;
};

export default Loading;
