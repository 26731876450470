import { Cancel, Close } from "@mui/icons-material";
import { Button, Modal } from "@mui/material";
import React from "react";

const SuccessModal = ({
  open,
  handleClose,
  title,
  isNegative = false,
}: {
  open: boolean;
  handleClose: () => void;
  title: string;
  isNegative?: boolean;
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal__container" style={{ textAlign: "center" }}>
        <h2 className="modal__title text-center">{title || "Successful"}</h2>
        {isNegative ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <div
              style={{
                backgroundColor: "#ffcccc",
                padding: 6,
                borderRadius: "50%",
              }}
            >
              <Cancel style={{ fontSize: 70, color: "#a01212" }} />
            </div>
          </div>
        ) : (
          <img
            className="mx-auto mt-4"
            src="/assets/images/success-icon.svg"
            alt=""
          />
        )}
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{ marginTop: 25 }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default SuccessModal;
