import React, { useState } from "react";
import { Button, Modal, TextField, Typography } from "@mui/material";
import { Circle, Close, Person } from "@mui/icons-material";
import { Link } from "react-router-dom";

import { IconNotificationBell } from "../../../../common/components/LayoutIcons";
import DocumentsTable from "./components/DocumentsTable";
import {
  IDeveloperDetails,
  INotification,
} from "../../../../common/utils/interfaces";
import { postSellerApplicationAction } from "../../../../../apis/admin/sellers";
import {
  errorToastify,
  successToastify,
} from "../../../../common/utils/Toasty";
import SuccessModal from "../../../../common/components/SuccessModal";

type Props = {
  detailData: IDeveloperDetails | null;
  closeModal: () => void;
};

const NOTIFICATIONS_LENGTH = 4;
const DOCUMENTS_LENGTH = 4;

const DeveloperApplication: React.FC<Props> = ({
  detailData,
  closeModal,
}: Props) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showRejectComment, setShowRejectComment] = useState(false);
  const [rejectComment, setRejectComment] = useState("");

  const notifications: INotification[] = [
    /* { body: "Macron Ventures Heights submitted new documents", time: "2m ago" },
    { body: "Goolex Properties submitted new documents", time: "3d ago" },
    { body: "Criston Heights submitted new documents", time: "last week" },
    { body: "ForReal Real Estate submitted new documents", time: "last week" }, */
  ];

  const NotificationsCard = () => (
    <div className="side-card">
      <div className="side-card-header">
        <Typography variant="h4">Notifications</Typography>
        <IconNotificationBell />
      </div>
      <div className="side-card-main">
        {notifications?.map((notification, index: number) => (
          <div key={index} className="_row">
            <div className="indent-disc">
              <Circle style={{ fontSize: 12 }} />
            </div>
            <Typography variant="h6">{notification.body}</Typography>
            <Typography
              style={{
                whiteSpace: "nowrap",
                marginLeft: "auto",
              }}
            >
              {notification.time}
            </Typography>
          </div>
        ))}
      </div>
      <div className="side-card-footer">
        <div className="_left">
          <Typography>
            {`${notifications.length - NOTIFICATIONS_LENGTH} more notification${
              notifications.length - NOTIFICATIONS_LENGTH === 1 ? "" : "s"
            }`}
          </Typography>
        </div>
        <div className="_right">
          <Link to="/" className="hover_underline">
            <Typography color="primary" style={{ fontWeight: 500 }}>
              See all
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );

  const submitComment = async (decision: "approve" | "reject") => {
    //  post to API
    const response = await postSellerApplicationAction({
      slug: detailData?.slug || "",
      action: decision,
      ...(decision === "reject" ? { rejection_reason: rejectComment } : {}),
    });

    if (response?.status) {
      setShowSuccessModal(true);

      setTimeout(() => {
        setShowRejectComment(false);
        setRejectComment("");
        setShowSuccessModal(false);
        closeModal();
      }, 2000);
    } else {
      alert("Sorry, an error occurred.");
      errorToastify("Sorry, an error occurred.");
    }
  };

  return (
    <>
      <div className="dashboard-page-title">
        <Typography variant="h4">Developer Application</Typography>
      </div>
      <div className="dashboard-main">
        <div className="page-central">
          <div className="card-group">
            <div className="info-card">
              <div className="info-card-header">
                <Typography variant="h5">Basic information</Typography>
              </div>
              <div className="info-card-body">
                <div className="_section">
                  <div className="_title">
                    <Typography>Developer name</Typography>
                  </div>
                  <div className="_data">
                    <div className="_avatar">
                      <Person style={{ fontSize: 38 }} />
                    </div>
                    <Typography>{`${detailData?.first_name} ${detailData?.last_name}`}</Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Email address</Typography>
                  </div>
                  <div className="_data">
                    <Typography>{detailData?.email}</Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Phone number</Typography>
                  </div>
                  <div className="_data">
                    <Typography>{detailData?.phone_number}</Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-card">
              <div className="info-card-header">
                <Typography variant="h5">Business information</Typography>
              </div>
              <div className="info-card-body">
                <div className="_section">
                  <div className="_title">
                    <Typography>Business name</Typography>
                  </div>
                  <div className="_data">
                    <Typography>{detailData?.company_name}</Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Business registration name</Typography>
                  </div>
                  <div className="_data">
                    <Typography>
                      {detailData?.company_registration_number}
                    </Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Business address</Typography>
                  </div>
                  <div className="_data">
                    <Typography>{detailData?.address}</Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DocumentsTable developerData={detailData} />
        </div>
        <div className="page-side">
          {/* temporarily hidden */}
          {/* <NotificationsCard /> */}
        </div>
      </div>
      <div className="dasboard-base">
        <Button variant="contained" onClick={() => submitComment("approve")}>
          Approve
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setShowRejectComment(true)}
        >
          Decline
        </Button>
        {/* <Button variant="outlined">Edit</Button>
        <Button variant="outlined">Request More Information</Button> */}
      </div>

      {/* tech-debt: add close button to modal */}
      <Modal open={showRejectComment}>
        <div
          style={{
            backgroundColor: "#ffffff",
            padding: 30,
            width: 500,
            margin: "auto",
            borderRadius: 5,
            marginTop: "15vh",
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>Rejection comment</Typography>
            <Close
              style={{ cursor: "pointer" }}
              onClick={() => setShowRejectComment(false)}
            />
          </div>
          <TextField
            placeholder="Enter comment here..."
            fullWidth
            multiline
            rows={5}
            onChange={(evt) => setRejectComment(evt.target.value)}
          />
          <Button variant="outlined" onClick={() => submitComment("reject")}>
            Submit
          </Button>
        </div>
      </Modal>

      <SuccessModal
        open={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        title={`Developer Application ${
          rejectComment ? "Rejected" : "Approved"
        }`}
        isNegative={!!rejectComment}
      />
    </>
  );
};

export default DeveloperApplication;
