import React, { useEffect, useState } from "react";
import { Button, colors, Modal, TextField, Typography } from "@mui/material";
import { Circle, Close, Download, Person } from "@mui/icons-material";
import { Link } from "react-router-dom";

import {
  IconDocumentPage,
  IconNotificationBell,
} from "../../../../common/components/LayoutIcons";
import {
  IDocument,
  IImages,
  IListing,
  IListings,
  IPropertyFeatures,
  IPropertyListing,
} from "../../../../common/utils/interfaces";
import { successToastify } from "../../../../common/utils/Toasty";
import { postListingApplicationAction } from "../../../../../apis/admin/listings";
import {
  categorizeFeatures,
  formatNumberString,
} from "../../../../common/utils/helpers";
import { listingDocuments } from "../../../../common/utils/constants";
import SuccessModal from "../../../../common/components/SuccessModal";
import ImageGalleryCarousel from "../../../../common/components/ImageGalleryCarousel";
import ImageGallery from "../../../../Buyer/modals/ImageGallery";

type Props = {
  psuedoData: IPropertyListing | null;
  fullDetailData: IListing | null;
  closeModal: () => void;
};

const NOTIFICATIONS_LENGTH = 4;
const DOCUMENTS_LENGTH = 4;

type INotification = { body: string; time: string };

const PropertyListingApplication: React.FC<Props> = ({
  psuedoData,
  fullDetailData,
  closeModal,
}: Props) => {
  const [loadedFeatures, setLoadedFeatures] = useState<any>({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showRejectComment, setShowRejectComment] = useState(false);
  const [rejectComment, setRejectComment] = useState("");
  const [showImageCarousel, setShowImageCarousel] = useState(false);

  useEffect(() => {
    if (fullDetailData?.features?.length) {
      const categrized = categorizeFeatures(fullDetailData?.features);
      setLoadedFeatures(categrized || {});
    }
  }, [fullDetailData]);

  const notifications: INotification[] = [
    /* { body: "Macron Ventures Heights submitted new documents", time: "2m ago" },
    { body: "Goolex Properties submitted new documents", time: "3d ago" },
    { body: "Criston Heights submitted new documents", time: "last week" },
    { body: "ForReal Real Estate submitted new documents", time: "last week" }, */
  ];

  const documents: IDocument[] = [
    {
      name: "Certificate of Incorporation",
      owner: "Crimson Heights",
      url: "/owner",
    },
    {
      name: "Certificate of Ownership",
      owner: "Crimson Heights",
      url: "/owner",
    },
    {
      name: "Real Estate License",
      owner: "Crimson Heights",
      url: "/owner",
    },
  ];

  const documentImages: IImages[] = [
    {
      name: "Certificate of Ownership",
      url: "Crimson Heights",
    },
    {
      name: "Certificate of Ownership",
      url: "Crimson Heights",
    },
    {
      name: "Certificate of Ownership",
      url: "Crimson Heights",
    },
    {
      name: "Certificate of Ownership",
      url: "Crimson Heights",
    },
  ];

  const NotificationsCard = () => (
    <div className="side-card">
      <div className="side-card-header">
        <Typography variant="h4">Notifications</Typography>
        <IconNotificationBell />
      </div>
      <div className="side-card-main">
        {notifications?.map((notification, index: number) => (
          <div key={index} className="_row">
            <div className="indent-disc">
              <Circle style={{ fontSize: 12 }} />
            </div>
            <Typography variant="h6">{notification.body}</Typography>
            <Typography
              style={{
                whiteSpace: "nowrap",
                marginLeft: "auto",
              }}
            >
              {notification.time}
            </Typography>
          </div>
        ))}
      </div>
      <div className="side-card-footer">
        <div className="_left">
          <Typography>
            {`${notifications.length - NOTIFICATIONS_LENGTH} more notification${
              notifications.length - NOTIFICATIONS_LENGTH === 1 ? "" : "s"
            }`}
          </Typography>
        </div>
        <div className="_right">
          <Link to="/" className="hover_underline">
            <Typography color="primary" style={{ fontWeight: 500 }}>
              See all
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );

  const DocumentsCard = () => (
    <div className="side-card">
      <div className="side-card-header">
        <Typography variant="h4">Submitted Documents</Typography>
        <IconDocumentPage />
      </div>
      <div className="side-card-main">
        {(Object.entries(listingDocuments) as [string, string][])?.map(
          (document, index: number) => (
            <div key={index} className="_row">
              <div className="indent-disc">
                <div className="icon-circle-grey">
                  <IconDocumentPage
                    style={{
                      width: 20,
                      filter: "grayscale(1) brightness(2.5)",
                    }}
                  />
                </div>
              </div>
              <div>
                <Typography variant="h5">{document[0]}</Typography>
                <Typography>
                  <span>from {fullDetailData?.developer?.company_name}</span>
                </Typography>
              </div>
              {(fullDetailData as any)?.[document[1]] ? (
                <a
                  href={(fullDetailData as any)?.[document[1]]}
                  download={document[0]}
                  rel="noreferrer"
                  target="_blank"
                  style={{ marginLeft: "auto" }}
                >
                  <Button
                    variant="text"
                    style={{
                      padding: 4,
                      minWidth: "unset",
                      fontSize: 12,
                    }}
                  >
                    <Download /> Download
                  </Button>
                </a>
              ) : (
                <Button
                  variant="text"
                  disabled
                  style={{
                    padding: 4,
                    minWidth: "unset",
                    fontSize: 12,
                    marginLeft: "auto",
                  }}
                >
                  <Download /> Download
                </Button>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );

  const ImagesVideosCard = () => (
    <div className="side-card">
      <div className="side-card-header">
        <Typography variant="h4">Property Images & Videos</Typography>
        <IconNotificationBell />
      </div>
      <div className="side-card-main">
        <div className="_preview" style={{ marginTop: 5 }}>
          <div className="_title">
            <Typography variant="body2">Images</Typography>
          </div>
          <div className="_group">
            {[null, null, null].map((empty, index: number) => {
              const image = fullDetailData?.files?.filter(
                (file) => file.file_type === "image"
              )?.[index];
              return (
                <div key={index} className="_thumbnail">
                  {image && <img src={image?.file} alt="property" />}
                </div>
              );
            })}
          </div>
          <div
            className="_mini-btns"
            style={{ cursor: "pointer" }}
            onClick={() => setShowImageCarousel(true)}
          >
            <Typography color="primary" style={{ fontWeight: 500 }}>
              See all
            </Typography>
          </div>
        </div>
        <div className="_preview">
          <div className="_title">
            <Typography variant="body2">Videos</Typography>
          </div>
          <div className="_group">
            {[null, null, null].map((empty, index: number) => {
              const video = fullDetailData?.files?.filter(
                (file) => file.file_type === "video"
              )?.[index];
              return (
                <div key={index} className="_thumbnail">
                  {video && <video src={video?.file} />}
                </div>
              );
            })}
          </div>
          <div
            className="_mini-btns"
            style={{ cursor: "pointer" }}
            onClick={() => setShowImageCarousel(true)}
          >
            {/* <Link to="/" className="hover_underline"> */}
            <Typography color="primary" style={{ fontWeight: 500 }}>
              See all
            </Typography>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );

  const submitComment = async (decision: string) => {
    //  post to API
    const response = await postListingApplicationAction({
      sellerUrl: psuedoData?.url || "",
      action: decision,
      reason: rejectComment,
    });

    if (response?.status) {
      setShowSuccessModal(true);

      setTimeout(() => {
        setShowRejectComment(false);
        setRejectComment("");
        closeModal();
        setShowSuccessModal(false);
      }, 2000);
    } else {
      //  tech-debt: BE not returning proper error
      successToastify("Action submitted successfully");
    }
  };

  return (
    <>
      <div className="dashboard-page-title">
        <Typography variant="h4">{fullDetailData?.name}</Typography>
      </div>
      <div className="dashboard-main">
        <div className="page-central">
          <div className="card-group">
            <div className="info-card">
              <div className="info-card-header">
                <Typography variant="h5">Basic information</Typography>
              </div>
              <div className="info-card-body">
                <div className="_section">
                  <div className="_title">
                    <Typography>Developer name</Typography>
                  </div>
                  <div className="_data">
                    <div className="_avatar">
                      <Person style={{ fontSize: 38 }} />
                    </div>
                    <Typography>{`${fullDetailData?.developer?.first_name} ${fullDetailData?.developer?.last_name}`}</Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Email address</Typography>
                  </div>
                  <div className="_data">
                    <Typography>{fullDetailData?.developer?.email}</Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Phone number</Typography>
                  </div>
                  <div className="_data">
                    <Typography>
                      {fullDetailData?.developer?.phone_number}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-card">
              <div className="info-card-header">
                <Typography variant="h5">Business information</Typography>
              </div>
              <div className="info-card-body">
                <div className="_section">
                  <div className="_title">
                    <Typography>Business name</Typography>
                  </div>
                  <div className="_data">
                    <Typography>
                      {fullDetailData?.developer?.company_name}
                    </Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Business registration name</Typography>
                  </div>
                  <div className="_data">
                    <Typography>
                      {fullDetailData?.developer?.company_registration_number}
                    </Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Business address</Typography>
                  </div>
                  <div className="_data">
                    <Typography style={{ textTransform: "capitalize" }}>
                      {fullDetailData?.developer?.address}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-card">
              <div className="info-card-header">
                <Typography variant="h5">Property details</Typography>
              </div>
              <div className="info-card-body">
                <div className="_section">
                  <div className="_title">
                    <Typography>Property type</Typography>
                  </div>
                  <div className="_data">
                    <Typography>{fullDetailData?.property_type}</Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Property name</Typography>
                  </div>
                  <div className="_data">
                    <Typography>{fullDetailData?.name}</Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Property address</Typography>
                  </div>
                  <div className="_data">
                    <Typography>{fullDetailData?.address}</Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Bedrooms</Typography>
                  </div>
                  <div className="_data">
                    <Typography>{fullDetailData?.bedrooms}</Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Bathrooms</Typography>
                  </div>
                  <div className="_data">
                    <Typography>{fullDetailData?.bathrooms}</Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Square Meter</Typography>
                  </div>
                  <div className="_data">
                    <Typography>
                      {formatNumberString(fullDetailData?.square_footage)}
                    </Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Price</Typography>
                  </div>
                  <div className="_data">
                    <Typography>
                      {formatNumberString(fullDetailData?.price)}
                    </Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Price per Sqft</Typography>
                  </div>
                  <div className="_data">
                    <Typography>
                      {formatNumberString(
                        fullDetailData?.price_per_square_meter
                      )}
                    </Typography>
                  </div>
                </div>
                <div className="_section">
                  <div className="_title">
                    <Typography>Default currency</Typography>
                  </div>
                  <div className="_data">
                    <Typography>{fullDetailData?.default_currency}</Typography>
                  </div>
                </div>
              </div>
              <div className="info-card-body">
                <div className="_section _large">
                  <div className="_title">
                    <Typography>Description</Typography>
                  </div>
                  <div className="_data">
                    <Typography>{fullDetailData?.description}</Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-card">
              <div className="info-card-header">
                <Typography variant="h5">Property features</Typography>
              </div>
              <div className="info-card-body">
                {Object.keys(loadedFeatures).map(
                  (featureGroup: string, outdex: number) => (
                    <div className="_section">
                      <div className="_title">
                        <Typography>{featureGroup}</Typography>
                      </div>
                      <div className="_data">
                        <ul>
                          {loadedFeatures[featureGroup].map(
                            (feature: IPropertyFeatures, index: number) => (
                              <li>
                                <Typography>{feature.name}</Typography>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="page-side">
          {/* temporarily hidden */}
          {/* <NotificationsCard /> */}
          <DocumentsCard />
          <ImagesVideosCard />
        </div>
      </div>
      <div className="dasboard-base">
        <Button variant="contained" onClick={() => submitComment("approve")}>
          Approve
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setShowRejectComment(true)}
        >
          Decline
        </Button>
        {/* <Button variant="outlined">Edit</Button>
        <Button variant="outlined">Request More Information</Button> */}

        {/* tech-debt: add close button to modal */}
        <Modal open={showRejectComment}>
          <div
            style={{
              backgroundColor: "#ffffff",
              padding: 30,
              width: 500,
              margin: "auto",
              borderRadius: 5,
              marginTop: "15vh",
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography>Rejection comment</Typography>
              <Close
                style={{ cursor: "pointer" }}
                onClick={() => setShowRejectComment(false)}
              />
            </div>
            <TextField
              placeholder="Enter comment here..."
              fullWidth
              multiline
              rows={5}
              onChange={(evt) => setRejectComment(evt.target.value)}
            />
            <Button variant="outlined" onClick={() => submitComment("reject")}>
              Submit
            </Button>
          </div>
        </Modal>
      </div>

      <SuccessModal
        open={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
        title={`Listing ${rejectComment ? "Rejected" : "Approved"}`}
        isNegative={!!rejectComment}
      />

      {/* <ImageGalleryCarousel
        open
        handleClose={() => {}}
        title="Gallery Carousel"
      /> */}
      <ImageGallery
        open={showImageCarousel}
        handleClose={() => setShowImageCarousel(false)}
        filesList={fullDetailData}
      />
    </>
  );
};

export default PropertyListingApplication;
