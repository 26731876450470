import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { withErrorBoundary } from "./ErrorBoundary";
import ErrorScreen from "../components/ErrorScreen/ErrorScreen";
import { IUserType } from "../utils/interfaces";
import IdleTimer from "./IdleTimer";

const ProtectedRoute = ({
  section,
  isAuthenticated,
  children,
}: {
  section: IUserType;
  isAuthenticated: boolean;
  children: JSX.Element;
}) => {
  // let auth = useAuth();
  let location = useNavigate();
  if (!isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: `/sign-in`,
          search: `?redirect=${window.location.pathname}${
            window.location.search || ""
          }`,
        }}
        // state={{ from: location }}
      />
    );
  }
  return withErrorBoundary(
    <IdleTimer isAuthenticated={isAuthenticated}>{children}</IdleTimer>,
    {
      // return withErrorBoundary(children, {
      fallback: <ErrorScreen />,
    }
  );
};

export default ProtectedRoute;
