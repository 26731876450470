import React, { ChangeEvent, useState } from "react";
import DashboardLayout from "../../../common/components/DashboardLayout";
import { sellerDashboardNavList } from "../../../common/global/DashboardRoutes";
import AccountVerification from "../../../Buyer/modals/AccountVerification";
import CompleteOnboarding from "../../components/CompleteOnboarding";

const SellerDashboardLayout = ({
  children,
  noPadding = false,
  hasHeader = false,
  pageHeader,
  pageBase,
  isCenter = false,
  searchPlaceholder,
  onFilter,
}: {
  noPadding?: boolean;
  hasHeader?: boolean;
  children: JSX.Element;
  pageHeader?: { mainText: string; subText?: string; flankRight?: string };
  pageBase?: JSX.Element;
  isCenter?: boolean;
  searchPlaceholder?: string;
  onFilter?: (evt: ChangeEvent<HTMLInputElement>, state: string) => void;
}) => {
  const localUser = JSON.parse(localStorage.getItem("user") || "{}");
  const [showOtpModal, setShowOtpModal] = useState(
    !localUser?.is_account_verified
  );

  const [showOnboardingModal, setShowOnboardingModal] = useState(
    localUser["user-types"][0].status === "rejected" || (localUser?.is_account_verified &&
      !localUser["user-types"][0].has_completed_onboarding)
  );
  const closeOtpModal = () => {
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...localUser,
        is_account_verified: true,
      })
    );
    setShowOtpModal(false);
    setShowOnboardingModal(true);
  };
  const closeOnboardingModal = () => {
    localUser["user-types"][0].has_completed_onboarding = true;
    localUser["user-types"][0].status = "pending";
    localStorage.setItem("user", JSON.stringify(localUser));
    setShowOtpModal(false);
    setShowOnboardingModal(false);
  };

  return (
    <DashboardLayout
      section="seller"
      noPadding={noPadding}
      hasHeader={false}
      navigationMenu={sellerDashboardNavList}
      pageHeader={pageHeader}
      pageBase={pageBase}
      isCenter={isCenter}
    >
      <>
        {/* Complete onboarding if not completed */}
        <CompleteOnboarding
          open={showOnboardingModal}
          onClose={() => {}}
          closeOnboardingModal={() => closeOnboardingModal()}
        />
        {/* OTP verification for unverified sellers */}
        <AccountVerification
          isLoggedIn={true}
          open={showOtpModal}
          handleClose={() => {}}
          submitAction={closeOtpModal}
          email={localUser.email}
        />
        {children}
      </>
    </DashboardLayout>
  );
};

export default SellerDashboardLayout;
