import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { withErrorBoundary } from "./ErrorBoundary";
import ErrorScreen from "../components/ErrorScreen/ErrorScreen";
import { IUserType } from "../utils/interfaces";
import IdleTimer from "./IdleTimer";

const LoggedOutRoute = ({
  isAuthenticated,
  children,
  redirectUrl,
}: {
  isAuthenticated: boolean;
  children: JSX.Element;
  redirectUrl: string;
}) => {
  if (isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: redirectUrl,
        }}
      />
    );
  }

  return withErrorBoundary(
    <IdleTimer isAuthenticated={false}>{children}</IdleTimer>,
    {
      // return withErrorBoundary(children, {
      fallback: <ErrorScreen />,
    }
  );
};

export default LoggedOutRoute;
