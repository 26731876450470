import "./App.css";
import Routes from "./Routes";
import { ToastContainer, toast, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReactGA from "react-ga4";

try {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || "");
} catch {
  console.log("GA MEASUREMENT ID!!!");
}
const App = () => {
  return (
    <>
      <ToastContainer autoClose={2500} />
      <Routes />
    </>
  );
};

export default App;
