import { MenuItem, Modal, Select, SelectChangeEvent } from "@mui/material";
import { CustomModalProps, ListingInterface } from "../../../../types";
import { DisplayAmount } from "../../../common/components/CurrencySwitch";
import { usePaystackPayment } from "react-paystack";
import { HookConfig } from "react-paystack/dist/types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import PhoneInput from "react-phone-number-input";
import { getPaymentAmount, makePayment } from "../../../../apis/buyer/payment";
import { PAYMENT_TYPES } from "../../../common/utils/constants";
import { convertDateFormat } from "../../../common/utils/validation";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { generateUniqueID } from "../../../common/utils/generateUniqueId";
interface Props extends CustomModalProps {
  listing: ListingInterface;
}

const InPersonTour: React.FC<Props> = ({ handleClose, open, listing }) => {
  const [amount, setAmount] = useState("0");
  const [tourist, setTourist] = useState("Self");
  const [repName, setRepName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [screen, setScreen] = useState<"details" | "payment">("details");
  const [repNumber, setRepNumber] = useState<string | undefined>();
  const [datetime, setDatetime] = useState<Dayjs | null>(null);
  const [paymentSuccess, setPaymentSuccess] = useState<boolean | null>(null);
  const paystackKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || "";
  const { user } = useSelector((state: RootState) => state.user);

  let referenceID = "";
  generateUniqueID(user.email).then((id) => (referenceID = id));
  const config: HookConfig = {
    reference: referenceID,
    email: user.email,
    amount: Number(amount) * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: paystackKey,
  };

  const onSuccess = async (reference: any) => {
    setIsLoading(true);
    const schedule = {
      scheduled_time: datetime,
      representative_name: repName,
      self_phone_number: repNumber,
      representative_phone_number: repNumber,
    };

    if (tourist === "Self") {
      delete schedule.representative_phone_number;
      // @ts-ignore
      delete schedule.representative_name;
    } else {
      delete schedule.self_phone_number;
    }

    const response = await makePayment(listing?.lid!, {
      payment_reference: reference.trxref,
      payment_type: PAYMENT_TYPES.PHYSICAL_TOUR,
      amount: Number(amount).toFixed(2),
      schedule,
    });
    setIsLoading(false);
    if (response) {
      setPaymentSuccess(true);
    } else {
      setPaymentSuccess(false);
    }
  };

  let fieldsUnfilled = true;

  if (tourist !== "Self") {
    fieldsUnfilled = !repName || !repNumber || !datetime;
  } else {
    fieldsUnfilled = !datetime || !repNumber;
  }

  const onClose = () => {};
  const initializePayment = usePaystackPayment(config);

  const handleBackendPaymentSuccess = () => {
    setPaymentSuccess(null);
    setScreen("details");
    handleClose();
  };

  const PaymentSection = () => (
    <div className="payment-modal relative">
      <img
        onClick={() => setScreen("details")}
        className="absolute cursor-pointer hover:opacity-50 duration-500  top-[50px] left-7 sm:left-[33px] translate-y-[35%]"
        src="/assets/images/arrow-left.svg"
        alt=""
      />
      <p className="payment-modal__title">Payment for In-Person Tour</p>
      <div className="payment-modal__price">
        <p className="naira flex justify-center">
          <DisplayAmount amount={amount} />
        </p>
        <p className="text-[13px]">(VAT Inclusive)</p>
      </div>
      <p className="payment-modal__field">Property Name:</p>
      <p className="payment-modal__value">{listing.name}</p>
      <div className="mt-[41px] mb-[21px]">
        <p className="payment-modal__field">Location:</p>
        <p className="payment-modal__value">{listing.state}</p>
      </div>
      <p className="payment-modal__info w-[90%] mx-auto">
        A confirmation email will be sent to you upon successful payment. <br />{" "}
        <br /> Note that this payment will be made in naira and is non-refundable..
      </p>
      <button
        onClick={() => initializePayment({ onSuccess, onClose })}
        className="filled-button w-full"
      >
        Click Here To Pay
      </button>
    </div>
  );

  const PaymentFailed = () => (
    <div className="payment-modal relative">
      <img
        onClick={() => setPaymentSuccess(null)}
        src="/assets/images/close-circle.svg"
        className="payment-modal__close hover:opacity-70 duration-300"
        alt=""
      />
      <p className="payment-modal__title">Payment Failed!</p>
      <p className="payment-modal__field mt-6 !text-justify">
        There was an issue while processing your payment. Please try again or
        contact support if the issue persists.
      </p>
      <button
        onClick={() => setPaymentSuccess(null)}
        className="outline-button mt-8"
      >
        CLOSE
      </button>
    </div>
  );

  const PaymentSuccessFul = () => (
    <div className="payment-modal relative">
      <img
        onClick={handleBackendPaymentSuccess}
        src="/assets/images/close-circle.svg"
        className="payment-modal__close hover:opacity-70 duration-300"
        alt=""
      />
      <p className="payment-modal__title">Payment Successful!</p>
      <p className="payment-modal__field mt-6 !text-justify">
        You will receive follow-up instructions via email. Thank you.
      </p>
      <button
        onClick={handleBackendPaymentSuccess}
        className="outline-button mt-8"
      >
        CLOSE
      </button>
    </div>
  );

  const LoadingState = () => (
    <div className="payment-modal relative flex flex-col items-center">
      <p className="payment-modal__title">Loading...</p>
      <div className="payment-loader"></div>
    </div>
  );

  useEffect(() => {
    async function getAmount() {
      const response = await getPaymentAmount();
      const paymentData = response.data.find(
        (type: any) => type.payment_type === PAYMENT_TYPES.PHYSICAL_TOUR
      );
      setAmount(paymentData.total_amount);
    }
    getAmount();
  }, []);

  return (
    <Modal open={open} onClose={handleClose}>
      <>
        {isLoading && <LoadingState />}
        {paymentSuccess && <PaymentSuccessFul />}
        {paymentSuccess === false && <PaymentFailed />}
        {!isLoading && paymentSuccess === null && screen === "details" && (
          <DetailsSection
            listing={listing}
            repName={repName}
            setRepName={setRepName}
            tourist={tourist}
            setTourist={setTourist}
            repNumber={repNumber}
            setRepNumber={setRepNumber}
            datetime={datetime}
            setDatetime={setDatetime}
            fieldsUnfilled={fieldsUnfilled}
            handleNext={() => setScreen("payment")}
            close={handleClose}
          />
        )}
        {!isLoading && paymentSuccess === null && screen === "payment" && (
          <PaymentSection />
        )}
      </>
    </Modal>
  );
};

export default InPersonTour;

interface IDetailsSection {
  tourist: string;
  setTourist: Dispatch<SetStateAction<string>>;
  repName: string;
  setRepName: Dispatch<SetStateAction<string>>;
  repNumber: string | undefined;
  setRepNumber: Dispatch<SetStateAction<string | undefined>>;
  listing: ListingInterface;
  datetime: Dayjs | null;
  setDatetime: Dispatch<SetStateAction<Dayjs | null>>;
  fieldsUnfilled: boolean;
  handleNext: () => void;
  close: () => void;
}
const DetailsSection: React.FC<IDetailsSection> = ({
  tourist,
  setTourist,
  repName,
  setRepName,
  listing,
  repNumber,
  setRepNumber,
  datetime,
  setDatetime,
  fieldsUnfilled,
  handleNext,
  close,
}) => {
  return (
    <div className="payment-modal !text-left">
      <img
        onClick={close}
        src="/assets/images/close-circle.svg"
        className="payment-modal__close hover:opacity-70 duration-300"
        alt=""
      />
      <div className="mx-auto">
        <p className="payment-modal__title !text-left">
          Schedule an In-Person Tour
        </p>
        <span className="block italic text-[14px] leading-[18px] my-[26px]">
          Please note that in-person tours are only available on Monday -
          Saturday 9am-4pm (WAT)
        </span>
        <div className="flex flex-col items-start gap-x-2">
          <p className="payment-modal__field">Property Name:</p>
          <p className="payment-modal__value">{listing.name}</p>
        </div>
        <div className="mt-3 flex flex-col items-start flex-wrap gap-x-20">
          <p className="payment-modal__field">Location:</p>
          <p className="payment-modal__value">{listing.state}</p>
        </div>
        <div className="mt-[18px] mb-[14px] flex flex-col gap-y-2">
          <div>
            <label className="custom__label" htmlFor="">
              Preferred Date/Time:*
            </label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                disablePast
                value={datetime}
                onChange={(newValue) => setDatetime(newValue)}
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
          </div>
          <div>
            <label className="custom__label" htmlFor="">
              Who will come for the tour?*
            </label>
            <Select
              value={tourist}
              id="lga"
              name="lga"
              onChange={(e: SelectChangeEvent) => {
                setTourist(e.target.value);
              }}
              style={{ width: "100%", height: 48, borderRadius: 8 }}
            >
              <MenuItem value="Self">Self</MenuItem>
              <MenuItem value="Representative">A Representative</MenuItem>
            </Select>
          </div>
          {tourist === "Self" && (
            <div>
              <label className="payment-modal__label" htmlFor="">
                Enter your phone number*
              </label>

              <PhoneInput
                placeholder="Enter phone number"
                international
                value={repNumber}
                className="custom__input"
                onChange={setRepNumber}
                defaultCountry="NG"
              />
            </div>
          )}
          {tourist === "Representative" && (
            <>
              <div>
                <label className="custom__label" htmlFor="">
                  Representative's name:*
                </label>
                <input
                  type="text"
                  value={repName}
                  onChange={(e) => setRepName(e.target.value)}
                  className="custom__input"
                />
              </div>
              <div>
                <label className="custom__label" htmlFor="">
                  Representative's phone number:*
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  international
                  value={repNumber}
                  className="custom__input"
                  onChange={setRepNumber}
                  defaultCountry="NG"
                />
              </div>
            </>
          )}
        </div>
      </div>
      <button
        disabled={fieldsUnfilled}
        onClick={handleNext}
        className="filled-button w-full disabled:opacity-35"
      >
        Continue
      </button>
    </div>
  );
};
