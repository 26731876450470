import React from "react";
import {
  NavIconDashboard,
  NavIconDevelopers,
  NavIconDocuments,
  NavIconLogout,
  NavIconPropertyListing,
  NavIconTransactions,
  NavIconUserBuyer,
} from "../components/LayoutIcons";

export interface INavItem {
  title: string;
  icon?: JSX.Element | null;
  url: string;
  sub?: {
    title: string;
    icon?: JSX.Element | null;
    url: string;
  }[];
}
export const adminDashboardNavList: INavItem[] = [
  {
    title: "Dashboard",
    url: `/admin/dashboard`,
    icon: <NavIconDashboard />,
  },
  {
    title: "Developers",
    url: `/admin/developers`,
    icon: <NavIconDevelopers />,
  },
  {
    title: "Property Listing",
    url: `/admin/property-listings`,
    icon: <NavIconPropertyListing />,
  },
  /* { title: "Documents", url: `/admin/documents`, icon: <NavIconDocuments /> },
  {
    title: "User & Buyer",
    url: `/admin/user-n-buyer`,
    icon: <NavIconUserBuyer />,
  }, */
  {
    title: "Transactions",
    url: `/admin/transactions`,
    icon: <NavIconTransactions />,
  },
  { title: "Logout", url: `/`, icon: <NavIconLogout /> },
];

export const sellerDashboardNavList: INavItem[] = [
  {
    title: "Dashboard",
    url: `/seller/dashboard`,
    icon: <NavIconDashboard />,
  },
  {
    title: "My Listings",
    url: `/seller/listings`,
    icon: <NavIconPropertyListing />,
  },
  {
    title: "Profile",
    url: `/seller/profile`,
    icon: <NavIconUserBuyer />,
  },
  /* {
    title: "Saved Properties",
    url: `/seller/saved-properties`,
    icon: <NavIconDocuments />,
  },
  {
    title: "Application",
    url: `/seller/application`,
    icon: <NavIconUserBuyer />,
  }, */
  {
    title: "Transactions",
    url: `/seller/transactions`,
    icon: <NavIconTransactions />,
  },
  { title: "Logout", url: `/`, icon: <NavIconLogout /> },
];

export const buyerDashboardNavList: INavItem[] = [
  /* {
    title: "Sample Items",
    url: `/dashboard/sample-items`,
    icon: <NavIconDashboard />,
  }, */
  {
    title: "My Properties",
    url: `/dashboard/my-properties`,
    icon: <NavIconPropertyListing />,
  },
  {
    title: "Saved Properties",
    url: `/dashboard/saved-properties`,
    icon: <NavIconDocuments />,
  },
  {
    title: "Transactions",
    url: `/dashboard/transactions`,
    icon: <NavIconTransactions />,
  },
  { title: "Logout", url: `/`, icon: <NavIconLogout /> },
];

export const unauthenticatedNavList: INavItem[] = [
  {
    title: "Sign In",
    icon: null,
    url: "/sign-in",
  },
  {
    title: "Sign Up",
    icon: null,
    url: "/sign-up",
  },
  {
    title: "Buy Now",
    icon: null,
    url: "/property-search",
  },
  {
    title: "FAQs",
    icon: null,
    url: "/faqs",
  },
  {
    title: "Support",
    icon: null,
    url: "",
    sub: [
      {
        title: "Chat (offline)",
        icon: <img src="/assets/images/chat.svg" className="size-5" alt="" />,
        url: "",
      },
      {
        title: "+234 809 131 1111",
        icon: (
          <img
            src="/assets/images/whatsapp.svg"
            className="size-5"
            alt="whatsapp"
          />
        ),
        url: "https://wa.me/+2348091311111",
      },
      {
        title: "+234 809 131 1111",
        icon: (
          <img src="/assets/images/phone2.svg" className="size-5" alt="phone" />
        ),
        url: "tel:+2348091311111",
      },
      {
        title: "support@homeandabroad.ng",
        icon: <img src="/assets/images/mail2.svg" className="size-5" alt="" />,
        url: "mailto:support@homeandabroad.ng",
      },
    ],
  },
];
