import { BACKEND_URL } from "../../views/common/utils/constants"
import { IAPIResponse } from "../../views/common/utils/interfaces"
import { errorToastify } from "../../views/common/utils/Toasty"
import { extractErrorMsg } from "../../views/common/utils/validation"
import { CustomAxios } from "../customAxios"

export const getPaymentAmount = async () => {
    try {
        const { data: result  } : IAPIResponse = await CustomAxios().get(`${BACKEND_URL}/payments/types`)
        if(!result.status) {
            throw new Error(result.message);
        }
        return result;
    } catch (error) {
        if (typeof error === "string") {
            errorToastify(error)
        }
    }
}
export const makePayment = async (id: string, body: Record<string, any>) => {
    try {
        const { data: result } : IAPIResponse = await CustomAxios().post(`${BACKEND_URL}/payments/${id}/make-payment`, body)
        if(!result.status) {
            throw new Error(result.message);
        }
        return result;
    } catch (error: any) {
        if(typeof error === "string"){
            errorToastify(error)
        } else {
            errorToastify(error.response.message || error.response.data.message)
        }        
    }
}