import { Slider, Typography } from "@mui/material";
import React, { useState } from "react";
import filterData from "../../data/filterData.json";
import FilterSubSection from "./FilterSubSection";

interface Props {
  closeFilter: () => void;
  showStateModal: () => void;
  propertyTypesList: { name: string; value: string }[];
  featuresList: { id: number; name: string; category: string }[];
  updateFilterList: (catg: string, value: string) => void;
  isStatesSelected?: boolean;
  doFilter: (arg?: any) => void;
  clearFilter: () => void;
}
const FilterSection: React.FC<Props> = ({
  closeFilter,
  showStateModal,
  propertyTypesList,
  featuresList,
  updateFilterList,
  isStatesSelected,
  doFilter,
  clearFilter,
}) => {
  const [isActive, setIsActive] = useState("");

  return (
    <div className="filter-section max-w-[100%] z-50 h-full bg-white flex flex-col">
      <div className="bg-primary rounded-t-lg flex items-center justify-between py-5 px-[30px]">
        <Typography variant="h5" className="h4 !text-textWhite">
          Categories
        </Typography>
        <img
          onClick={closeFilter}
          className="cursor-pointer"
          src="/assets/images/close-circle-white.svg"
          alt=""
        />
      </div>
      <div className="flex px-[10px] pt-5 justify-between items-center">
        <button
          className="outline-button"
          style={{ fontSize: 14 }}
          onClick={clearFilter}
        >
          Clear
        </button>
        <button
          className="filled-button"
          style={{ fontSize: 14 }}
          onClick={doFilter}
        >
          SHOW
        </button>
      </div>
      <div
        className="scroll-section flex flex-col gap-y-5 sm:gap-y-[20px]"
        style={{
          height: "calc(100% - 75px)",
          overflowY: "auto",
          paddingTop: 20,
        }}
      >
        <FilterSubSection
          collapse={isActive !== "state"}
          setIsActive={setIsActive}
          updateFilterList={updateFilterList}
          fid="state"
          title="Location"
          filterType="modal"
          value="Nigeria"
          doAction={showStateModal}
          isStatesSelected={isStatesSelected}
        />
        <FilterSubSection
          collapse={isActive !== "p"}
          setIsActive={setIsActive}
          updateFilterList={updateFilterList}
          fid="p"
          title="Price"
          filterType="slider"
          limitRange={[0, 1000000000]}
          isAmount
          limitPrefix="$"
        />
        <FilterSubSection
          collapse={isActive !== "pt"}
          setIsActive={setIsActive}
          updateFilterList={updateFilterList}
          fid="pt"
          title="Property Type"
          filterType="checkbox"
          listOptions={propertyTypesList}
        />
        <FilterSubSection
          collapse={isActive !== "bedroom"}
          setIsActive={setIsActive}
          updateFilterList={updateFilterList}
          fid="bedroom"
          title="Bedroom"
          filterType="slider"
          limitRange={[1, 20]}
        />
        <FilterSubSection
          collapse={isActive !== "bathroom"}
          setIsActive={setIsActive}
          updateFilterList={updateFilterList}
          fid="bathroom"
          title="Bathroom"
          filterType="slider"
          limitRange={[1, 20]}
        />
        <FilterSubSection
          collapse={isActive !== "ft1"}
          setIsActive={() => setIsActive("ft1")}
          updateFilterList={updateFilterList}
          fid="ft"
          title="Features: Conditioning"
          filterType="checkbox"
          listOptions={featuresList?.filter((feature) =>
            ["amenities_and_facilities", "energy_and_utilities"].includes(
              feature.category
            )
          )}
        />
        <FilterSubSection
          collapse={isActive !== "ft2"}
          setIsActive={() => setIsActive("ft2")}
          updateFilterList={updateFilterList}
          fid="ft"
          title="Features: Furnishing"
          filterType="checkbox"
          listOptions={featuresList?.filter((feature) =>
            ["interior_features", "kitchen_features"].includes(feature.category)
          )}
        />
        <FilterSubSection
          collapse={isActive !== "ft3"}
          setIsActive={() => setIsActive("ft3")}
          updateFilterList={updateFilterList}
          fid="ft"
          title="Features: Facilities"
          filterType="checkbox"
          listOptions={featuresList?.filter(
            (feature) => feature.category === "amenities_and_facilities"
          )}
        />
        <FilterSubSection
          collapse={isActive !== "pz"}
          setIsActive={setIsActive}
          updateFilterList={updateFilterList}
          fid="pz"
          title="Property Size"
          filterType="slider"
          limitRange={[0, 20000]}
          limitSuffix="sqm"
        />
      </div>
    </div>
  );
};

export default FilterSection;
