import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { APP_DEFAULT_CURRENCY } from "../../../views/common/utils/constants";

const initialState = {
  activeCurrency: localStorage.defaultCurrency || APP_DEFAULT_CURRENCY,
};

export const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    updateCurrency: (state, action: PayloadAction<string>) => {
      state.activeCurrency = action.payload;
    },
  },
});

export const { updateCurrency } = currencySlice.actions;
export default currencySlice.reducer;
