import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListingInterface } from "../../../types";

interface ListingsState {
  loading: boolean;
  listing: ListingInterface | null;
  error: string | null;
}

const initialState: ListingsState = {
  loading: false,
  listing: null,
  error: null,
};

const listingsSlice = createSlice({
  name: "listing",
  initialState,
  reducers: {
    fetchListing(state) {
      state.loading = true;
      state.error = null;
    },
    fetchListingSuccess(state, action: PayloadAction<ListingInterface>) {
        state.loading = false
        state.error = null
        state.listing = action.payload
    },
    fetchListingFailure(state, action: PayloadAction<string>) {
        state.loading = false;
        state.error = action.payload
    },
  },
});

export const {  fetchListing, fetchListingFailure, fetchListingSuccess } = listingsSlice.actions

export default listingsSlice.reducer
