import { Modal } from "@mui/material";
import React, { useState } from "react";
import { CustomModalProps } from "../../../types";
import { AppDispatch } from "../../../store/store";
import { useDispatch } from "react-redux";
import { forgotUserPassword } from "../../../apis/accounts/auth";

interface Props extends CustomModalProps {
  email: string;
  setEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const ForgottenPassword: React.FC<Props> = ({
  handleClose,
  open,
  submitAction,
  email,
  setEmail,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const dispatch: AppDispatch = useDispatch();
  const sendMail = async () => {
    setErrorMsg("");
    setLoading(true);
    const response = await dispatch(forgotUserPassword(email));
    if (response?.status) submitAction && submitAction();
    else {
      setErrorMsg("An Error Occured, Please Try Again Later");
    }
    setLoading(false);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal__container">
        <h2 className="modal__title">Forgot Password</h2>
        <span className="modal__subtitle">
          Please verify your email address
        </span>
        <div className="">
          <label htmlFor="email" className="custom__label">
            Email
          </label>
          <input
            value={email}
            onChange={setEmail}
            id="email"
            type="email"
            className="custom__input"
          />
        </div>
        <div className="mt-6">
          <span className="text-red-500 block text-center">{errorMsg}</span>
          <button
            disabled={!email}
            onClick={sendMail}
            className="modal__action !mt-0 disabled:opacity-30"
          >
            {loading ? <span className="loader"></span> : "NEXT"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ForgottenPassword;
