import React, { useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Button, Typography } from "@mui/material";
import {
  IconAddCart,
  IconSwipeRight,
  IconVerifiedFull,
  IconViewEye,
} from "../LayoutIcons";
import ListingRating from "../ListingRating";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import {
  IBuyerListings,
  IListing,
  IListings,
  IPropertyFeatures,
  IUserType,
} from "../../utils/interfaces";
import { USER_TYPES } from "../../utils/constants";
import LikeListing from "../LikeListing";
import { extractId, formatNumberString } from "../../utils/helpers";
import { postToggleSaveListing } from "../../../../apis/listings/buyer";
import { DisplayAmount } from "../CurrencySwitch";

const SingleListing = ({
  section,
  listingData,
  likeAction,
  leftButtonAction,
  rightButtonAction,
  isDefaultSaved = false,
  removeOnUnSave = false,
}: {
  section?: IUserType;
  listingData?: IListings | IBuyerListings;
  likeAction?: () => void;
  leftButtonAction?: () => void;
  rightButtonAction?: () => void;
  isDefaultSaved?: boolean;
  removeOnUnSave?: boolean;
}) => {
  const [removeMe, setRemoveMe] = useState(false);

  const toggleLikeListing = async (state?: boolean) => {
    await postToggleSaveListing({
      id: listingData?.lid,
      url: listingData?.url,
    });
    likeAction && likeAction();

    if (state === false && removeOnUnSave) {
      setRemoveMe(true);
    }
  };

  return removeMe ? (
    <></>
  ) : (
    <div className="card-shadow rounded-xl overflow-hidden single-listing">
      <Link
        // to={`/${section || "admin"}/property-listings/details?listingId=${
        //   listingData?.id
        // }`}
        // to={`/listings/${extractId(listingData?.url || "")}`}
        to={
          section === "seller"
            ? `/seller/listings?lid=${extractId(listingData?.url || "")}`
            : `/listings/${extractId(listingData?.url || "")}`
        }
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="relative overflow-hidden w-full rounded-t-[10px]">
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            className="mySwiper"
            pagination={true}
            modules={[Pagination]}
          >
            <CustomSwiperButton
              {...(section === USER_TYPES.BUYER ? { size: 24 } : {})}
            />
            {listingData?.pics?.map((pic) => (
              <SwiperSlide>
                {pic?.file_type === "image" ? (
                  <img
                    src={pic?.file}
                    className="object-cover w-full h-full rounded-t-[10px]"
                    alt=""
                    style={{ width: "100%", maxHeight: 282, minHeight: 282 }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/assets/images/property1.png";
                    }}
                  />
                ) : (
                  <video
                    src={pic?.file}
                    className="object-cover w-full h-full rounded-t-[10px]"
                    style={{ width: "100%" }}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/assets/images/property1.png";
                    }}
                  />
                )}
              </SwiperSlide>
            ))}
            {!listingData?.pics?.length && (
              <SwiperSlide>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 265,
                  }}
                >
                  <img
                    src="/assets/images/property1.png"
                    alt=""
                    style={{ width: "100%" }}
                  />
                  {/* <IconImagePlaceholder /> */}
                </div>
              </SwiperSlide>
            )}
          </Swiper>
          {section === USER_TYPES.BUYER ? (
            <div className="top-btns">
              {/* <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <IconAddCart />
                <Typography
                  style={{
                    textShadow: "1px 1px 0px #093213",
                  }}
                >
                  Add to cart
                </Typography>
              </button>
              <button>
                <Typography>Compare</Typography>
              </button> */}
              <button style={{ backgroundColor: "#363636ad" }}>
                <Typography>Outright</Typography>
              </button>

              <LikeListing
                isLiked={listingData?.saved_by_user || isDefaultSaved}
                doToggle={toggleLikeListing}
              />
            </div>
          ) : (
            <div className="top-btns">
              {/* temporarily hidden */}
              {/* <button>
                        <Typography>Featured</Typography>
                        </button> */}
              <button style={{ backgroundColor: "#363636" }}>
                <Typography>Outright</Typography>
              </button>
            </div>
          )}
          {listingData?.status === "approved" && (
            <div className="listing-date">
              {`Listed: ${listingData?.period_used_in_the_market || "--"}`}
            </div>
          )}
        </div>
        <div className="listing-details">
          <div className="">
            <div
              className="rating-verified"
              style={{ justifyContent: "flex-end" }}
            >
              {/* <ListingRating
                rating={listingData?.rating || 5}
                disabled={section !== USER_TYPES.BUYER}
                size={24}
              /> */}
              {listingData?.status === "approved" && (
                <Verified
                  {...(section === USER_TYPES.BUYER ? { size: 24 } : {})}
                />
              )}
            </div>
          </div>
          <div className="listing-developer">
            <Typography>{listingData?.name || "--"}</Typography>
            {/* temporarily hidden */}
            {/* <div>
              <IconViewEye style={{ width: 18 }} />
              <Typography>(21)</Typography>
            </div> */}
          </div>
          <div className="more-info">
            <Typography>{listingData?.state || "--"}</Typography>
            <Typography className="feature-list">
              {listingData?.features?.map(
                (feature, index: number, features) => (
                  <React.Fragment key={index}>
                    {feature.name}{" "}
                    {index < features.length - 1 && <span>| </span>}
                  </React.Fragment>
                )
              )}
              {!listingData?.features?.length && <div>--</div>}
            </Typography>
          </div>
          <div className="listing-documents">
            <Typography>Certified Developer</Typography>
            <Typography>Verified Title Deed</Typography>
            <Typography>Fraud Protection</Typography>
            <Typography>Certified Land Ownership</Typography>
            <Typography>No Legal disputes</Typography>
          </div>
          <div className="utility-price">
            <Typography>
              {listingData?.bedrooms || "--"} beds <span>|</span>{" "}
              {listingData?.bathrooms || "--"} baths
            </Typography>
            <div className="_price">
              <Typography>
                <DisplayAmount
                  amount={listingData?.price || 0}
                  format="string"
                />
              </Typography>
            </div>
          </div>
          <div style={{ marginTop: 20 }}>
            <Button variant="contained" fullWidth>
              View Details
            </Button>
          </div>
          {/* <div className="listing-action-btns">
            <Button
              variant="outlined"
              className=""
              {...(section === USER_TYPES.BUYER ? {} : { color: "error" })}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                leftButtonAction && leftButtonAction();
              }}
            >
              {section === USER_TYPES.BUYER ? "MESSAGE" : "REMOVE"}
            </Button>
            <Button
              variant="contained"
              style={{
                border: "none",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                rightButtonAction && rightButtonAction();
              }}
            >
              {section === USER_TYPES.BUYER ? "VIRTUAL TOUR" : "EDIT"}
            </Button>
          </div> */}
        </div>
      </Link>
    </div>
  );
};

export default SingleListing;

const Verified = ({ size }: { size?: number }) => (
  <div className="is-verified">
    <IconVerifiedFull style={{ width: size || "unset" }} />
    <Typography style={size ? { fontSize: 14 } : {}}>Verified</Typography>
  </div>
);

const CustomSwiperButton = ({ size }: { size?: number }) => {
  const swiper = useSwiper();
  return (
    <div className="swipe-buttons">
      <div
        onClick={(e) => {
          e.preventDefault();
          swiper.slidePrev();
        }}
      >
        <IconSwipeRight left style={{ width: size || "unset" }} />
      </div>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          swiper.slideNext();
        }}
      >
        <IconSwipeRight style={{ width: size || "unset" }} />
      </div>
    </div>
  );
};
