import React, { useState } from "react";
import { CompanyDetails, UploadFiles } from "../pages/Onboarding/SignUp";
import { Modal } from "@mui/material";
import { Link } from "react-router-dom";

interface ICompleteOnboaring {
  open: boolean;
  onClose: () => void;
  closeOnboardingModal: () => void;
}
const CompleteOnboarding: React.FC<ICompleteOnboaring> = ({
  open,
  onClose,
  closeOnboardingModal,
}) => {
  const [currentScreen, setCurrentScreen] = useState("companyDetails");
  const toUploadFiles = () => setCurrentScreen("uploadFiles");
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  return (
    <Modal open={open} onClose={onClose}>
      <div className="mt-[20px] max-h-[90vh] max-w-[90vw] overflow-y-scroll bg-white px-4 sm:px-[35px] py-10 shadow-md rounded-[20px] mx-auto lg:max-w-[1000px]">
        <div>
          {userInfo.status === "rejected" && (
            <p className="text-red-500 mb-4">
              Your account was rejected. Please fill in your details and submit
              again.
            </p>
          )}
          <Link to="/">
            <img
              src="/assets/images/logo.svg"
              alt="homeandabroad"
              className="block h-10 mb-8"
            />
          </Link>
          <h2 className="text-textBlack font-[600] text-[18px]">
            Continue Onboarding
          </h2>
          <p className="text-textGrey text-[14px] mb-5">
            Fill all input fields correctly
          </p>
          {currentScreen === "companyDetails" && (
            <CompanyDetails handleNext={toUploadFiles} />
          )}
          {currentScreen === "uploadFiles" && (
            <UploadFiles
              isLoggedIn={true}
              closeOnboardingModal={() => closeOnboardingModal()}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CompleteOnboarding;
