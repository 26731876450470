import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../../types";
import React from "react";
import { Link } from "react-router-dom";

const PaymentSuccessful: React.FC<CustomModalProps> = ({
  open,
  handleClose,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="payment-modal">
        <h2 className="h2">Payment Successful</h2>
        <img className="mx-auto my-5" src="/assets/images/success-icon.svg" alt="" />
        <button className="filled-button">Close</button>
      </div>
    </Modal>
  );
};

export default PaymentSuccessful;
