import { Button, Typography } from "@mui/material";
import { IconUploadArrowpage } from "../../../../common/components/LayoutIcons";
import { useEffect, useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import { convertFromBytes } from "../../../../common/utils/helpers";

const CustomUpload = ({
  id,
  name,
  updateFiles,
}: {
  id: string;
  name: string;
  updateFiles: (id: string, uploadedFiles: File[]) => void;
}) => {
  const uploadImagesRef: any = useRef();
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (isSubscribed || files.length) {
      updateFiles(id, files);
    }
    setIsSubscribed(true);
  }, [files, id]);

  const handleFileChange = (event: any) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles: File[] = Array.from(selectedFiles);
      setFiles(() => [...newFiles]);
    }
  };

  const removeFile = () => {
    setFiles([]);
  };

  return files.length ? (
    <div className="upload-box _uploaded">
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconUploadArrowpage />
        <div>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 500,
              lineHeight: "30px",
            }}
          >
            {files[0].name}
          </Typography>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: 400,
              lineHeight: "30px",
            }}
          >
            {convertFromBytes(files[0].size)}
          </Typography>
        </div>
        <Close
          style={{
            marginLeft: "auto",
            color: "#CD0101",
            fontSize: 20,
            cursor: "pointer",
          }}
          onClick={removeFile}
        />
      </div>
      <input
        ref={uploadImagesRef}
        type="file"
        hidden
        id={name}
        onChange={handleFileChange}
        accept=".pdf" //,.docx,.pptx,.txt,.xlsx"
      />
      <Button
        variant="contained"
        onClick={() => uploadImagesRef.current.click()}
        style={{ alignSelf: "flex-end" }}
      >
        Select file
      </Button>
    </div>
  ) : (
    <div className="upload-box">
      <IconUploadArrowpage />
      <div className="upload-captions">
        <Typography variant="h4" className="_pc">
          Choose a file or drag it and drop here
        </Typography>
        <Typography>PDF readable files only (Max. size: 10MB)</Typography>
        <input
          type="file"
          hidden
          id={name}
          onChange={handleFileChange}
          accept=".pdf" //,.docx,.pptx,.txt,.xlsx"
        />
        <label htmlFor={name} className="browse-btn">
          Browse files
        </label>
      </div>
    </div>
  );
};

export default CustomUpload;
