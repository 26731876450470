import * as React from "react";
import moment from "moment";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import { MoreHoriz } from "@mui/icons-material";
import {
  IAdminTransaction,
  IAPIResponse,
  IFilterFetch,
  IListings,
} from "../../../../common/utils/interfaces";
import CustomTable, {
  HeadCell,
} from "../../../../common/components/CustomTable/CustomTable";
import CustomBadge from "../../../../common/components/CustomBadge";
import { getAdminBuyerTransactions } from "../../../../../apis/admin/purchases";
import { Link } from "react-router-dom";

type Props = {
  setDetailData: (arg: IListings) => void;
  filter: IFilterFetch;
};

const headCells: readonly HeadCell[] = [
  {
    id: "transactionDate",
    numeric: false,
    disablePadding: true,
    label: "Transaction Date",
  },
  {
    id: "buyerName",
    numeric: false,
    disablePadding: false,
    label: "Buyer Name",
  },
  {
    id: "paymentType",
    numeric: false,
    disablePadding: false,
    label: "Payment Type",
  },
  {
    id: "propertyName",
    numeric: false,
    disablePadding: false,
    label: "Property Name",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];

const TransactionsTable: React.FC<Props> = ({
  setDetailData,
  filter,
}: Props) => {
  const [tableData, setTableData] = React.useState<IAdminTransaction[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & SVGSVGElement) | null
  >();

  const tableTitle = { mainText: "My Transactions", subText: "transactions" };

  React.useEffect(() => {
    fetchTableData();
  }, [filter]);

  const fetchTableData = async () => {
    const response: IAPIResponse | undefined =
      await getAdminBuyerTransactions();

    if (response?.status) {
      const { data, count } = response;
      setTableData(data);
    }
  };

  const getBadgeMode = (text: string) => {
    switch (text) {
      case "approved":
        return "success";

      case "rejected":
        return "error";

      default:
        return "info";
    }
  };

  const selectData = (
    evt: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    selectedData: IListings
  ) => {
    evt.stopPropagation();

    setDetailData(selectedData);
  };

  const MappedTableRows = ({
    row,
    labelId,
    isItemSelected,
  }: {
    row: IAdminTransaction;
    labelId: string;
    isItemSelected: boolean;
  }) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {moment(row.created).format("Do MMM, YYYY")}
      </TableCell>
      <TableCell align="left">{row.buyer_name}</TableCell>
      <TableCell align="left">{row.payment_type_name}</TableCell>
      <TableCell align="left">{row.listing?.name}</TableCell>
      <TableCell align="center">
        {row.download_receipt_url ? (
          <a href={row.download_receipt_url} download>
            Download receipt
          </a>
        ) : (
          <span
            style={{
              cursor: "default",
              opacity: 0.4,
            }}
          >
            Download receipt
          </span>
        )}
        {/* <MoreHoriz
          onClick={(evt) => {
            evt.stopPropagation();
            setAnchorEl(evt.currentTarget);
          }}
        /> */}
      </TableCell>
    </>
  );

  return (
    <CustomTable
      tableTitle={tableTitle}
      headCells={headCells}
      rows={tableData}
      // rows={testt}
      mappedTableRows={MappedTableRows}
    />
  );
};

export default TransactionsTable;
