import { useLocation, useNavigate } from "react-router-dom";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Add, ArrowBackIos, Person } from "@mui/icons-material";

import SellerDashboardLayout from "../../commonComponents/DashboardLayout";
import {
  getFeaturesList,
  getPropertyTypesList,
} from "../../../../apis/listings/general";
import CustomUpload from "./components/CustomUpload";
import UploadedItem from "./components/UploadedItem";
import {
  categorizeFeatures,
  convertInputDataToFormData,
} from "../../../common/utils/helpers";
import {
  getMyListingDetails,
  postNewListing,
  updateListing,
} from "../../../../apis/listings/sellers";
import {
  IAPIResponse,
  IPropertyFeatures,
  IUserProfile,
} from "../../../common/utils/interfaces";
import { getLGAsList, getStatesList } from "../../../../apis/locations";
import { getMyProfile } from "../../../../apis/accounts/user";

const Profile: React.FC = () => {
  const [userInfo, setUserInfo] = useState<IUserProfile | null>(null);

  useEffect(() => {
    const initPage = async () => {
      loadAllInfo();
    };

    initPage();
  }, []);

  const loadAllInfo = async () => {
    const response: IAPIResponse | undefined = await getMyProfile();

    if (response?.status) {
      setUserInfo(response.data);
      localStorage.setItem("userInfo", JSON.stringify(response.data));
    }
  };

  const AccountStatus = () => (
    <div className={`profile-badge _${userInfo?.status}`}>{`${
      userInfo?.status === "pending"
        ? "PENDING APPROVAL"
        : `${userInfo?.status?.toUpperCase() || ""} ACCOUNT`
    }`}</div>
  );

  return (
    <SellerDashboardLayout isCenter>
      <div className="page-body profile-page width-700">
        <div className="profile-header _pc">
          <Typography variant="h3">My Profile</Typography>
          <AccountStatus />
        </div>
        <div
          style={{
            borderBottom: "solid 1px #ddd",
            padding: "10px 0",
            margin: "15px 0",
          }}
        >
          <Typography variant="h4">Personal Details</Typography>{" "}
          <div className="_mobile">
            <AccountStatus />
          </div>
        </div>

        <div className="form-form">
          <div className="form-row">
            <div className="form-input">
              <Typography>Name</Typography>
              <TextField
                value={`${userInfo?.first_name || "-"} ${
                  userInfo?.last_name || "-"
                }`}
                // onChange={updateFormData}
                fullWidth
                disabled
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-input">
              <Typography>Phone number</Typography>
              <TextField
                value={userInfo?.phone_number}
                // onChange={updateFormData}
                fullWidth
                disabled
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-input">
              <Typography>Email</Typography>
              <TextField
                value={userInfo?.email}
                // onChange={updateFormData}
                fullWidth
                disabled
              />
            </div>
          </div>
        </div>

        <div
          style={{
            borderBottom: "solid 1px #ddd",
            padding: "10px 0",
            margin: "15px 0",
          }}
        >
          <Typography variant="h4">Company Details</Typography>
        </div>

        <div className="form-form">
          <div className="form-row">
            <div className="form-input">
              <Typography>Company name</Typography>
              <TextField
                value={userInfo?.company_name}
                // onChange={updateFormData}
                fullWidth
                disabled
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-input">
              <Typography>Company registration number</Typography>
              <TextField
                value={userInfo?.company_registration_number}
                // onChange={updateFormData}
                fullWidth
                disabled
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-input">
              <Typography>Business type</Typography>
              <TextField
                value={userInfo?.business_type}
                // onChange={updateFormData}
                fullWidth
                disabled
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-input">
              <Typography>Address</Typography>
              <TextField
                value={userInfo?.address}
                // onChange={updateFormData}
                fullWidth
                disabled
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-input">
              <Typography>Website URL</Typography>
              <TextField
                value={userInfo?.website_url}
                // onChange={updateFormData}
                fullWidth
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </SellerDashboardLayout>
  );
};

export default Profile;
