import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SearchState {
  layoutSearchText: string;
}
const initialState: SearchState = {
  layoutSearchText: "",
};

export const layoutSearchSlice = createSlice({
  name: "searchLayout",
  initialState,
  reducers: {
    setLayoutSearchText: (state, action: PayloadAction<string>) => {
      state.layoutSearchText = action.payload;
    },
  },
});

export const { setLayoutSearchText } = layoutSearchSlice.actions;
export default layoutSearchSlice.reducer;
