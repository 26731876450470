import { BACKEND_URL } from "../../views/common/utils/constants";
import { IAPIResponse } from "../../views/common/utils/interfaces";
import { errorToastify } from "../../views/common/utils/Toasty";
import { CustomAxios } from "../customAxios";

export const getMyProfile = async () => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/accounts/profile`
    );

    if (!result?.status) {
      throw new Error(result?.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};
