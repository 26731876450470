import React, { ChangeEvent, useEffect, useState } from "react";
import { TextField, Typography } from "@mui/material";
import { GridArrowDownwardIcon } from "@mui/x-data-grid";
import { Circle } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import {
  HeaderIconSearch,
  IconDocumentPage,
  IconMoreMenu,
  IconNotificationBell,
  IconPushNotification,
} from "../../../common/components/LayoutIcons";
import DashboardTable from "./components/DashboardTable";
import AdminDashboardLayout from "../../commonComponents/DashboardLayout";
import {
  IAPIResponse,
  IDashboardInfo,
  IDocument,
  IFilterFetch,
  IListings,
  INotification,
} from "../../../common/utils/interfaces";
import SingleListing from "../../../common/components/SingleListing";
import { MAX_DASHBOARD_LISTINGS_ADMIN } from "../../../common/utils/constants";
import { getListingsList } from "../../../../apis/admin/listings";
import { getAdminDashboardInfo } from "../../../../apis/admin/dashboard";

const NOTIFICATIONS_LENGTH = 4;
const DOCUMENTS_LENGTH = 4;

type IReportCard = { title: string; value: number | "-"; extra?: any };

const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [filterState, setFilterState] = useState<any>({
    approved: false,
    pending: false,
    rejected: false,
  });
  const [developerProperties, setDeveloperProperties] = useState<IListings[]>(
    []
  );
  const [loadingDeveloperProperties, setLoadingDeveloperProperties] =
    useState(false);
  const [dashboardInfo, setDashboardInfo] = useState<IDashboardInfo | null>();

  useEffect(() => {
    //  fetch reports
    const init = async () => {
      loadDashboardInfo();
      loadDeveloperProperties();
    };

    init();
  }, []);

  const loadDashboardInfo = async () => {
    const response = await getAdminDashboardInfo();

    if (response.status) {
      setDashboardInfo(response.data);
    }
  };

  const loadDeveloperProperties = async () => {
    setLoadingDeveloperProperties(true);
    const response: IAPIResponse | undefined = await getListingsList();
    setLoadingDeveloperProperties(false);

    if (response?.status) {
      setDeveloperProperties(response.data);
    }
  };

  const reportCards: IReportCard[] = [
    {
      title: "Approved Properties",
      value: dashboardInfo?.approved_listings || "-",
    },
    {
      title: "Pending Approvals",
      value: dashboardInfo?.pending_listings || "-",
    },
    {
      title: "Listing Requests",
      value:
        (dashboardInfo?.approved_listings || 0) +
        (dashboardInfo?.pending_listings || 0),
      /* extra: {
        change: {
          direction: "down",
          magnitude: "10%",
          text: "from last month",
        },
      }, */
    },
  ];
  const notifications: INotification[] = [
    /* { body: "Macron Ventures Heights submitted new documents", time: "2m ago" },
    { body: "Goolex Properties submitted new documents", time: "3d ago" },
    { body: "Criston Heights submitted new documents", time: "last week" },
    { body: "ForReal Real Estate submitted new documents", time: "last week" }, */
  ];
  const documents: IDocument[] = [
    {
      name: "Certificate of Ownership",
      owner: "Oway Gains Properties",
      url: "/owner",
    },
    {
      name: "Certificate of Ownership",
      owner: "Crimson Heights",
      url: "/owner",
    },
    {
      name: "Certificate of Ownership",
      owner: "Crimson Heights",
      url: "/owner",
    },
    {
      name: "Certificate of Ownership",
      owner: "Crimson Heights",
      url: "/owner",
    },
  ];

  const Listings = () => (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <Typography variant="h4">Listed recently</Typography>
        <Link to="/admin/property-listings" className="hover_underline">
          <Typography color="primary" style={{ fontWeight: 500 }}>
            SEE ALL
          </Typography>
        </Link>
      </div>
      <div className="listing-group">
        {developerProperties
          ?.filter(
            (ppty, index: number) => index < MAX_DASHBOARD_LISTINGS_ADMIN
          )
          ?.map((ppty) => (
            <SingleListing
              section="admin"
              listingData={ppty}
              rightButtonAction={() => {
                navigate("/seller/list-property", {
                  state: { listingId: ppty.lid },
                });
              }}
            />
          ))}
        {loadingDeveloperProperties && (
          <div style={{ padding: 20, textAlign: "center" }}>
            L O A D I N G . . .
          </div>
        )}
      </div>
    </div>
  );

  const ReportCards = () => (
    <div className="report-cards">
      {reportCards?.map((card, index: number) => (
        <div className="report-card" key={index}>
          <div className="_top">
            <Typography variant="h5">{card.title}</Typography>
            <IconMoreMenu />
          </div>
          <div className="_bottom">
            <Typography variant="h2">{card.value}</Typography>
            {card.extra?.change && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <GridArrowDownwardIcon
                  style={{
                    color:
                      card.extra?.change?.direction === "up"
                        ? "green"
                        : card.extra?.change?.direction === "down"
                        ? "#CD0101"
                        : "inherit",
                  }}
                />
                <Typography>
                  <span
                    style={{
                      marginLeft: 5,
                      color:
                        card.extra?.change?.direction === "up"
                          ? "green"
                          : card.extra?.change?.direction === "down"
                          ? "#CD0101"
                          : "inherit",
                    }}
                  >
                    {card.extra?.change?.magnitude}
                  </span>{" "}
                  vs last month
                </Typography>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  const NotificationsCard = () => (
    <div className="side-card">
      <div className="side-card-header">
        <Typography variant="h4">Notifications</Typography>
        <IconNotificationBell />
      </div>
      {!!notifications?.length ? (
        <>
          <div className="side-card-main">
            {notifications?.map((notification, index: number) => (
              <div key={index} className="_row">
                <div className="indent-disc">
                  <Circle style={{ fontSize: 12 }} />
                </div>
                <Typography variant="h5">{notification.body}</Typography>
                <Typography
                  style={{
                    whiteSpace: "nowrap",
                    marginLeft: "auto",
                  }}
                >
                  {notification.time}
                </Typography>
              </div>
            ))}
          </div>
          <div className="side-card-footer">
            <div className="_left">
              <Typography>
                {`${
                  notifications.length - NOTIFICATIONS_LENGTH
                } more notification${
                  notifications.length - NOTIFICATIONS_LENGTH === 1 ? "" : "s"
                }`}
              </Typography>
            </div>
            <div className="_right">
              <Link to="/admin/notifications" className="hover_underline">
                <Typography color="primary" style={{ fontWeight: 500 }}>
                  See all
                </Typography>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <Typography variant="caption" className="no-records">
          No records yet
        </Typography>
      )}
    </div>
  );

  const DocumentsCard = () => (
    <div className="side-card">
      <div className="side-card-header">
        <Typography variant="h4">Submitted Documents</Typography>
        <IconDocumentPage />
      </div>
      <div className="side-card-main">
        {documents?.map((document, index: number) => (
          <div key={index} className="_row">
            <div className="indent-disc">
              <div className="icon-circle-grey">
                <IconDocumentPage
                  style={{
                    width: 20,
                    filter: "grayscale(1) brightness(2.5)",
                  }}
                />
              </div>
            </div>
            <div>
              <Typography variant="h5">{document.name}</Typography>
              <Typography>
                <span>from {document.owner}</span>
              </Typography>
            </div>
            <Link
              to={document.url}
              className="hover_underline"
              style={{ marginLeft: "auto" }}
            >
              <Typography color="primary">View</Typography>
            </Link>
          </div>
        ))}
      </div>
      <div className="side-card-footer">
        <div className="_left">
          <Typography>
            {" "}
            {`${documents.length - DOCUMENTS_LENGTH} more document${
              documents.length - DOCUMENTS_LENGTH === 1 ? "" : "s"
            }`}
          </Typography>
        </div>
        <div className="_right">
          <Link to="/admin/documents" className="hover_underline">
            <Typography color="primary" style={{ fontWeight: 500 }}>
              See all
            </Typography>
          </Link>
        </div>
      </div>
    </div>
  );

  const onFilter = (evt: ChangeEvent<HTMLInputElement>, state: string) => {
    setFilterState({ ...filterState, [state]: evt.target.checked });
  };

  return (
    <AdminDashboardLayout onFilter={onFilter}>
      <>
        <div className="page-central">
          <div>
            <div className="central-search _pc">
              <TextField
                fullWidth
                placeholder="Search listed properties"
                // temporarily hidden
                // onChange={(evt) => setSearchText(evt?.target?.value)}
              />
              <HeaderIconSearch />
            </div>
            {!searchText && <ReportCards />}
          </div>
          <div>
            <DashboardTable
              filter={{ search: searchText, filter: filterState }}
            />
          </div>
          <div>
            <Listings />
          </div>
        </div>
        <div className="page-side">
          <div className="push-notification-banner">
            <div className="_left">
              <Typography variant="h4" style={{ color: "#686868" }}>
                Push Notification
                <span className="coming-soon">Coming soon</span>
              </Typography>
              <Typography style={{ color: "#000000" }}>
                Send a general message to developers
              </Typography>
            </div>
            <div className="_right">
              <IconPushNotification />
            </div>
          </div>

          <NotificationsCard />
          {/* temporarily hidden */}
          {/* <DocumentsCard /> */}
        </div>
      </>
    </AdminDashboardLayout>
  );
};

export default Dashboard;
