import { useState } from "react";
import ForgottenPassword from "../../modals/ForgottenPassword";
import ResetPassword from "../../modals/ResetPassword";
import useInput from "../../../../hooks/useInput";
import PasswordUpdated from "../../modals/PasswordUpdated";
import { Link, useNavigate } from "react-router-dom";
import LoginVerification from "../../modals/LoginVerification";

function SignIn() {
  const navigate = useNavigate();
  const [displayModals, setDisplayModals] = useState({
    forgottenPassword: false,
    resetPassword: false,
    passwordUpdated: false,
    loginVerification: false,
  });
  const email = useInput("");
  const password = useInput("");
  const [showPassword, setShowPassword] = useState(false);
  const openModal = (modalName: string) =>
    setDisplayModals((prev) => ({ ...prev, [modalName]: true }));
  const closeModal = (modalName: string) =>
    setDisplayModals((prev) => ({ ...prev, [modalName]: false }));
  const closeAllModal = () =>
    setDisplayModals({
      forgottenPassword: false,
      resetPassword: false,
      passwordUpdated: false,
      loginVerification: false,
    });
  const signIn = () => {
    navigate("/seller/dashboard");
  };
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  return (
    <div className="pt-16">
      <img
        src="/assets/images/background.svg"
        className="absolute top-0 left-0 z-[-1] h-full w-full object-cover"
        alt=""
      />
      <div className="flex space-x-4 items-center justify-center">
        <img className="h-[40px]" src="/assets/images/logo.svg" alt="" />
        <div className="flex flex-col items-center italic uppercase text-[#084E14] font-[600] text-[16px]">
          <h4>Home</h4>
          <div className="flex space-x-1 items-center">
            <div className="w-[54px] h-[2px] bg-custom-gradient" />
            <span className="bg-custom-gradient text-[16px] bg-clip-text text-transparent">
              &
            </span>
            <div className="w-[54px] h-[2px] bg-custom-gradient" />
          </div>
          <h4>Abroad</h4>
        </div>
      </div>
      <h3 className="text-primary text-center font-[500] text-[16px]">
        Simplifying Property Purchases in Nigeria
      </h3>
      <section className="mt-[20px] bg-white px-[35px] pt-[10px] pb-10 sm:shadow-md rounded-[20px] mx-auto max-w-[526px]">
        <form action="">
          <h2 className="text-textBlack font-[600] text-[16px] mt-14">
            Log in to your account
          </h2>
          <div className="grid gap-[15px] mt-[26px]">
            <div>
              <label
                htmlFor="email"
                className="text-textBlack text-[12px] mb-[10px] block"
              >
                Email
              </label>
              <input
                value={email.value}
                onChange={email.onChange}
                id="email"
                type="email"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div className="">
              <label htmlFor="password" className="custom__label">
                Password
              </label>
              <div className="relative">
                <input
                  value={password.value}
                  onChange={password.onChange}
                  id="password"
                  type={showPassword ? "text" : "password"}
                  className="custom__input"
                />
                {showPassword ? (
                  <img
                    onClick={togglePasswordVisibility}
                    className="password__eye"
                    src="/assets/images/eye-open.svg"
                    alt=""
                  />
                ) : (
                  <img
                    onClick={togglePasswordVisibility}
                    className="password__eye"
                    src="/assets/images/eye-closed.svg"
                    alt=""
                  />
                )}
              </div>
            </div>
          </div>
          {/* <span
            onClick={() => openModal("forgottenPassword")}
            className="underline cursor-pointer"
          >
            Forgot Password?
          </span> */}
          <button
            type="button"
            onClick={() => openModal("loginVerification")}
            className="uppercase block mx-auto w-full bg-primary text-textWhite rounded-[10px] mt-[30px] mb-[5px] py-[10px] text-[16px] font-[600]"
          >
            Sign In
          </button>
        </form>
        {/* <p className="text-center text-[14px] text-black/50">
          Or Sign in using
        </p>
        <div className="flex flex-col sm:flex-row justify-center gap-x-5 gap-y-2 mt-2 mb-[10px]">
          <button className="flex w-full sm:w-fit justify-center sm:justify-start items-center space-x-7 rounded-[10px] py-[10px] pr-[25px] pl-3 border border-[#E1E1E1]">
            <img src="/assets/images/google.svg" alt="" />
            <span className="text-textBlack text-[14px] font-[400]">
              Login using Google
            </span>
          </button>
          <button className="flex w-full sm:w-fit justify-center sm:justify-start items-center space-x-7 rounded-[10px] py-[10px] pr-[25px] pl-3 border border-[#E1E1E1]">
            <img className="h-8" src="/assets/images/apple.svg" alt="" />
            <span className="text-textBlack text-[14px] mx-auto font-[400]">
              Login using Apple
            </span>
          </button>
        </div> */}
        <p className="text-center text-textBlack font-[500] text-[16px]">
          Don't have an account?{" "}
          <Link to="/seller/sign-up">
            <span className="text-primary">Create an account</span>
          </Link>
        </p>
      </section>
      <ForgottenPassword
        submitAction={() => openModal("resetPassword")}
        handleClose={() => closeModal("forgottenPassword")}
        email={email.value}
        setEmail={email.onChange}
        open={displayModals.forgottenPassword}
      />
      <ResetPassword
        email={email.value}
        handleClose={() => closeModal("resetPassword")}
        open={displayModals.resetPassword}
        submitAction={() => openModal("passwordUpdated")}
      />
      <PasswordUpdated
        handleClose={closeAllModal}
        open={displayModals.passwordUpdated}
      />
      <LoginVerification
        email={email.value}
        open={displayModals.loginVerification}
        handleClose={() => closeModal("loginVerification")}
        submitAction={signIn}
      />
    </div>
  );
}

export default SignIn;
