import { MenuItem, Modal, Select, SelectChangeEvent } from "@mui/material";
import { CustomModalProps, ListingInterface } from "../../../../types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  convertDateFormat,
  validateEmail,
} from "../../../common/utils/validation";
import PhoneInput from "react-phone-number-input";
import { DisplayAmount } from "../../../common/components/CurrencySwitch";
import { getPaymentAmount, makePayment } from "../../../../apis/buyer/payment";
import { usePaystackPayment } from "react-paystack";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { HookConfig } from "react-paystack/dist/types";
import { PAYMENT_TYPES } from "../../../common/utils/constants";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { generateUniqueID } from "../../../common/utils/generateUniqueId";

interface Props extends CustomModalProps {
  listing: ListingInterface;
}
const LiveVirtualTour: React.FC<Props> = ({ handleClose, open, listing }) => {
  const [amount, setAmount] = useState("0");
  const [contactMethod, setContactMethod] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState<boolean | null>(null);
  const { user } = useSelector((state: RootState) => state.user);
  const [channel, setChannel] = useState("GoogleMeet");
  const [datetime, setDateTime] = useState<Dayjs | null>(null);
  const [screen, setScreen] = useState<"details" | "payment">("details");
  const [contactDetails, setContactDetails] = useState<string | undefined>();
  const [errorMsg, setErrorMsg] = useState("");
  const paystackKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || "";
  const fieldsUnfilled =
    !contactMethod || !channel || !datetime || !contactDetails;

  let referenceID = "";
  generateUniqueID(user.email).then((id) => (referenceID = id));
  const changeToPayment = () => {
    setErrorMsg("");
    if (contactMethod === "Email" && !validateEmail(contactDetails!))
      return setErrorMsg("Enter a valid e-mail");
    setScreen("payment");
  };

  const config: HookConfig = {
    reference: referenceID,
    email: user.email,
    amount: Number(amount) * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: paystackKey,
  };

  const onSuccess = async (reference: any) => {
    setIsLoading(true);
    const schedule = {
      representative_name: `${user?.first_name} ${user?.last_name}`,
      scheduled_time: datetime,
      contact_email: contactDetails,
      contact_phone_number: contactDetails,
    };

    if (contactMethod === "Email") delete schedule.contact_phone_number;
    else if (contactMethod === "Number") delete schedule.contact_email;
    const response = await makePayment(listing?.lid!, {
      payment_type: PAYMENT_TYPES.VIRTUAL_TOUR,
      payment_reference: reference.trxref,
      amount: Number(amount).toFixed(2),
      schedule,
    });
    setIsLoading(false);

    if (response) {
      setPaymentSuccess(true);
    } else {
      setPaymentSuccess(false);
    }
  };

  const onClose = () => {};
  const initializePayment = usePaystackPayment(config);

  const closeModal = () => {
    setPaymentSuccess(null);
    handleClose();
  };
  const PaymentSection = () => (
    <div className="payment-modal relative">
      <img
        onClick={() => setScreen("details")}
        className="absolute cursor-pointer hover:opacity-50 duration-500  top-[50px] left-7 sm:left-[33px] translate-y-[35%]"
        src="/assets/images/arrow-left.svg"
        alt=""
      />
      <p className="payment-modal__title">Payment for Live Virtual Tour</p>
      <div className="payment-modal__price">
        <p className="naira flex justify-center">
          <DisplayAmount amount={amount} />
        </p>
        <p className="text-[13px]">(VAT Inclusive)</p>
      </div>
      <p className="payment-modal__field">Property Name:</p>
      <p className="payment-modal__value">{listing.name}</p>
      <div className="mt-[41px] mb-[21px]">
        <p className="payment-modal__field">Location:</p>
        <p className="payment-modal__value">{listing.state}</p>
      </div>
      <p className="payment-modal__info w-[90%] mx-auto">
        A confirmation email will be sent to you upon successful payment. <br />{" "}
        <br /> Note that this payment will be made in naira and is non-refundable..
      </p>
      <button
        onClick={() => initializePayment({ onSuccess, onClose })}
        className="filled-button w-full"
      >
        Click Here To Pay
      </button>
    </div>
  );

  const handleBackendPaymentSuccess = () => {
    setPaymentSuccess(null);
    setScreen("details");
    handleClose();
  };

  const PaymentFailed = () => (
    <div className="payment-modal relative">
      <img
        onClick={() => setPaymentSuccess(null)}
        src="/assets/images/close-circle.svg"
        className="payment-modal__close hover:opacity-70 duration-300"
        alt=""
      />
      <p className="payment-modal__title">Payment Failed</p>
      <p className="payment-modal__field mt-6 !text-justify">
        There was an issue while processing your payment. Please try again or
        contact support if the issue persists.
      </p>
      <button
        onClick={() => setPaymentSuccess(null)}
        className="outline-button mt-8"
      >
        CLOSE
      </button>
    </div>
  );

  const PaymentSuccessFul = () => (
    <div className="payment-modal relative">
      <img
        onClick={handleBackendPaymentSuccess}
        src="/assets/images/close-circle.svg"
        className="payment-modal__close hover:opacity-70 duration-300"
        alt=""
      />
      <p className="payment-modal__title">Payment Successful!</p>
      <p className="payment-modal__field mt-6 !text-justify">
        You will receive follow-up instructions via email. Thank you.
      </p>
      <button
        onClick={handleBackendPaymentSuccess}
        className="outline-button mt-8"
      >
        CLOSE
      </button>
    </div>
  );

  const LoadingState = () => (
    <div className="payment-modal relative flex flex-col items-center">
      <p className="payment-modal__title">Loading...</p>
      <div className="payment-loader"></div>
    </div>
  );

  useEffect(() => {
    async function getAmount() {
      const response = await getPaymentAmount();
      const paymentData = response.data.find(
        (type: any) => type.payment_type === PAYMENT_TYPES.VIRTUAL_TOUR
      );
      setAmount(paymentData.total_amount);
    }
    getAmount();
  }, [amount]);

  return (
    <Modal open={open} onClose={closeModal}>
      <>
        {isLoading && <LoadingState />}
        {paymentSuccess && <PaymentSuccessFul />}
        {paymentSuccess === false && <PaymentFailed />}
        {!isLoading && paymentSuccess === null && screen === "details" && (
          <DetailsSection
            listing={listing}
            channel={channel}
            setChannel={setChannel}
            contactMethod={contactMethod}
            setContactMethod={setContactMethod}
            datetime={datetime}
            setDatetime={setDateTime}
            contactDetails={contactDetails}
            setContactDetails={setContactDetails}
            fieldsUnfilled={fieldsUnfilled}
            handleNext={changeToPayment}
            errorMsg={errorMsg}
            close={closeModal}
          />
        )}
        {!isLoading && paymentSuccess === null && screen === "payment" && (
          <PaymentSection />
        )}
      </>
    </Modal>
  );
};

export default LiveVirtualTour;

interface IDetailsSection {
  listing: ListingInterface;
  channel: string;
  setChannel: Dispatch<SetStateAction<string>>;
  datetime: Dayjs | null;
  setDatetime: Dispatch<SetStateAction<Dayjs | null>>;
  contactDetails: string | undefined;
  setContactDetails: Dispatch<SetStateAction<string | undefined>>;
  contactMethod: string;
  setContactMethod: Dispatch<SetStateAction<string>>;
  fieldsUnfilled: boolean;
  handleNext: () => void;
  errorMsg: string;
  close: () => void;
}
const DetailsSection: React.FC<IDetailsSection> = ({
  listing,
  channel,
  setChannel,
  datetime,
  setDatetime,
  contactDetails,
  setContactDetails,
  contactMethod,
  setContactMethod,
  fieldsUnfilled,
  handleNext,
  errorMsg,
  close,
}) => (
  <div className="payment-modal !text-left">
    <img
      onClick={close}
      src="/assets/images/close-circle.svg"
      className="payment-modal__close hover:opacity-70 duration-300"
      alt=""
    />
    <div className="mx-auto mt-4">
      <p className="payment-modal__title !text-left">
        Schedule a Live Virtual Tour
      </p>
      <span className="block italic text-[14px] leading-[18px] my-[26px]">
        Please note that live virtual tours are only available on Monday -
        Saturday 9am-4pm (WAT)
      </span>
      <div className="flex flex-col items-start gap-x-2">
        <p className="payment-modal__field">Property Name:</p>
        <p className="payment-modal__value">{listing.name}</p>
      </div>
      <div className="mt-3 flex flex-col items-start flex-wrap gap-x-20">
        <p className="payment-modal__field">Location:</p>
        <p className="payment-modal__value">{listing.state}</p>
      </div>
      <div className="mt-[18px] mb-[14px] flex flex-col gap-y-5">
        <div>
          <label className="payment-modal__label" htmlFor="">
            Preferred Channel?*
          </label>
          <Select
            value={channel}
            id="channel"
            name="channel"
            onChange={(e: SelectChangeEvent) => {
              setChannel(e.target.value);
            }}
            style={{ width: "100%", height: 48, borderRadius: 8 }}
          >
            <MenuItem value="Zoom">Zoom</MenuItem>
            <MenuItem value="GoogleMeet">Google Meet</MenuItem>
            <MenuItem value="Whatsapp">Whatsapp Call</MenuItem>
          </Select>
        </div>
        <div>
          <label className="payment-modal__label" htmlFor="">
            Preferred Date/Time:*
          </label>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              disablePast
              value={datetime}
              onChange={(newValue) => setDatetime(newValue)}
              sx={{ width: "100%" }}
            />
          </LocalizationProvider>
        </div>
        <div>
          <label className="payment-modal__label" htmlFor="">
            How do you want us to contact you?*
          </label>
          <Select
            value={contactMethod}
            id="contact"
            name="contact"
            onChange={(e: SelectChangeEvent) => {
              setContactMethod(e.target.value);
            }}
            style={{ width: "100%", height: 48, borderRadius: 8 }}
          >
            <MenuItem value="Email">Email</MenuItem>
            <MenuItem value="Number">Phone Number</MenuItem>
          </Select>
        </div>
        {!!contactMethod && (
          <>
            <div>
              <label className="payment-modal__label" htmlFor="">
                {contactMethod === "Email"
                  ? "Enter your e-mail"
                  : "Enter your phone number"}
                *
              </label>
              {contactMethod === "Number" && (
                <PhoneInput
                  placeholder="Enter phone number"
                  international
                  value={contactDetails}
                  className="custom__input"
                  onChange={setContactDetails}
                  defaultCountry="NG"
                />
              )}
              {contactMethod === "Email" && (
                <input
                  value={contactDetails}
                  onChange={(e) => setContactDetails(e.target.value)}
                  type="email"
                  className="custom__input"
                />
              )}
            </div>
          </>
        )}
      </div>
    </div>
    <div className="mt-8">
      {errorMsg && (
        <span className="text-sm text-red-500 !text-[13px] block mb-1 text-center">
          {errorMsg}
        </span>
      )}
      <button
        disabled={fieldsUnfilled}
        onClick={handleNext}
        className="filled-button w-full block disabled:opacity-35"
      >
        Continue
      </button>
    </div>
  </div>
);
