import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../types";
import React from "react";
import { Link } from "react-router-dom";

const PurchaseRequest: React.FC<CustomModalProps> = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="w-[90vw] sm:w-[830px] bg-white pb-[54px] px-6 sm:px-[90px] text-center relative card-shadow rounded-lg mx-auto">
        <img
          onClick={handleClose}
          src="/assets/images/close-circle.svg"
          className="absolute cursor-pointer right-6 sm:right-[43px] top-6 sm:top-[43px]"
          alt=""
        />
        <div className="my-[99px] h-[150px] w-[150px]"></div>
        <p className="text-center text-textBlack font-semibold text-[28px]">
          Purchase Request Submitted
        </p>
        <p className="text-center text-[20px] mt-[5px] mb-[54px]">
          The agent will review your request and decide whether to proceed with
          the sale. You will be notified of the decision via email
        </p>
        <Link to="/buyer/property-checkout">
          <button className="filled-button w-full sm:w-[calc(100%-250px)] mx-auto">
            CONTINUE BROWSING
          </button>
        </Link>
      </div>
    </Modal>
  );
};

export default PurchaseRequest;
