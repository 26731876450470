import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Add } from "@mui/icons-material";

import AdminDashboardLayout from "../../commonComponents/DashboardLayout";
import PropertyListingsTable from "./components/PropertyListingsTable";
import CustomModal from "../../../common/components/CustomModal";
import PropertyListingApplication from "./PropertyListingApplication";
import {
  IAPIResponse,
  IListing,
  IListings,
} from "../../../common/utils/interfaces";
import { getMyListingDetails } from "../../../../apis/listings/sellers";

const PropertyListings: React.FC = () => {
  const location = useLocation();
  const queries = location.search;
  const lid = new URLSearchParams(queries).get("lid");

  const [detailData, setDetailData] = useState<IListings | null>(null);
  const [fullDetailData, setFullDetailData] = useState<IListing | null>(null);
  const [filterState, setFilterState] = useState<any>({
    approved: false,
    pending: false,
    rejected: false,
  });

  useEffect(() => {
    const fetchData = async (lid?: string) => {
      const result: IAPIResponse | undefined = await getMyListingDetails(
        lid ? { id: lid } : detailData
      );

      if (result) {
        const { data, count } = result;
        setFullDetailData(data);
      }
    };

    if (detailData) {
      fetchData();
    } else if (lid) {
      fetchData(lid);
    }
  }, [detailData, lid]);

  const onFilter = (evt: ChangeEvent<HTMLInputElement>, state: string) => {
    setFilterState({ ...filterState, [state]: evt.target.checked });
  };

  return (
    <AdminDashboardLayout hasHeader searchPlaceholder="Requests">
      <>
        <div className="page-central">
          <div>
            <PropertyListingsTable
              setDetailData={(value) => setDetailData(value)}
              filter={{ filter: filterState }}
            />
          </div>
        </div>
        <CustomModal
          showModal={!!fullDetailData}
          onCloseModal={() => setFullDetailData(null)}
        >
          <PropertyListingApplication
            psuedoData={detailData}
            fullDetailData={fullDetailData}
            closeModal={() => setFullDetailData(null)}
          />
        </CustomModal>

        {/* <div
          className="floating-button"
          onClick={() => navigate("/seller/list-property")}
        >
          <Add />
        </div> */}
      </>
    </AdminDashboardLayout>
  );
};

export default PropertyListings;
