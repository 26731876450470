import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { APP_INACTIVITY_MINUTES } from "../utils/constants";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../apis/accounts/auth";
import { AppDispatch } from "../../../store/store";

const IdleTimer = ({
  children,
  isAuthenticated,
}: {
  children: React.ReactNode;
  isAuthenticated: boolean;
}) => {
  //Get RememberMe state from User
  const rememberUser = localStorage.getItem("rememberMe");
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  // Eevents that determine In-Activity
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  let timer: any = null;

  const inactivityTimeout = APP_INACTIVITY_MINUTES * 60 * 1000;

  const resetTimer = () => {
    clearTimeout(timer);
    timer = null;
  };

  const logoutAction = () => {
    dispatch(logoutUser());
    navigate("/sign-in");
  };

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      events.forEach((item) => window.removeEventListener(item, initTimer));
      logoutAction(); // Log out the user
    }, inactivityTimeout);
  };

  useEffect(() => {
    if (isAuthenticated) {
      handleLogoutTimer();
      events.forEach((event) => window.addEventListener(event, initTimer));
    }

    return () => {
      resetTimer();
      events.forEach((event) => window.removeEventListener(event, initTimer));
    };
  }, [isAuthenticated]);

  const initTimer = () => {
    resetTimer();
    handleLogoutTimer();
  };

  return children;
};

export default IdleTimer;
