import React, { ChangeEvent, useEffect, useState } from "react";

import AdminDashboardLayout from "../../commonComponents/DashboardLayout";
import PropertyListingsTable from "./components/PropertyListingsTable";
import CustomModal from "../../../common/components/CustomModal";
import PropertyListingApplication from "./PropertyListingApplication";
import {
  IAPIResponse,
  IListing,
  IListings,
  IPropertyListing,
} from "../../../common/utils/interfaces";
import { getListingDetails } from "../../../../apis/admin/listings";

const PropertyListings: React.FC = () => {
  const [detailData, setDetailData] = useState<IPropertyListing | null>(null);
  const [fullDetailData, setFullDetailData] = useState<IListing | null>(null);
  const [filterState, setFilterState] = useState<any>({
    approved: false,
    pending: false,
    rejected: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      const result: IAPIResponse | undefined = await getListingDetails(
        detailData
      );

      if (result) {
        const { data, count } = result;
        setFullDetailData(data);
      }
    };

    if (detailData) {
      fetchData();
    }
  }, [detailData]);

  const onFilter = (evt: ChangeEvent<HTMLInputElement>, state: string) => {
    setFilterState({ ...filterState, [state]: evt.target.checked });
  };

  return (
    <AdminDashboardLayout hasHeader searchPlaceholder="Requests">
      <>
        <div className="page-central">
          <div>
            <PropertyListingsTable
              setDetailData={(value) => setDetailData(value)}
              filter={{ filter: filterState }}
            />
          </div>
        </div>
        <CustomModal
          showModal={!!fullDetailData}
          onCloseModal={() => setFullDetailData(null)}
        >
          <PropertyListingApplication
            psuedoData={detailData}
            fullDetailData={fullDetailData}
            closeModal={() => setFullDetailData(null)}
          />
        </CustomModal>
      </>
    </AdminDashboardLayout>
  );
};

export default PropertyListings;
