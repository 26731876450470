import * as React from "react";
import moment from "moment";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import {
  IAPIResponse,
  IBuyerTransaction,
  IFilterFetch,
  IListings,
} from "../../../../../../common/utils/interfaces";
import CustomTable, {
  HeadCell,
} from "../../../../../../common/components/CustomTable/CustomTable";
import { getMyListings } from "../../../../../../../apis/listings/sellers";
import CustomBadge from "../../../../../../common/components/CustomBadge";
import { MoreHoriz } from "@mui/icons-material";
import { getBuyerTransactions } from "../../../../../../../apis/listings/buyer";
import { Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";

type Props = {
  setDetailData: (arg: IListings) => void;
  filter: IFilterFetch;
};

const headCells: readonly HeadCell[] = [
  {
    id: "transactionDate",
    numeric: false,
    disablePadding: true,
    label: "Transaction Date",
  },
  {
    id: "paymentType",
    numeric: false,
    disablePadding: false,
    label: "Payment Type",
  },
  {
    id: "propertyName",
    numeric: false,
    disablePadding: false,
    label: "Property Name",
  },
  {
    id: "paymentStautus",
    numeric: false,
    disablePadding: false,
    label: "Payment Status",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];

const TransactionsTable: React.FC<Props> = ({
  setDetailData,
  filter,
}: Props) => {
  const { layoutSearchText } = useSelector((state: RootState) => state.search);

  const [tableData, setTableData] = React.useState<IBuyerTransaction[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<
    (EventTarget & SVGSVGElement) | null
  >();

  const tableTitle = { mainText: "My Transactions", subText: "transactions" };

  React.useEffect(() => {
    fetchTableData();
  }, [filter, layoutSearchText]);

  const fetchTableData = async () => {
    const response: IAPIResponse | undefined = await getBuyerTransactions(layoutSearchText);

    if (response?.status) {
      const { data, count } = response;
      setTableData(data);
    }
  };

  const getBadgeMode = (text: string) => {
    switch (text) {
      case "approved":
        return "success";

      case "rejected":
        return "error";

      default:
        return "info";
    }
  };

  const selectData = (
    evt: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    selectedData: IListings
  ) => {
    evt.stopPropagation();

    setDetailData(selectedData);
  };

  const MappedTableRows = ({
    row,
    labelId,
    isItemSelected,
  }: {
    row: IBuyerTransaction;
    labelId: string;
    isItemSelected: boolean;
  }) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {moment(row.created).format("Do MMM, YYYY")}
      </TableCell>
      <TableCell align="left">{row.payment_type_name}</TableCell>
      <TableCell align="left">{row.listing_name}</TableCell>
      <TableCell align="center">
        <CustomBadge mode={getBadgeMode(row.status)}>
          {row.status_display}
        </CustomBadge>
      </TableCell>
      <TableCell align="center">
        {row.download_receipt_url ? (
          <a href={row.download_receipt_url} download>
            Download receipt
          </a>
        ) : (
          <span
            style={{
              cursor: "default",
              opacity: 0.4,
            }}
          >
            Download receipt
          </span>
        )}
        {/* <MoreHoriz
          onClick={(evt) => {
            evt.stopPropagation();
            setAnchorEl(evt.currentTarget);
          }}
        /> */}
      </TableCell>
    </>
  );

  const ActionsMenu = () => (
    <Menu
      // setAnchorEl={setAnchorEl}
      anchorEl={anchorEl}
      open={!!anchorEl}
      // id={id}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "center", horizontal: "right" }}
      // verifiedPerms={verifiedPerms}
      // userID={userID}
      // setShowSideBar={setShowSideBar}
    >
      <MenuItem
        onClick={() => {
          setAnchorEl(null);
        }}
      >
        View details
      </MenuItem>
      <MenuItem
        onClick={() => {
          setAnchorEl(null);
        }}
      >
        Buy property
      </MenuItem>
      <MenuItem
        disabled
        onClick={() => {
          setAnchorEl(null);
        }}
      >
        View documents
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <CustomTable
        tableTitle={tableTitle}
        headCells={headCells}
        rows={tableData}
        // rows={testt}
        mappedTableRows={MappedTableRows}
      />
      <ActionsMenu />
    </>
  );
};

export default TransactionsTable;
