import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { logoutUser } from "../../../apis/accounts/auth";
import { AppDispatch } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const UserDropdown = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // @ts-ignore
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const userType = user?.["user-types"]?.[0]?.type;

  const routeToDashboard = () => {
    if (userType === "buyer") navigate("/dashboard/activities");
    else if (userType === "seller") navigate("/seller/dashboard");
    else navigate("/admin/dashboard");
  };

  const routeToMyListings = () => {
    if (userType === "buyer") navigate("/dashboard/my-properties");
    else if (userType === "seller") navigate("/seller/listings");
    else navigate("/admin/property-listings");
  };

  const routeToSavedProperties = () => {
    if (userType === "buyer") navigate("/dashboard/saved-properties");
  };
  const routeToTransactions = () => {
    if (userType === "buyer") navigate("/dashboard/transactions");
    else if (userType === "seller") navigate("/seller/transactions");
    else if (userType === "admin") navigate("/admin/transactions");
  };
  const routeToProfile = () => {
    if (userType === "seller") navigate("/seller/profile");
  };
  const routeToDevelopers = () => {
    if (userType === "admin") navigate("/admin/developers");
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ minWidth: "unset", padding: "9px 10px" }}
      >
        <div className="flex gap-x-2">
          <span className="capitalize text-textBlack">
            {user?.first_name} {user?.last_name?.[0]}
          </span>
          <img src="/assets/images/chevron-down.svg" alt="" />
        </div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {userType !== "buyer" && (
          <MenuItem onClick={routeToDashboard}>
            <div className="flex py-[10px] gap-x-[13px] w-[240px]">
              <img src="/assets/images/dashboard.svg" alt="" />
              <span>Dashboard</span>
            </div>
          </MenuItem>
        )}
        <MenuItem onClick={routeToMyListings}>
          <div className="flex py-[10px] gap-x-[13px] w-[240px]">
            <img src="/assets/images/listing.svg" alt="" />
            <span>
              {userType === "buyer" ? "My Properties" : "My Listings"}
            </span>
          </div>
        </MenuItem>
        {userType === "seller" && (
          <MenuItem onClick={routeToProfile}>
            <div className="flex py-[10px] gap-x-[13px]">
              <img src="/assets/images/account.svg" alt="" />
              <span>Profile</span>
            </div>
          </MenuItem>
        )}
        {userType === "admin" && (
          <MenuItem onClick={routeToDevelopers}>
            <div className="flex py-[10px] gap-x-[13px]">
              <img src="/assets/images/developers.svg" alt="" />
              <span>Developers</span>
            </div>
          </MenuItem>
        )}
        {userType === "buyer" && (
          <MenuItem onClick={routeToSavedProperties}>
            <div className="flex py-[10px] gap-x-[13px]">
              <img src="/assets/images/heart.svg" alt="" />
              <span>Saved Properties</span>
            </div>
          </MenuItem>
        )}
        <MenuItem onClick={routeToTransactions}>
          <div className="flex py-[10px] gap-x-[13px]">
            <img src="/assets/images/card.svg" alt="" />
            <span>Transactions</span>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            dispatch(logoutUser());
            navigate("/");

            handleClose();
          }}
        >
          <div className="flex py-[10px] gap-x-[13px]">
            <img src="/assets/images/logout.svg" alt="" />
            <span>Logout</span>
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserDropdown;
