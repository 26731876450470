export const extractErrorMsg = (data: any) => {
  const messages = [];

  if (data.errors) {
    if (Array.isArray(data.errors)) {
      data.errors.forEach((error: string) => messages.push(error));
    } else {
      for (const field in data.errors) {
        if (data.errors.hasOwnProperty(field)) {
          const errorMessages = data.errors[field];
          errorMessages.forEach((errorMessage: any) => {
            messages.push(`${errorMessage}`);
          });
        }
      }
    }
  } else if (data.data) {
    if (Array.isArray(data.data)) {
      data.data.forEach((error: string) => messages.push(error));
    } else {
      for (const field in data.data) {
        if (data.data.hasOwnProperty(field)) {
          const errorMessages = data.data[field];
          errorMessages.forEach((errorMessage: any) => {
            messages.push(`${errorMessage}`);
          });
        }
      }
    }
  } else if (data.message) {
    messages.push(data);
  } else if (data) {
    messages.push(data);
  }

  return messages;
};

export const validateEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const convertDateFormat = (input: string) => {
  const date = new Date(input);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(date.getUTCMilliseconds()).padStart(6, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}+00:00`;
};
