import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/buyer/userSlice";
import { verifyAuth } from "../apis/accounts/auth";
import listingsReducer from "./slices/buyer/listingsSlice";
import listingReducer from "./slices/buyer/listingSlice";
import currencyReducer from "./slices/general/currencySlice";
import searchReducer from "./slices/general/searchSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    listings: listingsReducer,
    listing: listingReducer,
    currency: currencyReducer,
    search: searchReducer,
  },
});

store.dispatch(verifyAuth());

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
