import HomeLayout from "../../commonComponents/HomeLayout";

const TermsAndConditions = () => {
  return (
    <HomeLayout>
      <section className="items-center px-8 sm:px-10 lg:px-[174px] pt-[30px] text-center max-w-[1300px] mx-auto">
        <h2 className="h2">Terms and Conditions</h2>
        <p className="body-description mt-8 text-justify">
          The standard terms and conditions contained on this Website shall
          govern your use of this Website. These terms shall be fully applied
          and shall apply to your use of this Website. By using this Website,
          you accept that you have read and understood the terms and conditions
          and agree to be bound by the terms and conditions written herein. If
          you do not agree with these terms and conditions, please refrain from
          using the Website. <br />
          <br /> If you are under the age of 18 (a minor) you are not allowed to
          use this website. <br /> <br />{" "}
          <strong>Protection of User ID:</strong> <br /> Any User ID and
          password you may have created for the purpose of accessing the Website
          are confidential to you. You shall take all reasonable steps to ensure
          that your User ID and password remain confidential to you. <br />
          <br />
          <strong>Collection and Use of Information:</strong> <br /> By using
          this Website, you consent to the collection, storage, and use of your
          private information. Information such as your name, email, address,
          contact details, and any other information provided while using this
          Website. <br />
          <br /> The information provided will be used to process transactions
          and provide you with service requests, and updates. And for any other
          purpose necessary to facilitate your transactions. <br />
          <br /> <strong>Data Sharing:</strong> <br /> We may share your
          information with our trusted third-party service providers who will
          assist us in operating our platform and conducting our business
          services. We will not sell, rent, or lease your personal information
          to third parties. <br />
          <br /> <strong>Data Security:</strong> <br /> We shall take all
          reasonable steps to ensure that your information is protected from
          unauthorized access, use, or disclosure. However, no method of
          transmission over the Internet or method of electronic storage is 100%
          secure. Payment and Transaction Processes: All payments for property
          purchases must be made using the payment methods and processes
          provided on the Website. You are responsible for ensuring that all
          payment details are provided. Sydani Group shall not be liable for any
          payment made to any other payment details not listed on the Website.
          Intellectual Property Rights: Under these Terms and Conditions, Sydani
          Group owns all intellectual property rights and materials contained on
          the Website. These include logos, texts, graphics, and software. You
          are granted a limited license to view the properties listed and
          nothing more.
          <br />
          <br />
          Nothing in this Terms and Conditions shall confer the right to do the
          following:{" "}
          <ul className="pl-10">
            {" "}
            <li className="list-disc">
              {" "}
              Publishing any material contained in this Website.
            </li>{" "}
            <li className="list-disc">
              Selling or commercializing in any form or otherwise sublicensing
              any Website Material.
            </li>{" "}
            <li className="list-disc">
              {" "}
              Publicly performing, presenting, and or showing any Website
              material{" "}
            </li>{" "}
            <li className="list-disc">
              {" "}
              Using this Website and any material contained in it for any
              activity that may be damaging to the Website or that may hinder
              the use of the Website.{" "}
            </li>{" "}
            <li className="list-disc">
              {" "}
              Using the Website in such a way that will negatively impact User
              access to the Website.{" "}
            </li>{" "}
            <li className="list-disc">
              {" "}
              Using this Website contrary to applicable laws.{" "}
            </li>{" "}
            <li className="list-disc">
              {" "}
              Using this website in a manner that will negatively impact or
              cause harm to the business entities on the Website{" "}
            </li>{" "}
            <li className="list-disc">
              {" "}
              Making use of the Website to carry out marketing and advertising
              activities.{" "}
            </li>{" "}
          </ul>{" "}
          <br />
          <strong>Refund and Cancellation:</strong> <br /> All property sales
          are subject to the Developer’s cancellation and refund policy. You are
          advised to review the developer’s policy before completing a payment.{" "}
          <br />
          <br />
          <strong>Limitation of Liability:</strong> <br /> Sydani Group, its
          employees, officers, directors, or partners shall not be held liable
          for anything arising out of or in connection to the use of this
          website whether such liability is under contract. Sydani Group their
          employees, officers, directors, or partners shall not be held liable
          for any direct, indirect consequential, or specific liability arising
          out of or in any way related to your use of this Website. <br />
          <br /> <strong>Indemnification Clause:</strong> <br /> You hereby
          agree to indemnify and hold harmless Sydani Group from and against any
          and/ or all liabilities, costs, demands, damages, and expenses arising
          from your breach of any of the provisions contained in these Terms.{" "}
          <br />
          <br /> <strong>Severability:</strong> <br /> In the event that any of
          these terms are found to be invalid, such provision(s) shall be
          removed. Such removal shall not affect the remaining provisions.{" "}
          <br />
          <br /> <strong>Amendments:</strong> <br /> Sydani Group reserves the
          right to revise or update the terms at any time and as it sees fit
          without any notification to you. You are expected to review these
          terms regularly. <br />
          <br /> <strong>Governing Law</strong> <br /> These terms will be
          governed by and construed in accordance with the laws of the Federal
          Republic of Nigeria. <br /> <br />{" "}
          <strong>
            By using this Website you consent to the Terms and Conditions.
          </strong>
        </p>
      </section>
    </HomeLayout>
  );
};

export default TermsAndConditions;
