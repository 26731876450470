import { BACKEND_URL } from "../../views/common/utils/constants";
import {
  IAPIResponse,
  IFilterFetch,
} from "../../views/common/utils/interfaces";
import { errorToastify } from "../../views/common/utils/Toasty";
import { CustomAxios } from "../customAxios";

const tempDummyList = {
  status: true,
  message: "Listing List",
  count: 4,
  next: null,
  previous: null,
  data: [
    {
      url: "http://localhost:8000/api/admin/listings/c9508352-8c7b-4e37-8b77-07d8511a10b6/",
      lid: "c9508352-8c7b-4e37-8b77-07d8511a10b6",
      name: "Villa De Casa",
      submission_date: "2024-10-14T01:02:29.651686+00:00",
      status: "pending",
    },
    {
      url: "http://localhost:8000/api/admin/listings/7e1dbe08-8e0d-4693-bfc6-b8a4215b91c9/",
      lid: "7e1dbe08-8e0d-4693-bfc6-b8a4215b91c9",
      name: "Villa De Casa 1",
      submission_date: "2024-10-14T01:04:13.720164+00:00",
      status: "pending",
    },
    {
      url: "http://localhost:8000/api/admin/listings/05f7e3c9-13af-4876-bc9c-7625ca5361cb/",
      lid: "05f7e3c9-13af-4876-bc9c-7625ca5361cb",
      name: "Villa De Casa 2",
      submission_date: "2024-10-14T01:16:15.339820+00:00",
      status: "pending",
    },
    {
      url: "http://localhost:8000/api/admin/listings/a46c16dd-4a53-49ce-8304-7f49cc0d13ec/",
      lid: "a46c16dd-4a53-49ce-8304-7f49cc0d13ec",
      name: "Villa De Casa 3",
      submission_date: "2024-10-14T01:26:07.001006+00:00",
      status: "pending",
    },
  ],
};

const tempDummyDetail = {
  status: true,
  message: "Listing Detail",
  data: {
    name: "Villa De Casa",
    description: "A very long description",
    bedrooms: 4,
    bathrooms: 4,
    square_footage: 200,
    property_type: "single_family_home",
    latitude: null,
    longitude: null,
    price: "200000.00",
    price_per_square_meter: "1000.00",
    default_currency: "USD",
    year_built: 2023,
    features: [
      {
        id: 1,
        name: "Walk-In Closet",
        category: "interior_features",
      },
      {
        id: 2,
        name: "Fireplace",
        category: "interior_features",
      },
      {
        id: 3,
        name: "Balcony/Terrace",
        category: "exterior_features",
      },
      {
        id: 4,
        name: "Courtyard",
        category: "exterior_features",
      },
      {
        id: 5,
        name: "Dishwasher",
        category: "kitchen_features",
      },
      {
        id: 6,
        name: "Pantry",
        category: "kitchen_features",
      },
      {
        id: 7,
        name: "Ocean View",
        category: "views_and_location",
      },
      {
        id: 8,
        name: "City View",
        category: "views_and_location",
      },
      {
        id: 9,
        name: "Park View",
        category: "views_and_location",
      },
    ],
    address: "address somewhere",
    street_name: "Street somewhere",
    city: null,
    lga: "Aba North",
    state: "Abia State",
    days_on_market_for: 30,
    status: "approved",
    files: [
      {
        file: "http://localhost:8000/media/listings/c9508352-8c7b-4e37-8b77-07d8511a10b6/image/-5204492255007501378..jpg",
        file_type: "image",
      },
    ],
    developer: {
      first_name: "Habeeb",
      last_name: "Oluwo",
      phone_number: "+2348131097519",
      user_type: "seller",
      picture:
        "http://localhost:8000/media/users/user_9/images/3110647668972795048.jpg",
      company_name: "Habeeb and Sons",
      company_registration_number: "12348543",
      address: "Oluyole, Ibadan",
    },
    rating: 0,
    update_status_url:
      "http://localhost:8000/api/admin/listings/c9508352-8c7b-4e37-8b77-07d8511a10b6/update-status",
    update_info_url:
      "http://localhost:8000/api/admin/listings/c9508352-8c7b-4e37-8b77-07d8511a10b6/update",
  },
};

export const getListingsList = async (filter?: IFilterFetch) => {
  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      `${BACKEND_URL}/admin/listings/`
    );

    // const result = tempDummyList;

    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const getListingDetails = async (inputData: any) => {
  const { id, url } = inputData || {};

  try {
    const { data: result }: IAPIResponse = await CustomAxios().get(
      id
        ? `${BACKEND_URL}/admin/listings/${id}/`
        : url?.replace("http:", "https:")
    );

    // const result = tempDummyDetail;

    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const postListingApplicationAction_ = async ({
  sellerUrl,
  action,
  reason,
}: {
  sellerUrl: string;
  action: string;
  reason?: string;
}) => {
  try {
    const result: IAPIResponse = await CustomAxios().post(
      // `${BACKEND_URL}/admin/listings/${sellerUrl}`,
      sellerUrl?.replace("http:", "https:"),
      {
        action,
        reason,
      }
    );
    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};

export const postListingApplicationAction = async ({
  sellerUrl,
  action,
  reason: rejection_reason,
}: {
  sellerUrl: string;
  action: string;
  reason?: string;
}) => {
  try {
    const result: IAPIResponse = await CustomAxios().post(
      // `${BACKEND_URL}/admin/listings/${sellerUrl}`,
      `${sellerUrl}update-status`?.replace("http:", "https:"),
      { action, ...(action === "reject" ? { rejection_reason } : {}) }
    );
    if (!result.status) {
      throw new Error(result.message);
    }
    return result;
  } catch (error) {
    if (typeof error === "string") {
      errorToastify(error);
    }
  }
};
