import { useEffect, useState } from "react";
import {
  IconMingcuteHeartLineOff,
  IconMingcuteHeartLineOn,
} from "./LayoutIcons";
import { successToastify } from "../utils/Toasty";

const LikeListing = ({
  isLiked,
  doToggle,
}: {
  isLiked: boolean;
  doToggle?: (state?: boolean) => void;
}) => {
  const [currentIsLiked, setCurrentIsLiked] = useState(isLiked);

  const setCurrentIsLiked_ = (newState: boolean) => {
    successToastify(`Property listing ${newState ? "liked" : "unliked"}`);
    setCurrentIsLiked(newState);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        const newState = !currentIsLiked;
        setCurrentIsLiked_(newState);
        doToggle && doToggle(newState);
      }}
      style={{ cursor: "pointer", marginLeft: "auto" }}
    >
      {currentIsLiked ? (
        <IconMingcuteHeartLineOn />
      ) : (
        <IconMingcuteHeartLineOff />
      )}
    </div>
  );
};

export default LikeListing;
