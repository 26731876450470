import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { resetPassword, verifyResetLink } from "../../../../apis/accounts/auth";
import useInput from "../../../../hooks/useInput";
import { Modal } from "@mui/material";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const { id, key } = useParams();
  const [isValidatingLink, setIsValidatingLink] = useState(true);
  const [isMakingRequest, setIsMakingRequest] = useState(false);
  const [isValidLink, setIsValidLink] = useState(false);

  const password = useInput("");
  const confirmPassword = useInput("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const lowercaseRegex = /[a-z]/;
  const hasLowerCase = lowercaseRegex.test(password.value);
  const uppercaseRegex = /[A-Z]/;
  const hasUpperCase = uppercaseRegex.test(password.value);
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const hasSpecialCharacter = specialCharRegex.test(password.value);
  const passwordMatch = password.value === confirmPassword.value;

  const updatePassword = async () => {
    setErrorMsg("");
    if (!passwordMatch) return setErrorMsg("Passwords do not match");
    if (
      !hasLowerCase ||
      !hasUpperCase ||
      !hasSpecialCharacter ||
      password.value.length < 12
    ) {
      return setErrorMsg("Ensure password meet required format");
    } else {
      setIsMakingRequest(true);
      const response = await resetPassword(`${id}/${key}`, {
        password1: password.value,
        password2: confirmPassword.value,
      });
      setIsMakingRequest(false);
      if (response.status) {
        setPasswordResetSuccess(true);
      } else {
        setErrorMsg(response.message);
      }
    }
  };

  useEffect(() => {
    const confirmResetLink = async () => {
      const response = await verifyResetLink(`${id}/${key}`);
      setIsValidatingLink(false);
      if (response.status) setIsValidLink(true);
      else setIsValidLink(false);
    };

    if (id && key) confirmResetLink();
  }, [id, key]);

  return (
    <Modal open={true} onClose={() => {}}>
      <div className="modal__container">
        <h2 className="modal__title !mb-6">Reset Password</h2>
        {isValidatingLink && (
          <div>
            <p className="text-xl">Validating Reset Link</p>
            <span className="loader2 mx-auto mt-4"></span>
          </div>
        )}
        {!isValidatingLink && isValidLink && !passwordResetSuccess && (
          <div className="flex flex-col gap-y-4">
            <div className="">
              <label htmlFor="password">Password</label>
              <div className="relative">
                <input
                  value={password.value}
                  onChange={password.onChange}
                  type={showPassword ? "text" : "password"}
                  className="custom__input"
                />
                {showPassword ? (
                  <img
                    onClick={togglePasswordVisibility}
                    className="password__eye"
                    src="/assets/images/eye-open.svg"
                    alt=""
                  />
                ) : (
                  <img
                    onClick={togglePasswordVisibility}
                    className="password__eye"
                    src="/assets/images/eye-closed.svg"
                    alt=""
                  />
                )}
              </div>
            </div>
            {password.value && (
              <div className="flex flex-col gap-y-1 text-red-500">
                <span className={`${hasLowerCase && "hidden"}`}>
                  Password must contain small letter
                </span>
                <span className={`${password.value.length >= 12 && "hidden"}`}>
                  Password must not be less than 12 characters
                </span>
                <span className={`${hasUpperCase && "hidden"}`}>
                  Password must contain capital letter
                </span>
                <span className={`${hasSpecialCharacter && "hidden"}`}>
                  Password must contain special characters
                </span>
              </div>
            )}
            <div>
              <label htmlFor="password">Confirm Password</label>
              <div className="relative">
                <input
                  value={confirmPassword.value}
                  onChange={confirmPassword.onChange}
                  type={showConfirmPassword ? "text" : "password"}
                  className="custom__input"
                />
                {showConfirmPassword ? (
                  <img
                    onClick={toggleConfirmPasswordVisibility}
                    className="password__eye"
                    src="/assets/images/eye-open.svg"
                    alt=""
                  />
                ) : (
                  <img
                    onClick={toggleConfirmPasswordVisibility}
                    className="password__eye"
                    src="/assets/images/eye-closed.svg"
                    alt=""
                  />
                )}
              </div>
            </div>
            <div className="mt-6">
              {errorMsg && (
                <p className="text-red-500 text-center">{errorMsg}</p>
              )}
              <button className="modal__action !mt-0" onClick={updatePassword}>
                {isMakingRequest ? (
                  <span className="loader"></span>
                ) : (
                  "UPDATE PASSWORD"
                )}
              </button>
            </div>
          </div>
        )}
        {!isValidatingLink && passwordResetSuccess && (
          <div>
            <p className="text-xl">Password Reset Successful</p>
            <Link to="/sign-in">
              <button className="modal__action w-full">
                RETURN TO SIGN IN
              </button>
            </Link>
          </div>
        )}
        {!isValidatingLink && !isValidLink && (
          <div>
            <p className="text-xl text-red-500">
              Reset Link provided is invalid, please visit the link sent to your
              mail
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ForgotPassword;
