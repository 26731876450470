import React, { ChangeEvent } from "react";
import DashboardLayout from "../../../common/components/DashboardLayout/DashboardLayout";
import { adminDashboardNavList } from "../../../common/global/DashboardRoutes";

const AdminDashboardLayout = ({
  children,
  hasHeader = false,
  pageHeader,
  pageBase,
  noPadding,
  searchPlaceholder,
  onSearch,
  onFilter,
}: {
  hasHeader?: boolean;
  children: JSX.Element;
  pageHeader?: { mainText: string; subText?: string; flankRight?: string };
  pageBase?: JSX.Element;
  noPadding?: boolean;
  searchPlaceholder?: string;
  onSearch?: (evt: ChangeEvent<HTMLInputElement>) => void;
  onFilter?: (evt: ChangeEvent<HTMLInputElement>, state: string) => void;
}) => {
  return (
    <DashboardLayout
      section="admin"
      hasHeader={hasHeader}
      navigationMenu={adminDashboardNavList}
      pageHeader={pageHeader}
      pageBase={pageBase}
      noPadding={noPadding}
      searchPlaceholder={searchPlaceholder}
      // onSearch={onSearch}
    >
      {children}
    </DashboardLayout>
  );
};

export default AdminDashboardLayout;
