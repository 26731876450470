import { PieChart } from "@mui/x-charts";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

import AdminDashboardLayout from "../../../commonComponents/DashboardLayout";
import SingleListing from "../../../../common/components/SingleListing";
import ImageGallery from "../../../../Buyer/modals/ImageGallery";
import mockData from "../../../../Buyer/data.json";
import { ArrowBackIos, Person } from "@mui/icons-material";
import { Typography } from "@mui/material";

const PropertyDetails = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  return (
    <AdminDashboardLayout noPadding>
      <div className="page-central">
        <div className="page-title">
          <ArrowBackIos
            style={{ fontSize: 28, cursor: "pointer" }}
            onClick={() => navigate("/admin/dashboard")}
          />
          <Typography variant="h4">Property Details</Typography>
          <div className="_avatar">
            <Person style={{ fontSize: 65 }} />
          </div>
        </div>

        <div className="px-6 sm:px-[81px]">
          <div className="grid sm:grid-cols-2 gap-y-5 gap-x-[21px] overflow-y-hidden">
            <div className="rounded-xl">
              <img
                className="h-full w-full rounded-xl object-cover"
                src="/property1.png"
                alt=""
              />
            </div>
            <div className="h-fit grid grid-cols-2 gap-4 sm:gap-[18px]">
              <img
                className="hidden sm:block h-full w-full rounded-xl"
                src="/property1.png"
                alt=""
              />
              <img
                className="hidden sm:block h-full w-full rounded-xl"
                src="/property1.png"
                alt=""
              />
              <img
                className="sm:h-full aspect-square sm:aspect-auto w-full rounded-xl"
                src="/property1.png"
                alt=""
              />
              <div className="relative sm:h-full aspect-square sm:aspect-auto w-full rounded-xl">
                <div
                  onClick={() => setShowModal(true)}
                  className="absolute w-full h-full cursor-pointer flex flex-col items-center justify-center rounded-xl bg-[#1C1B1F]/40"
                >
                  <p className="text-textWhite font-semibold text-[43px]">
                    25+
                  </p>
                  <p className="text-textWhite font-medium text-center text-[22px]">
                    Photos & Videos
                  </p>
                </div>
                <img
                  className="h-full w-full rounded-xl"
                  src="/property1.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="sm:flex mt-[55px] gap-x-[76px]">
            <section className="flex-1">
              <h1 className="h1 !font-semibold">Azure Heights Apartment</h1>
              <p className="text-justify mt-[10px] body-description">
                Discover luxury living at Azure Heights Apartments, an exclusive
                high-rise offering stunning city views and contemporary design.
                Located in the heart of the city, this property features
                spacious 1, 2, and 3-bedroom units, each equipped with modern
                amenities such as fully-fitted kitchens, in-unit laundry, and
                high-speed internet. Enjoy premium access to a rooftop pool,
                state-of-the-art fitness center, 24/7 concierge service, and
                secure parking. Perfect for professionals and families seeking
                convenience, comfort, and style, Azure Heights Apartments is
                your gateway to elevated urban living.
              </p>
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-y-[30px] mt-[25px] body-description">
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/bathroom.svg" alt="" />
                  </div>
                  <span>4 bathrooms</span>
                </span>
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/bedroom.svg" alt="" />
                  </div>
                  <span>3 bedrooms</span>
                </span>
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/distance.svg" alt="" />
                  </div>
                  <span>250sqkm</span>
                </span>
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/gym.svg" alt="" />
                  </div>
                  <span>Gym</span>
                </span>
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/garage.svg" alt="" />
                  </div>
                  <span>Garage</span>
                </span>
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/pool.svg" alt="" />
                  </div>
                  <span>Pool</span>
                </span>
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/garden.svg" alt="" />
                  </div>
                  <span>Garden</span>
                </span>
                <span className="flex gap-x-[13px] items-center">
                  <div className="h-6 w-6 flex items-center justify-center">
                    <img src="/location.svg" alt="" />
                  </div>
                  <span>Urban</span>
                </span>
              </div>
              <h3 className="h3 mt-10 mb-[25px] font-medium">
                Home Details for 15 Adeola Odeku
              </h3>
              <button className="outline-button !font-semibold">
                SEE MORE
              </button>
              {/* <MoreDetails /> */}
            </section>
            <aside className="max-w-full w-[437px]">
              <div className="px-[32px] py-[25px] card-shadow">
                <div className="flex">
                  <p>Price</p>
                  <img className="ml-[62px]" src="/heart.svg" alt="" />
                </div>
                <p className="h2 text-textBlack">$821,450</p>
                <button className="outline-button w-full my-5">TOUR</button>
                <Link to="/buyer/property/buy">
                  <button className="filled-button w-full">BUY PROPERTY</button>
                </Link>
              </div>
              <div className="px-[32px] py-[25px] card-shadow mt-[15px]">
                <p className="text-[#686868] text-[16px]">Need help?</p>
                <div className="grid grid-cols-2 gap-x-[18px] mt-[30px]">
                  <button className="outline-button">CALL</button>
                  <button className="filled-button">
                    <span>MESSAGE</span>
                  </button>
                </div>
              </div>
            </aside>
          </div>
          <p className="text-[20px] font-medium text-textBlack mb-[15px] mt-[35px]">
            Location
          </p>
          <div className="flex items-center gap-x-[15px] text-[16px]">
            <img src="/location.svg" alt="location" />
            <span>
              Azure Heights Apartments, 15 Adeola Odeku Street, Victoria Island,
              Lagos, Nigeria
            </span>
          </div>
          <div className="sm:flex gap-x-10 mt-[30px]">
            <div className="flex-1 card-shadow"></div>
            <div className="p-10 card-shadow rounded-[10px]">
              <h3 className="h3">Purchase Request Submitted!</h3>
              <div className="mt-5 flex flex-col gap-y-6">
                <div className="flex gap-x-[15px]">
                  <img src="/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">
                    Certified Developer
                  </p>
                </div>
                <div className="flex gap-x-[15px]">
                  <img src="/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">
                    Track Record
                  </p>
                </div>
                <div className="flex gap-x-[15px]">
                  <img src="/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">
                    Trusted by Diaspora Investors
                  </p>
                </div>
                <div className="flex gap-x-[15px]">
                  <img src="/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">Title Deeds</p>
                </div>
                <div className="flex gap-x-[15px]">
                  <img src="/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">
                    Certified Land Ownership
                  </p>
                </div>
                <div className="flex gap-x-[15px]">
                  <img src="/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">
                    No legal Disputes
                  </p>
                </div>
                <div className="flex gap-x-[15px]">
                  <img src="/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">
                    Environmentally Compliant
                  </p>
                </div>
                <div className="flex gap-x-[15px]">
                  <img src="/check-circle.svg" alt="" />
                  <p className="body-description text-textBlack">
                    Tax Compliance
                  </p>
                </div>
              </div>
            </div>
          </div>
          
          <section className="grid sm:grid-cols-3 gap-y-4 gap-x-[37px] my-[35px]">
            <div className="h-[309px] relative rounded-lg overflow-hidden">
              <div className="absolute h-full w-full flex flex-col gap-y-2 items-center justify-center bg-black/50">
                <img src="/virtual-tour.svg" alt="" />
                <p className="text-textWhite text-[36px] font-semibold">
                  Virtual Tour
                </p>
              </div>
              <img src="/tour.png" className="h-full w-full" alt="" />
            </div>
            <div className="h-[309px] relative rounded-lg overflow-hidden">
              <div className="absolute h-full w-full flex flex-col gap-y-2 items-center justify-center bg-black/50">
                <img src="/live-video.svg" alt="" />
                <p className="text-textWhite text-[36px] font-semibold">
                  Live Video
                </p>
              </div>
              <img src="/live-video.png" className="h-full w-full" alt="" />
            </div>
            <div className="h-[309px] relative rounded-lg overflow-hidden">
              <div className="absolute h-full w-full flex flex-col gap-y-2 items-center justify-center bg-black/50">
                <img src="/floor-plan.svg" alt="" />
                <p className="text-textWhite text-[36px] font-semibold">
                  Floor Plan
                </p>
              </div>
              <img src="/floor-plan.png" className="h-full w-full" alt="" />
            </div>
          </section>
          <ImageGallery
            open={showModal}
            handleClose={() => setShowModal(false)}
          />
        </div>
      </div>
    </AdminDashboardLayout>
  );
};

export default PropertyDetails;

const MoreDetails = () => {
  const {
    interiorFeatures,
    daysOnMarket,
    propertyInformation,
    miscellaneous,
    priceStatus,
    exteriorFeatures,
  } = mockData;
  return (
    <div className="flex flex-col gap-y-[25px]">
      <div className="card-shadow">
        <div className="rounded-t-[5px] py-[10px] px-[30px] bg-primary">
          <h4 className="font-medium text-textWhite text-[20px]">
            Interior Features
          </h4>
        </div>
        <div className="">
          {interiorFeatures.map((feature, index) => {
            const newObj = Object.values(feature)[0];
            return (
              <div className="pt-5 pb-6 px-[30px]">
                <p className="subheadings !font-medium mb-1">
                  {Object.keys(feature)}
                </p>

                <div className="flex gap-x-[65px]">
                  {Object.entries(newObj).map((key, value) => {
                    return (
                      <div>
                        <span className="body-description">
                          {key[0] && `${key[0]}:`}
                        </span>
                        <span className="subheadings ml-[2px]">{`${key[1]}`}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="card-shadow">
        <div className="rounded-t-[5px] py-[10px] px-[30px] bg-primary">
          <h4 className="font-medium text-textWhite text-[20px]">
            Exterior Features
          </h4>
        </div>
        <div className="">
          {exteriorFeatures.map((feature, index) => {
            const newObj = Object.values(feature)[0];
            return (
              <div className="pt-5 pb-6 px-[30px]">
                <p className="subheadings !font-medium mb-1">
                  {Object.keys(feature)}
                </p>

                <div className="flex gap-x-[65px]">
                  {Object.entries(newObj).map((key, value) => {
                    return (
                      <div>
                        <span className="body-description">
                          {key[0] && `${key[0]}:`}
                        </span>
                        <span className="subheadings ml-[2px]">{`${key[1]}`}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="card-shadow">
        <div className="rounded-t-[5px] py-[10px] px-[30px] bg-primary">
          <h4 className="font-medium text-textWhite text-[20px]">
            Days on Market
          </h4>
        </div>
        <div className="">
          {daysOnMarket.map((feature, index) => {
            const newObj = Object.values(feature)[0];
            return (
              <div className="pt-5 pb-6 px-[30px]">
                <p className="subheadings !font-medium mb-1">
                  {Object.keys(feature)}
                </p>

                <div className="flex gap-x-[65px]">
                  {Object.entries(newObj).map((key, value) => {
                    return (
                      <div>
                        <span className="body-description">
                          {key[0] && `${key[0]}:`}
                        </span>
                        <span className="subheadings ml-[2px]">{`${key[1]}`}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="card-shadow">
        <div className="rounded-t-[5px] py-[10px] px-[30px] bg-primary">
          <h4 className="font-medium text-textWhite text-[20px]">
            Property Information
          </h4>
        </div>
        <div className="">
          {propertyInformation.map((feature, index) => {
            const newObj = Object.values(feature)[0];
            return (
              <div className="pt-5 pb-6 px-[30px]">
                <p className="subheadings !font-medium mb-1">
                  {Object.keys(feature)}
                </p>

                <div className="flex gap-x-[65px]">
                  {Object.entries(newObj).map((key, value) => {
                    return (
                      <div>
                        <span className="body-description">
                          {key[0] && `${key[0]}:`}
                        </span>
                        <span className="subheadings ml-[2px]">{`${key[1]}`}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="card-shadow">
        <div className="rounded-t-[5px] py-[10px] px-[30px] bg-primary">
          <h4 className="font-medium text-textWhite text-[20px]">
            Price and Status
          </h4>
        </div>
        <div className="">
          {priceStatus.map((feature, index) => {
            const newObj = Object.values(feature)[0];
            return (
              <div className="pt-5 pb-6 px-[30px]">
                <p className="subheadings !font-medium mb-1">
                  {Object.keys(feature)}
                </p>

                <div className="flex gap-x-[65px]">
                  {Object.entries(newObj).map((key, value) => {
                    return (
                      <div>
                        <span className="body-description">
                          {key[0] && `${key[0]}:`}
                        </span>
                        <span className="subheadings ml-[2px]">{`${key[1]}`}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="card-shadow">
        <div className="rounded-t-[5px] py-[10px] px-[30px] bg-primary">
          <h4 className="font-medium text-textWhite text-[20px]">
            Miscellaneous
          </h4>
        </div>
        <div className="">
          {miscellaneous.map((feature, index) => {
            const newObj = Object.values(feature)[0];
            return (
              <div className="pt-5 pb-6 px-[30px]">
                <p className="subheadings !font-medium mb-1">
                  {Object.keys(feature)}
                </p>

                <div className="flex gap-x-[65px]">
                  {Object.entries(newObj).map((key, value) => {
                    return (
                      <div>
                        <span className="body-description">
                          {key[0] && `${key[0]}:`}
                        </span>
                        <span className="subheadings ml-[2px]">{`${key[1]}`}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
