import { Modal } from "@mui/material";
import React, { useState } from "react";
import { CustomModalProps } from "../../../types";
import useInput from "../../../hooks/useInput";

interface Props extends CustomModalProps {
  email: string;
}
const ResetPassword: React.FC<Props> = ({
  handleClose,
  open,
  submitAction,
  email,
}) => {

  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal__container min-h-[200px]">
        <img className="ml-auto cursor-pointer" src="/assets/images/close-circle.svg" alt="" />
        <h2 className="modal__title">Reset Password</h2>

        <span className="modal__subtitle">We've sent a link to: {email}</span>
      </div>
    </Modal>
  );
};

export default ResetPassword;
