import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../types";

const PasswordUpdated: React.FC<CustomModalProps> = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal__container">
        <h2 className="modal__title text-center">Password Updated</h2>
        <img className="mx-auto mt-4" src="/assets/images/success-icon.svg" alt="" />
        <button
          className="modal__action"
          onClick={handleClose}
        >
          RETURN TO LOGIN
        </button>
      </div>
    </Modal>
  );
};

export default PasswordUpdated;
