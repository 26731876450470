import { Box, Button, Collapse, Drawer, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IUserType } from "../../common/utils/interfaces";
import { ExpandLess, ExpandMore, Person } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { NavIconLogout } from "../../common/components/LayoutIcons";
import {
  adminDashboardNavList,
  buyerDashboardNavList,
  INavItem,
  sellerDashboardNavList,
  unauthenticatedNavList,
} from "../../common/global/DashboardRoutes";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { logoutUser } from "../../../apis/accounts/auth";
import CurrencySwitch from "../../common/components/CurrencySwitch";

const MobileNav = ({
  source,
  isDashboard,
}: {
  source: IUserType;
  isDashboard?: boolean;
}) => {
  const userState = useSelector((state: RootState) => state.user as any);

  const storedUser = JSON.parse(localStorage.getItem("user") || "{}");

  const dispatch: AppDispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [toggleSubMenu, setToggleSubMenu] = useState<any>({});

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  const logOut = async () => {
    dispatch(logoutUser());
  };

  const DrawerList = (
    <Box sx={{ width: 280, height: "100%" }} role="presentation">
      <div
        className="flex flex-col gap-y-10 pt-10 pl-6"
        style={{
          height: "100%",
          paddingBottom: 30,
        }}
      >
        {navList(source, isDashboard)
          .filter((item: INavItem) => item.title !== "Logout")
          .map((item: INavItem, index) =>
            !item.sub ? (
              <Link key={index} to={item.url}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 15,
                    ...(isDashboard && item.title === "Transactions"
                      ? { borderBottom: "solid 1px #f6f6f6", paddingBottom: 30 }
                      : {}),
                  }}
                >
                  {/* {item.icon && <span className="_green-img">{item.icon}</span>} */}
                  <span className="text-[14px]">{item.title}</span>
                </div>
              </Link>
            ) : (
              <div key={index}>
                <div
                  onClick={() =>
                    setToggleSubMenu((state: any) => ({
                      ...state,
                      [item.title]: !state[item.title],
                    }))
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 15,
                  }}
                >
                  {item.icon && <span className="_green-img">{item.icon}</span>}
                  <span className="text-[14px]">
                    {item.title}
                    {toggleSubMenu[item.title] ? (
                      <ExpandLess style={{ marginLeft: 10 }} />
                    ) : (
                      <ExpandMore style={{ marginLeft: 10 }} />
                    )}
                  </span>
                </div>
                <Collapse in={toggleSubMenu[item.title]}>
                  <div
                    style={{
                      marginLeft: 15,
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      marginTop: 10,
                    }}
                  >
                    {item.sub.map((subItem, subIndex: number) => (
                      <a key={`${index}-${subIndex}`} href={subItem.url}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 15,
                          }}
                        >
                          <span className="">{subItem.icon}</span>
                          <span className="text-[14px]">{subItem.title}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                </Collapse>
              </div>
            )
          )}
        {source !== "unauthenticated" && (
          <button>
            <div
              onClick={logOut}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 15,
              }}
            >
              {/* <span className="_green-img">
                <NavIconLogout />
              </span> */}
              <span onClick={logOut} className="text-[14px]">
                Logout
              </span>
            </div>
          </button>
        )}
      </div>
    </Box>
  );
  // @ts-ignore
  return (
    <div>
      <Button
        onClick={toggleDrawer(true)}
        style={{ minWidth: "unset", paddingLeft: 10, paddingRight: 10 }}
      >
        <img src="/assets/images/menu.svg" alt="" style={{ width: 23 }} />
      </Button>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        {source !== "unauthenticated" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "18.5px 20px",
              gap: 10,
              boxShadow: "0 1px 6px #eee",
              maxWidth: 250,
            }}
          >
            <div className="_avatar" style={{ width: 30, height: 30 }}>
              <Person style={{ fontSize: 60 }} />
            </div>
            {/* <Link to="/dashboard"> */}
            <Typography
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                fontSize: 12,
              }}
            >
              {/* @ts-ignore */}
              {userState?.user?.first_name ||
                storedUser?.first_name ||
                "-"}{" "}
              {userState?.user?.last_name || storedUser?.last_name || "-"}
            </Typography>
            {/* </Link> */}
          </div>
        )}
        <div style={{ margin: 5 }} className="_mobile">
          <CurrencySwitch />
        </div>
        {DrawerList}
      </Drawer>
    </div>
  );
};

export default MobileNav;

const navList = (source: IUserType, isDashboard?: boolean) => {
  switch (source) {
    case "admin":
      return adminDashboardNavList;

    case "seller":
      return [
        ...sellerDashboardNavList,
        ...unauthenticatedNavList.filter(({ title }) => title !== 'Sign In' && title !== 'Sign Up')
      ];

    case "unauthenticated":
      return unauthenticatedNavList;

    default:
      return [
        ...buyerDashboardNavList,
        ...unauthenticatedNavList.filter(({ title }) => title !== 'Sign In' && title !== 'Sign Up')
      ]
  }
};
