import React from "react";
import { Route } from "react-router-dom";
import UnprotectedRoute from "./views/common/global/UnprotectedRoute";
import ProtectedRoute from "./views/common/global/ProtectedRoute";

import {
  SellerSignIn as SellerSignInView,
  SellerSignUp as SellerSignUpView,
  SellerDashboard as SellerDashboardView,
  SellerProfile as SellerProfileView,
  SellerListProperty as SellerListPropertyView,
  SellerListings as SellerListingsView,
  SellerTransactions as SellerTransactionsView,
} from "./views";
import { USER_TYPES } from "./views/common/utils/constants";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import LoggedOutRoute from "./views/common/global/LoggedOutRoute";

const SellerRoutes = () => {
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.user
  );

  let dashboardUrl = "";


  if (user && 'user-types' in user) {

    // @ts-ignore
    const userType = user["user-types"][0].type;
    if (userType === "buyer") dashboardUrl = "/dashboard";
    else if (userType === "seller") dashboardUrl = "/seller/dashboard";
    else dashboardUrl = "/admin/dashboard";
  }
  return (
    <>
      <Route
        path={`/seller/signin`}
        element={
          <LoggedOutRoute
            isAuthenticated={isAuthenticated}
            redirectUrl={dashboardUrl}
          >
            <SellerSignInView />
          </LoggedOutRoute>
        }
      />
      <Route
        path={`/seller/signup`}
        element={
          <LoggedOutRoute
            isAuthenticated={isAuthenticated}
            redirectUrl={dashboardUrl}
          >
            <SellerSignUpView />
          </LoggedOutRoute>
        }
      />
      <Route
        path={`/seller`}
        element={
          <ProtectedRoute
            section={USER_TYPES.SELLER}
            isAuthenticated={isAuthenticated}
          >
            <SellerDashboardView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/seller/dashboard`}
        element={
          <ProtectedRoute
            section={USER_TYPES.SELLER}
            isAuthenticated={isAuthenticated}
          >
            <SellerDashboardView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/seller/profile`}
        element={
          <ProtectedRoute section={USER_TYPES.SELLER} isAuthenticated={true}>
            <SellerProfileView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/seller/transactions`}
        element={
          <ProtectedRoute
            section={USER_TYPES.SELLER}
            isAuthenticated={isAuthenticated}
          >
            <SellerTransactionsView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/seller/list-property`}
        element={
          <ProtectedRoute
            section={USER_TYPES.SELLER}
            isAuthenticated={isAuthenticated}
          >
            <SellerListPropertyView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/seller/listings`}
        element={
          <ProtectedRoute
            section={USER_TYPES.SELLER}
            isAuthenticated={isAuthenticated}
          >
            <SellerListingsView />
          </ProtectedRoute>
        }
      />
    </>
  );
};

export default SellerRoutes;
