import HomeLayout from "../../commonComponents/HomeLayout";
import faqData from "../../../data/faqs.json";

const Faq = () => {
  const { faqs } = faqData;
  return (
    <HomeLayout>
      <section className="items-center px-4 sm:px-10 lg:px-[174px] pt-[30px] text-center">
        <h2 className="h2">Frequently Asked Questions</h2>
        <p className="body-description mt-8 text-justify">
          <ol className="flex flex-col gap-y-6">
            {faqs.map(({ content, title }, index) => (
              <li key={title} className="flex gap-x-2">
                <p className="font-bold">{index+1}.</p>
                <div>
                  <p className="font-bold">
                    {title}
                  </p>
                  <p>
                    {content}
                  </p>
                </div>
              </li>
            ))}
          </ol>
        </p>
      </section>
    </HomeLayout>
  );
};

export default Faq;
