import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import {
  IAPIResponse,
  IBuyerProperties,
  IFilterFetch,
  IListings,
} from "../../../../../../common/utils/interfaces";
import CustomTable, {
  HeadCell,
} from "../../../../../../common/components/CustomTable/CustomTable";
import { getMyListings } from "../../../../../../../apis/listings/sellers";
import CustomBadge from "../../../../../../common/components/CustomBadge";
import { MoreHoriz } from "@mui/icons-material";
import { getBuyerProperties } from "../../../../../../../apis/listings/buyer";
import { Menu, MenuItem } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../../store/store";
import { setLayoutSearchText } from "../../../../../../../store/slices/general/searchSlice";

type Props = {
  setDetailData: (arg: IListings) => void;
  filter: IFilterFetch;
};

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "location",
    numeric: false,
    disablePadding: false,
    label: "Location",
  },
  {
    id: "marketStautus",
    numeric: false,
    disablePadding: false,
    label: "Market Status",
    align: "center",
  },
  {
    id: "purchaseStautus",
    numeric: false,
    disablePadding: false,
    label: "Purchase Status",
    align: "center",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    align: "center",
  },
];

const MyPropertiesTable: React.FC<Props> = ({
  setDetailData,
  filter,
}: Props) => {
  const navigate = useNavigate();
  const { layoutSearchText } = useSelector((state: RootState) => state.search);

  const [tableData, setTableData] = useState<IBuyerProperties[]>([]);
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & SVGSVGElement) | null
  >();
  const [selectedPropertyId, setSelectedPropertyId] = useState("");

  const tableTitle = { mainText: "My Properties", subText: "properties" };

  useEffect(() => {
    fetchTableData();
  }, [filter, layoutSearchText]);

  const fetchTableData = async () => {
    const result: IAPIResponse | undefined = await getBuyerProperties(layoutSearchText);
    if (result) {
      const { data, count } = result;
      setTableData(data);
    }
  };

  const getBadgeMode = (text: string) => {
    switch (text) {
      case "approved":
        return "success";

      case "rejected":
        return "error";

      default:
        return "info";
    }
  };

  const selectData = (
    evt: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    selectedData: IListings
  ) => {
    evt.stopPropagation();

    setDetailData(selectedData);
  };

  const MappedTableRows = ({
    row,
    labelId,
    isItemSelected,
  }: {
    row: IBuyerProperties;
    labelId: string;
    isItemSelected: boolean;
  }) => (
    <>
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{
            "aria-labelledby": labelId,
          }}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        {row.name}
      </TableCell>
      <TableCell align="left">{`${row.lga}, ${row.state}`}</TableCell>
      <TableCell align="center">
        <CustomBadge mode={getBadgeMode(row.market_status)}>
          {row.market_status}
        </CustomBadge>
      </TableCell>
      <TableCell align="center">
        <CustomBadge mode={getBadgeMode(row.status)}>{row.status}</CustomBadge>
      </TableCell>
      <TableCell align="center">
        <MoreHoriz
          onClick={(evt) => {
            evt.stopPropagation();
            setSelectedPropertyId(row.lid);
            setAnchorEl(evt.currentTarget);
          }}
        />
      </TableCell>
    </>
  );

  const ActionsMenu = () => (
    <Menu
      // setAnchorEl={setAnchorEl}
      anchorEl={anchorEl}
      open={!!anchorEl}
      // id={id}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "center", horizontal: "right" }}
      // verifiedPerms={verifiedPerms}
      // userID={userID}
      // setShowSideBar={setShowSideBar}
    >
      <MenuItem
        onClick={() => {
          navigate(`/listings/${selectedPropertyId}`);
          setAnchorEl(null);
        }}
      >
        View details
      </MenuItem>
      <MenuItem
        onClick={() => {
          setAnchorEl(null);
        }}
      >
        Buy property
      </MenuItem>
      <MenuItem
        disabled
        onClick={() => {
          setAnchorEl(null);
        }}
      >
        View documents
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <CustomTable
        tableTitle={tableTitle}
        headCells={headCells}
        rows={tableData}
        // rows={testt}
        mappedTableRows={MappedTableRows}
        additionalInfomation={
          <>
            Click on <MoreHoriz /> to access authorised information about each
            property
          </>
        }
      />
      <ActionsMenu />
    </>
  );
};

export default MyPropertiesTable;
