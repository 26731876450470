import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../types";
import OTPInput from "react-otp-input";
import React, { useRef, useState } from "react";

interface Props extends CustomModalProps {
  email: string;
}
const LoginVerification: React.FC<Props> = ({
  open,
  handleClose,
  submitAction,
  email,
}) => {
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="modal__container">
        <h2 className="modal__title">Sign-in Verification</h2>
        {!otpSent && (
          <>
            <span className="modal__subtitle block mb-[30px]">
              Kindly select where your OTP should be sent to:-
            </span>
            <div className="rounded-[10px] flex border shadow-md border-[#36363680] h-[50px]">
              <div className="h-full flex items-center justify-center w-[50px] border-r border-[#36363680]">
                <img src="/assets/images/mail.svg" alt="" />
              </div>
              <input
                type="email"
                value={email}
                className="h-full flex-1 focus:outline-none pl-[17px] text-[#363636C2] font-[500] text-[18px]"
              />
            </div>
            <button onClick={() => setOtpSent(true)} className="modal__action">
              PROCEED
            </button>
          </>
        )}
        {otpSent && (
          <>
            <span className="modal__subtitle block mb-[30px]">
              Please verify your sign in with the OTP sent to {email}
            </span>
            <div className="flex items-center justify-between">
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                containerStyle={{ width: "100%", gap: "12%" }}
                // renderSeparator={<span className="" />}
                renderInput={(props) => (
                  <input
                    {...props}
                    maxLength={1}
                    type="text"
                    className="border drop-shadow-otp border-[#E1E1E1] flex-1 h-[50px] rounded-[10px]"
                  />
                )}
              />
            </div>
            <p className="underline mt-[5px]">Resend code</p>

            <button onClick={submitAction} className="modal__action">LOGIN</button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default LoginVerification;
