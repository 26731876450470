import { Modal } from "@mui/material";
import { CustomModalProps } from "../../../types";
import React from "react";

const CheckoutModal: React.FC<CustomModalProps> = ({ open, handleClose, submitAction }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <div className="w-[90vw] sm:w-[830px] absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%] overflow-hidden bg-white pb-[54px] sm:pt-12 px-6 sm:px-[100px] text-center card-shadow rounded-lg text-textBlack">
        <img
          src="/assets/images/checkout-bg.png"
          className="absolute top-0 z-[5] left-0 opacity-[12%] w-full  h-full object-cover"
          alt=""
        />
        <img src="/assets/images/nidcom.png" className="mx-auto mt-12" alt="log0" />
        <p className="text-center text-primary font-semibold text-[14px] mb-4 mt-1">
          Simplifying Property Purchases in Nigeria
        </p>
        <div className="card-shadow bg-[#F8F8F8] relative rounded-[13px] z-[1000] pt-5 pb-[33px] px-[26px] text-textBlack font-medium text-[15px]">
          <h3>
            Review Your Commitment Before <br /> Proceeding
          </h3>
          <p className="mt-[26px] font-medium text-[12px] text-justify sm:text-left">
            You’re about to complete your property purchase! Before you proceed,
            please ensure you fully understand the condition of this
            transaction. <br /> <br /> By proceeding, you agree to complete the payment as per
            the selected payment plan. Your first payment is required
            immediately upon clicking Continue. <br /> <br /> If payment is not made within 3
            days, a penalty of 0.5% will be applied to the outstanding balance.
            Failure to meet the payment deadlines can lead to transaction
            cancellation. <br /> <br /> Please note, payments are non-refundable unless
            explicitly stated in the refund policy. <br /> <br /> By proceeding you confirm
            that you have reviewed all legal documents and are aware of your
            financial obligations tied to this property.
          </p>
        </div>
        <span className="underline text-textBlack mt-[14px] mb-3 block text-[11px] font-medium">
          Need help? Contact our support team before proceeding
        </span>
        <div className="flex z-10 relative gap-[21px] sm:px-16">
          <button
            onClick={handleClose}
            className="outline-button sm:w-[calc(100%-250px)] mx-auto bg-white"
          >
            CANCEL
          </button>
          <button
            onClick={submitAction}
            className="filled-button sm:w-[calc(100%-250px)] mx-auto"
          >
            CONTINUE
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CheckoutModal;
