import React from "react";

const CustomBadge = ({
  mode,
  style,
  nocase,
  children,
}: {
  mode?: "success" | "error" | "info";
  style?: any;
  nocase?: boolean;
  children: JSX.Element | string;
}) => {
  return (
    <span
      className={`badge _${mode}`}
      style={{ ...style, ...(nocase ? { textTransform: "none" } : {}) }}
    >
      {children}
    </span>
  );
};

export default CustomBadge;
