import React from "react";
import { LoaderFunctionArgs, Navigate, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import axios from "axios";

import UnprotectedRoute from "./views/common/global/UnprotectedRoute";
import ProtectedRoute from "./views/common/global/ProtectedRoute";

import {
  BuyerSignIn as BuyerSignInView,
  BuyerSignUp as BuyerSignUpView,
  BuyerHome as BuyerHomeView,
  PropertyDetails as PropertyDetailsView,
  PropertyPurchase as PropertyPurchaseView,
  PurchaseCheckout as PurchaseCheckoutView,
  PropertySearch as PropertySearchView,
  BuyerDashboard as BuyerDashboardView,
  TermsAndConditions as TermsAndConditionsView,
  PrivacyPolicy as PrivacyPolicyView,
  BuyerTransactions as BuyerTransactionsView,
  BuyerSavedItems as BuyerSavedItemsView,
  BuyerProperties as BuyerPropertiesView,
  About as AboutView,
  ForgotPassword as ForgotPasswordView,
  Faq as FaqView,
} from "./views";
import apiRoutes from "./views/common/utils/apiRoutes";
import { ListingInterface } from "./types";
import { USER_TYPES } from "./views/common/utils/constants";
import LoggedOutRoute from "./views/common/global/LoggedOutRoute";

const BuyerRoutes = () => {
  const { isAuthenticated, user } = useSelector(
    (state: RootState) => state.user
  );

  const listingLoader = async ({
    params,
  }: LoaderFunctionArgs): Promise<ListingInterface> => {
    const listingId = params.listingId;
    const response = await axios.get(
      `${apiRoutes.buyerListing.view}/${listingId}`
    );
    const data = response.data.data;
    return data;
  };
  let dashboardUrl = "";

  if (user && "user-types" in user) {
    // @ts-ignore
    const userType = user["user-types"][0].type;
    if (userType === "buyer") dashboardUrl = "/dashboard/my-properties";
    else if (userType === "seller") dashboardUrl = "/seller/dashboard";
    else dashboardUrl = "/admin/dashboard";
  }

  return (
    <>
      <Route
        path="/"
        element={
          <UnprotectedRoute isAuthenticated={isAuthenticated}>
            <BuyerHomeView />
          </UnprotectedRoute>
        }
      />
      <Route
        path={`/sign-in`}
        element={
          <LoggedOutRoute
            isAuthenticated={isAuthenticated}
            redirectUrl={dashboardUrl}
          >
            <BuyerSignInView />
          </LoggedOutRoute>
        }
      />
      <Route
        path={`/sign-up`}
        element={
          <LoggedOutRoute
            isAuthenticated={isAuthenticated}
            redirectUrl={dashboardUrl}
          >
            <BuyerSignUpView />
          </LoggedOutRoute>
        }
      />
      <Route
        path="/reset-password/:id/:key"
        element={
          <LoggedOutRoute
            isAuthenticated={isAuthenticated}
            redirectUrl={dashboardUrl}
          >
            <ForgotPasswordView />
          </LoggedOutRoute>
        }
      />
      <Route
        path="/listings/:listingId"
        element={
          <UnprotectedRoute isAuthenticated={isAuthenticated}>
            <PropertyDetailsView />
          </UnprotectedRoute>
        }
        loader={listingLoader}
      />
      <Route
        path="/property-search/:searchText"
        element={
          <UnprotectedRoute isAuthenticated={isAuthenticated}>
            <PropertySearchView />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/property-search"
        element={
          <UnprotectedRoute isAuthenticated={isAuthenticated}>
            <PropertySearchView />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/about"
        element={
          <UnprotectedRoute isAuthenticated={isAuthenticated}>
            <AboutView />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/faqs"
        element={
          <UnprotectedRoute isAuthenticated={isAuthenticated}>
            <FaqView />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <UnprotectedRoute isAuthenticated={isAuthenticated}>
            <PrivacyPolicyView />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/terms-and-conditions"
        element={
          <UnprotectedRoute isAuthenticated={isAuthenticated}>
            <TermsAndConditionsView />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/buy-property"
        element={
          <UnprotectedRoute isAuthenticated={isAuthenticated}>
            <PropertyPurchaseView />
          </UnprotectedRoute>
        }
      />
      <Route
        path="/property-checkout"
        element={
          <UnprotectedRoute isAuthenticated={isAuthenticated}>
            <PurchaseCheckoutView />
          </UnprotectedRoute>
        }
      />
      {/* <Route
        path="/dashboard"
        element={
          <ProtectedRoute
            section={USER_TYPES.BUYER}
            isAuthenticated={isAuthenticated}
          >
            <BuyerDashboardView />
          </ProtectedRoute>
        }
      /> */}
      {/* <Route
        path="/dashboard/sample-items"
        element={
          <ProtectedRoute
            section={USER_TYPES.BUYER}
            isAuthenticated={isAuthenticated}
          >
            <BuyerDashboardView />
          </ProtectedRoute>
        }
      /> */}
      <Route
        path="/dashboard/transactions"
        element={
          <ProtectedRoute
            section={USER_TYPES.BUYER}
            isAuthenticated={isAuthenticated}
          >
            <BuyerTransactionsView />
          </ProtectedRoute>
        }
      />{" "}
      <Route
        path="/dashboard/my-properties"
        element={
          <ProtectedRoute
            section={USER_TYPES.BUYER}
            isAuthenticated={isAuthenticated}
          >
            <BuyerPropertiesView />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/saved-properties"
        element={
          <ProtectedRoute
            section={USER_TYPES.BUYER}
            isAuthenticated={isAuthenticated}
          >
            <BuyerSavedItemsView />
          </ProtectedRoute>
        }
      />
    </>
  );
};

export default BuyerRoutes;
