export async function generateUniqueID(input: string) {
    // Step 1: Combine input with current datetime
    const currentDatetime = new Date().toISOString();
    const combinedString = `${input}-${currentDatetime}`;
    
    // Step 2: Hash the combined string (using SHA-256)
    const encoder = new TextEncoder();
    const data = encoder.encode(combinedString);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  
    // Step 3: Convert hash to hexadecimal and slice the first 10 characters
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    
    // Step 4: Return the first 10 characters as the unique ID
    return hashHex.slice(0, 10);
  }