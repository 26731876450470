import HomeLayout from "../../commonComponents/HomeLayout";

const Policy = () => {
  return (
    <HomeLayout>
      <section className="items-center px-8 sm:px-10 lg:px-[174px] pt-[30px] mx-auto max-w-[1300px]">
        <h2 className="h2 text-center">Data Privacy Policy</h2>
        <p className="body-description mt-8 text-justify">
          At www.homeandabroad.ng we fully understand that your personal
          information is important to you. Your trust is important to us, and we
          are committed to ensuring the safety of your personal data on this
          website. This data privacy policy provides information on how your
          personal data will be collected and processed when you visit our
          website. <br />
          <br />
          Persons under the age of 18 are not allowed to use this website
          without the consent and supervision of their parents or guardians.
        </p>
        <br />
        <div className="body-description">
          <p>
            <strong>1. Scope of Application</strong> <br />
            This data privacy policy will apply to users of the
            www.homeandabroad.ng website (customers and sellers)
          </p>
          <br />
          <p>
            <strong>2. The Data We Collect About You</strong> <br />
            Personal data is any information that can be directly or indirectly
            used to identify a specific individual. We collect your information
            to process transactions, provide you with service requests and
            updates, and for any other purpose necessary to facilitate your
            transactions.
          </p>{" "}
          <br />
          <p>The personal information we collect includes:</p> <br />
          <p>
            <strong>3. Information you provide:</strong>
          </p>
          <p className="pl-10">
            1. Information you provide to us: We receive and store the
            information you provide to us including your identity data, contact
            data, delivery address, and financial data. These types of personal
            data may include: <br /> 2. contact details (such as your name,
            postal addresses, phone numbers and email addresses), <br /> 3.
            demographic information (such as your date of birth, age or age
            range and gender), <br /> 4. online registration information (such
            as your password and other authentication information) <br /> 5.
            payment information (such as your credit card information and
            billing address) <br /> 6. information provided as part of online
            questionnaires (such as responses to any customer satisfaction
            surveys or market research),
          </p>{" "}
          <p>
            We receive and store the information you provide to us on the
            website. <br /> You provide us with your personal information when
            you register to use our website and complete transactions with such
            information.
          </p>{" "}
          <br />
          <p>
            <strong>4. What We Use Your Data For:</strong> <br /> Your personal
            information is collected to:
          </p>
          <ul className="list-disc pl-10">
            <li>
              authenticate the users of the website (customers and sellers on
              the platform).
            </li>{" "}
            <li>process payments.</li>
            <li>
              communicate with you in relation to the home and abroad services
              through channels such as (email).
            </li>{" "}
            <li>
              Comply with legal requirements. We collect sellers' information
              such as registered place of business, and bank account details for
              authentication of the seller and other purposes.
            </li>
          </ul>{" "}
          <p>
            Notwithstanding the above-listed reasons for collecting your
            personal information, you will be notified when your personal
            information might be shared with a third party, and you will have
            the opportunity to choose not to share the information.
          </p>
          <br />
          <p>
            <strong>5. Cookies and other identifiers</strong> <br /> A cookie is
            a small file of letters and numbers that we put on your computer or
            mobile phone. It helps us distinguish you from other users of our
            website and it helps us to provide you with an enhanced browsing
            experience.
          </p>
          <br />
          <p>
            <strong>6. How we share your personal information</strong> <br />{" "}
            The customer’s personal information is shared with the seller to:
          </p>
          <ul className="list-disc pl-10">
            <li>
              process transactions on any of the properties listed on the
              website.
            </li>{" "}
            <li> authenticate the buyer of the property</li>
            <li>
              {" "}
              enable the direct delivery of title documents to the buyer after
              the transactions have been finalized when necessary.{" "}
            </li>{" "}
            <li>
              {" "}
              detect fraud: We will release account and other personal data to
              other companies and organizations including law enforcement
              agencies for fraud protection and credit risk reduction, and to
              comply with applicable laws
            </li>
          </ul>{" "}
          <br />
          <p>The seller’s information will be shared with the customer to:</p>
          <ul className="list-disc pl-10">
            <li>
              to enable the customer to identify and authenticate the seller's
              information.
            </li>
            <li>
              to enable the customer to verify information on any desired
              property listed on the website.
            </li>
          </ul>
          <p>
            We may need to transfer your data to locations in another country.
            We shall take all necessary steps to ensure the protection of your
            personal data.
          </p>
          <br />
          <p>
            <strong>7. Data Security</strong> <br />
            We have taken all necessary steps to ensure that your data is
            protected and used only for the purposes set out in this privacy
            policy. We will ensure that your data is not lost, used, or accessed
            in an unauthorized manner, altered, or disclosed to unauthorized
            third parties.
          </p>
          <br />
          <p>
            <strong>8. Your Legal Rights </strong> <br />
            You will have the right to:
          </p>
          <ul className="list-disc pl-10">
            <li> access, correct, and erase your personal information </li>
            <li>
              {" "}
              object to or restrict the processing of your personal data{" "}
            </li>
            <li>
              {" "}
              ask that we transfer your personal data to a third party, and{" "}
            </li>
            <li> the right to permanently delete your data. </li>
          </ul>
          <p>
            If you wish to delete your data from our website, you can choose the
            option of closing your account. Once your account is closed, all
            products and services that you accessed on the website through your
            account will no longer be available.
          </p>
          <br />
          <p>
            <strong>9. Consent </strong> <br />
            Your personal information will only be processed when you consent to
            our Terms and Conditions of use of this website
          </p>
          <br />
          <p>
            <strong>10. Contact and Inquiry </strong> <br />
            If you have any questions or concerns about the www.homeandabroad.ng
            website or you require more information on how we process your
            personal data or wish to exercise your legal rights in respect of
            your personal data, please send all your questions, or concerns to  {" "}
            <a href="mailto:support@homeandabroad.ng" className="text-primary hover:opacity-75">support@homeandabroad.ng</a>
          </p>
        </div>
      </section>
    </HomeLayout>
  );
};

export default Policy;
