import React from "react";
import { Typography } from "@mui/material";
import { PaginationIconNext, PaginationIconPrevious } from "../../LayoutIcons";

const CustomTablePagination = ({
  handleChangePage,
  currentPageNumber,
  totalPages,
}: {
  handleChangePage: (event: unknown, newPage: number) => void;
  currentPageNumber: number;
  totalPages: number;
}) => {
  const PageUnit = ({ pageNumber }: { pageNumber: number }) => (
    <div
      className={`_number ${
        pageNumber === currentPageNumber + 1 ? "_active" : ""
      }`}
      onClick={(e) => handleChangePage(e, pageNumber - 1)}
    >
      <Typography>{pageNumber}</Typography>
    </div>
  );

  return (
    <div className="custom-table-pagination">
      <div
        className={`_direction ${!currentPageNumber ? "_disabled" : ""}`}
        onClick={
          !currentPageNumber
            ? () => {}
            : (e) => handleChangePage(e, --currentPageNumber)
        }
      >
        <PaginationIconPrevious />
        <Typography>Previous</Typography>
      </div>
      <div className="_numbers">
        {new Array(totalPages || 0)
          .fill("null")
          .map((nothing: null, pageNumber: number) => (
            <PageUnit key={pageNumber} pageNumber={pageNumber + 1} />
          ))}
      </div>
      <div
        className={`_direction ${
          currentPageNumber >= totalPages - 1 ? "_disabled" : ""
        }`}
        onClick={
          currentPageNumber >= totalPages - 1
            ? () => {}
            : (e) => handleChangePage(e, ++currentPageNumber)
        }
      >
        <Typography>Next</Typography>
        <PaginationIconNext />
      </div>
    </div>
  );
};

export default CustomTablePagination;
