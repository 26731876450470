import { useState } from "react";
import useInput from "../../../../hooks/useInput";
import { Navbar } from "../../commonComponents/HomeLayout";
import PurchaseRequest from "../../modals/PurchaseRequest";
import CheckoutModal from "../../modals/CheckoutModal";

const PurchaseCheckout = () => {
  const cardNumber = useInput("");
  const expiryDate = useInput("");
  const cvv = useInput("");
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <Navbar />
      <div className="flex flex-col sm:flex-row pb-5 sm:pb-0 px-6 sm:px-[80px] gap-y-10 gap-x-[15px] bg-[#FBFBFB]">
        <div className="flex-1">
          <div className="flex gap-x-[22px] mb-[9px]">
            <img src="/assets/images/arrow-left.svg" alt="" />
            <p className="text-[16px] text-textBlack">Go back</p>
          </div>
          <h3 className="h3">Purchase Checkout</h3>
          <div className="card-shadow px-6 sm:px-[30px] py-[25px] mt-[30px] mb-[15px]">
            <h3 className="font-medium text-[18px] border-b pb-2">
              Basic information
            </h3>
            <div className="flex flex-wrap gap-y-6 justify-between mt-[35px]">
              <div>
                <span className="text-[#5E5E5E] font-medium text-[16px]">
                  Full name
                </span>
                <p className="mt-2 font-medium text-[18px] text-black">
                  Samantha Balenciaga
                </p>
              </div>
              <div>
                <span className="text-[#5E5E5E] font-medium text-[16px]">
                  Email address
                </span>
                <p className="mt-2 font-medium text-[18px] text-black">
                  sambalenciaga@gmail.com
                </p>
              </div>
              <div>
                <span className="text-[#5E5E5E] font-medium text-[16px]">
                  Phone number
                </span>
                <p className="mt-2 font-medium text-[18px] text-black">
                  +234-812345678
                </p>
              </div>
            </div>
          </div>
          <div className="card-shadow px-6 sm:px-[30px] py-[25px] mt-[30px] mb-[15px]">
            <h3 className="font-medium text-[18px] border-b pb-2">
              Payment information
            </h3>
            <div className="mt-[35px] mb-10">
              <label htmlFor="cardNumber" className="custom__label">
                Card Number
              </label>
              <input
                type="text"
                onChange={cardNumber.onChange}
                value={cardNumber.value}
                id="cardNumber"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div className="grid grid-cols-2 gap-x-[25px]">
              <div>
                <label htmlFor="expiryDate" className="custom__label">
                  Expiry Date
                </label>
                <input
                  type="text"
                  onChange={expiryDate.onChange}
                  placeholder="MM/YYYY"
                  value={expiryDate.value}
                  id="expiryDate"
                  className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
                />
              </div>
              <div>
                <label htmlFor="cvv" className="custom__label">
                  CVV
                </label>
                <input
                  type="text"
                  placeholder="---"
                  onChange={cvv.onChange}
                  value={cvv.value}
                  id="cvv"
                  className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
                />
              </div>
            </div>
            <div className="my-5 flex items-center gap-x-[13px]">
              <input type="checkbox" className="shrink-0" name="" id="checkbox" />
              <label htmlFor="checkbox" className="text-[14px] text-textBlack text-justify sm:text-left">
                By agreeing to this purchase, you acknowledge that you have
                reviewed and accepted the terms and conditions of sale.
              </label>
            </div>
            <button onClick={() => setShowModal(true)} className="filled-button w-full">CHECKOUT</button>
          </div>
        </div>
        <section className="sm:w-[561px] py-[15px] px-6 sm:px-[30px] card-shadow">
          <img src="/assets/images/property7.png" className="w-full sm:h-[354px]" alt="" />
          <div className="mt-[25px] text-textBlack">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-[#686868] text-[16px]">Price</p>
                <p className="mt-[5px] font-semibold text-[28px]">$821,540</p>
              </div>
              <div className="flex gap-x-[22px]">
                <span className="font-medium text-[22px] text-textBlack">
                  4.5
                </span>
                <img src="/assets/images/star-filled.svg" alt="" />
              </div>
            </div>
            <div className="flex items-center mt-[30px] pt-10 border-t">
              <img src="/assets/images/diaspora.png" alt="" />
              <div className="ml-[18px] mr-auto">
                <p className="text-textBlack font-medium text-[16px]">
                  DIASPORA
                </p>
                <p className="text-[#686868] text-[12.5px]">Realty</p>
              </div>
              <div className="flex gap-x-8">
                <img src="/assets/images/mail3.svg" alt="" />
                <img src="/assets/images/phone2.svg" alt="" />
              </div>
            </div>
            <span className="text-[16px] text-[#686868] block mt-[30px] mb-[5px]">
              Property name
            </span>
            <h3 className="font-semibold text-[16px] text-textBlack ">
              Azure Heights Apartments
            </h3>
            <span className="text-[16px] text-[#686868] block mt-[30px] mb-[5px]">
              Location
            </span>
            <p className="text-[16px] font-semibold ">
              Azure Heights Apartments, 15 Adeola Odeku Street, Victoria Island,
              Lagos, Nigeria
            </p>
            <div className="flex flex-wrap gap-y-4 justify-between mt-[30px] text-textBlack">
                <div className="flex items-center gap-x-[9px] text-[14.67px]">
                    <img src="/assets/images/bathroom.svg" alt="" />
                    <span>4 bathrooms</span>
                </div>
                <div className="flex items-center gap-x-[9px] text-[14.67px]">
                    <img src="/assets/images/bedroom.svg" alt="" />
                    <span>3 bedrooms</span>
                </div>
                <div className="flex items-center gap-x-[9px] text-[14.67px]">
                    <img src="/assets/images/distance.svg" alt="" />
                    <span>250sqkm</span>
                </div>
            </div>
          </div>
        </section>
        <CheckoutModal 
            open={showModal}
            handleClose={() => setShowModal(false)}
        />
      </div>
    </>
  );
};

export default PurchaseCheckout;
