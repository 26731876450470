import React from "react";
import { Route } from "react-router-dom";
import UnprotectedRoute from "./views/common/global/UnprotectedRoute";
import ProtectedRoute from "./views/common/global/ProtectedRoute";

import {
  AdminSignin as AdminSigninView,
  AdminDashboard as AdminDashboardView,
  AdminPropertyListings as AdminPropertyListingsView,
  AdminPropertyListingDetails as AdminPropertyListingDetailsView,
  AdminTransactions as AdminTransactionsView,
  AdminDevelopers as AdminDevelopersView,
} from "./views";
import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import { USER_TYPES } from "./views/common/utils/constants";

const AdminRoutes = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.user);

  return (
    <>
      <Route
        path={`/admin/signin`}
        element={
          <UnprotectedRoute>
            <AdminSigninView />
          </UnprotectedRoute>
        }
      />
      <Route
        path={`/admin`}
        element={
          <ProtectedRoute
            section={USER_TYPES.ADMIN}
            isAuthenticated={isAuthenticated}
          >
            <AdminDashboardView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/admin/dashboard`}
        element={
          <ProtectedRoute
            section={USER_TYPES.ADMIN}
            isAuthenticated={isAuthenticated}
          >
            <AdminDashboardView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/admin/property-listings`}
        element={
          <ProtectedRoute
            section={USER_TYPES.ADMIN}
            isAuthenticated={isAuthenticated}
          >
            <AdminPropertyListingsView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/admin/property-listings/details`}
        element={
          <ProtectedRoute
            section={USER_TYPES.ADMIN}
            isAuthenticated={isAuthenticated}
          >
            <AdminPropertyListingDetailsView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/admin/transactions`}
        element={
          <ProtectedRoute
            section={USER_TYPES.ADMIN}
            isAuthenticated={isAuthenticated}
          >
            <AdminTransactionsView />
          </ProtectedRoute>
        }
      />
      <Route
        path={`/admin/developers`}
        element={
          <ProtectedRoute
            section={USER_TYPES.ADMIN}
            isAuthenticated={isAuthenticated}
          >
            <AdminDevelopersView />
          </ProtectedRoute>
        }
      />
    </>
  );
};

export default AdminRoutes;
