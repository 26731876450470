import { CloseOutlined } from "@mui/icons-material";
import { Box, Modal } from "@mui/material";

const CustomModal = ({
  showModal,
  onCloseModal,
  children,
}: {
  showModal: boolean;
  onCloseModal: () => void;
  children: JSX.Element;
}) => {
  return (
    <Modal
      open={showModal}
      onClose={onCloseModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="page-modal">
        <div className="modal-close-button" onClick={onCloseModal}>
          <CloseOutlined />
        </div>
        {children}
      </Box>
    </Modal>
  );
};

export default CustomModal;
