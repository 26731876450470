import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AccountVerification from "../../modals/AccountVerification";
import { signUpUser } from "../../../../apis/accounts/auth";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { AppDispatch, RootState } from "../../../../store/store";
import { signUpFailure } from "../../../../store/slices/buyer/userSlice";
import { Checkbox } from "@mui/material";

function SignUp() {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [formInput, setFormInput] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password1: "",
    password2: "",
    phoneNumber: "",
    companyName: "",
    companyNumber: "",
  });

  const [displayModals, setDisplayModals] = useState({
    accountVerification: false,
  });

  const lowercaseRegex = /[a-z]/;
  const hasLowerCase = lowercaseRegex.test(formInput.password1);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const emailIsValid = emailRegex.test(formInput.email);
  const uppercaseRegex = /[A-Z]/;
  const hasUpperCase = uppercaseRegex.test(formInput.password1);
  const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
  const hasSpecialCharacter = specialCharRegex.test(formInput.password1);
  const passwordMatch = formInput.password1 === formInput.password2;

  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();

  const openModal = (modalName: string) =>
    setDisplayModals((prev) => ({ ...prev, [modalName]: true }));
  const closeModal = (modalName: string) =>
    setDisplayModals((prev) => ({ ...prev, [modalName]: false }));
  const closeAllModal = () =>
    setDisplayModals({
      accountVerification: false,
    });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormInput((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const fieldsUnfilled =
    !formInput.email ||
    !formInput.first_name ||
    !formInput.last_name ||
    !phoneNumber ||
    !formInput.password1 ||
    !formInput.password2;

  const [errorMsg, setErrorMsg] = useState("");

  const signUp = async () => {
    setErrorMsg("");
    if (fieldsUnfilled) {
      setErrorMsg("Please fill the required fields");
      return;
    }
    if (!passwordMatch) return setErrorMsg("Passwords do not match");
    else setErrorMsg("");
    if (!emailIsValid) return setErrorMsg("Enter a valid Email");
    if (
      !hasLowerCase ||
      !hasUpperCase ||
      !hasSpecialCharacter ||
      formInput.password1.length < 12
    )
      return setErrorMsg("Ensure password meet required format");
    setIsLoading(true);
    const isSuccess = await dispatch(
      signUpUser(
        formInput.email,
        formInput.first_name,
        formInput.last_name,
        phoneNumber || "",
        "NG",
        formInput.password1,
        formInput.password2
      )
    );
    setIsLoading(false);
    isSuccess && openModal("accountVerification");
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const routeToLogin = () => navigate("/sign-in");
  const { loading, error } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    return () => {
      dispatch(signUpFailure([""]));
    };
  }, [dispatch]);

  return (
    <div className="pt-10 relative pb-8">
      <img
        src="/assets/images/background.svg"
        className="absolute top-0 left-0 z-[-1] h-full w-full object-cover"
        alt=""
      />
      <div className="flex space-x-4 items-center justify-center">
        <Link to="/" className="contents">
          <img className="h-[40px]" src="/assets/images/logo.svg" alt="" />
          <div className="flex flex-col items-center italic uppercase text-[#084E14] font-[600] text-[16px]">
            <h4>Home</h4>
            <div className="flex space-x-1 items-center">
              <div className="w-[54px] h-[2px] bg-custom-gradient" />
              <span className="bg-custom-gradient text-[16px] bg-clip-text text-transparent">
                &
              </span>
              <div className="w-[54px] h-[2px] bg-custom-gradient" />
            </div>
            <h4>Abroad</h4>
          </div>
        </Link>
      </div>
      <h3 className="text-primary text-center font-[500] text-[16px]">
        Simplifying Property Purchases in Nigeria
      </h3>
      <section className="mt-[20px] bg-white px-[35px] pt-[10px] pb-5 shadow-md rounded-[20px] mx-auto max-w-[1000px]">
        <div
          onClick={handleGoBack}
          className="flex items-center w-fit mt-3 duration-300 space-x-3 hover:translate-x-2 cursor-pointer"
        >
          <img
            src="/assets/images/chevron-left.svg"
            className="opacity-55"
            alt=""
          />
          <span className="text-lg text-textBlack2">Back</span>
        </div>
        <div className="mt-8">
          <h2 className="text-textBlack font-[600] text-[18px]">
            Create a Buyer Account
          </h2>
          <p className="text-textGrey text-[14px]">
            Fill all input fields correctly
          </p>
          <div className="grid sm:grid-cols-2 gap-[15px] mt-[26px]">
            <div>
              <label htmlFor="last_name" className="custom__label">
                First Name*
              </label>
              <input
                value={formInput.last_name}
                onChange={handleInputChange}
                type="text"
                id="last_name"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div>
              <label htmlFor="first_name" className="custom__label">
                Last Name*
              </label>
              <input
                type="text"
                onChange={handleInputChange}
                value={formInput.first_name}
                id="first_name"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div>
              <label htmlFor="email" className="custom__label">
                Email*
              </label>
              <input
                value={formInput.email}
                onChange={handleInputChange}
                id="email"
                type="email"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div>
              <label htmlFor="phoneNumber" className="custom__label">
                Phone Number*
              </label>
              <PhoneInput
                placeholder="Enter phone number"
                international
                value={phoneNumber}
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1] focus:outline-none"
                onChange={setPhoneNumber}
                defaultCountry="NG"
              />
            </div>
            <div>
              <label htmlFor="password1" className="custom__label">
                Password*
              </label>
              <input
                id="password1"
                onChange={handleInputChange}
                value={formInput.password1}
                type="password"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
              {formInput.password1 && (
                <div className="flex flex-col gap-y-1 text-red-500">
                  <span className={`${hasLowerCase && "hidden"}`}>
                    Password must contain small letter
                  </span>
                  <span
                    className={`${
                      formInput.password1.length >= 12 && "hidden"
                    }`}
                  >
                    Password must not be less than 12 characters
                  </span>
                  <span className={`${hasUpperCase && "hidden"}`}>
                    Password must contain capital letter
                  </span>
                  <span className={`${hasSpecialCharacter && "hidden"}`}>
                    Password must contain special characters
                  </span>
                </div>
              )}
            </div>
            <div>
              <label htmlFor="password2" className="custom__label">
                Confirm Password*
              </label>
              <input
                id="password2"
                onChange={handleInputChange}
                value={formInput.password2}
                type="password"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div>
              <label htmlFor="companyName" className="custom__label">
                Company Name (Optional)
              </label>
              <input
                id="companyName"
                onChange={handleInputChange}
                value={formInput.companyName}
                type="text"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
            <div>
              <label htmlFor="companyNumber" className="custom__label">
                Company Registration Number (Optional)
              </label>
              <input
                id="companyNumber"
                onChange={handleInputChange}
                value={formInput.companyNumber}
                type="text"
                className="w-full outline-none bg-[#F8F8F8] border h-[45px] rounded-[10px] pl-4 border-[#E1E1E1]"
              />
            </div>
          </div>
          <div className="mt-[30px] mb-[5px]">
            <div className="flex sm:justify-center items-center gap-x-2 mb-2 ml-[-9px]">
              <Checkbox
                checked={agreedToTerms}
                onChange={() => setAgreedToTerms(!agreedToTerms)}
              />
              <span className="italic">
                I have read and agree to homeandabroad.ng's{" "}
                <Link to="/terms-and-conditions" className="hover:opacity-80 text-primary font-semibold">
                  Terms of Service{" "}
                </Link>
                and{" "}
                <Link className="hover:opacity-80 text-primary font-semibold" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </span>
            </div>
            {errorMsg && (
              <span className="text-red-500 block text-center">{errorMsg}</span>
            )}
            {error &&
              error.map((msg) => (
                <span className="text-red-500 block text-center">{msg}</span>
              ))}
            <button
              disabled={isLoading || fieldsUnfilled || !agreedToTerms}
              onClick={signUp}
              className="uppercase block w-full mx-auto sm:max-w-[50%] bg-primary text-textWhite rounded-[10px] py-[10px] text-[14px] font-[600] disabled:opacity-35"
            >
              {isLoading ? <span className="loader"></span> : "Sign Up"}
            </button>
          </div>
        </div>

        {/* <p className="text-center text-[14px] text-black/50">
          Or Sign in using
        </p>
        <div className="flex flex-col sm:flex-row justify-center gap-x-5 gap-y-2 mt-2 mb-[10px]">
          <button className="flex w-full sm:w-fit justify-center sm:justify-start items-center space-x-7 rounded-[10px] py-[10px] pr-[25px] pl-3 border border-[#E1E1E1]">
            <img src="/assets/images/google.svg" alt="" />
            <span className="text-textBlack text-[14px] font-[400]">
              Login using Google
            </span>
          </button>
          <button className="flex w-full sm:w-fit justify-center sm:justify-start items-center space-x-7 rounded-[10px] py-[10px] pr-[25px] pl-3 border border-[#E1E1E1]">
            <img className="h-8" src="/assets/images/apple.svg" alt="" />
            <span className="text-textBlack text-[14px] mx-auto font-[400]">
              Login using Apple
            </span>
          </button>
        </div> */}
        <p className="text-center text-textBlack font-[500] text-[12px]">
          Already have an account?{" "}
          <span className="text-primary">
            <Link to="/sign-in">Sign In</Link>
          </span>
        </p>
      </section>
      <AccountVerification
        open={displayModals.accountVerification}
        handleClose={() => {}}
        submitAction={routeToLogin}
        email={formInput.email}
      />
    </div>
  );
}

export default SignUp;
