import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import React, { useState } from "react";
import { Popover, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ListingInterface, ListingsInterface } from "../../../types";
import { extractId } from "../../common/utils/helpers";
import {
  formatCurrency,
  CurrencyType,
} from "../../common/utils/formatCurrency";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import {
  postToggleSaveListing,
  toggleSaveListing,
} from "../../../apis/listings/buyer";
import LikeListing from "../../common/components/LikeListing";
import { DisplayAmount } from "../../common/components/CurrencySwitch";

const ListingCard = ({ listing }: { listing: ListingsInterface | ListingInterface }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const toggleLikeListing = async (state?: boolean) => {
    await postToggleSaveListing({
      id: listing.lid,
      url: listing.url,
    });
  };

  const showCurrencyPop = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className="property-card">
      <div className="h-[266px] relative overflow-hidden w-full rounded-t-[10px]">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          className="mySwiper h-full"
          pagination={true}
          modules={[Pagination]}
        >
          <CustomSwiperButton />
          {listing.pics.map((img) => (
            <SwiperSlide key={img.file}>
              <img
                onClick={() => navigate(`/listings/${listing.lid}`)}
                src={img.file}
                className="object-cover w-full h-full rounded-t-[10px]"
                alt={listing.name}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="absolute top-[10px] gap-x-2 left-[15px] right-[15px] flex items-center z-10">
          {/* <button className="bg-primary flex items-center space-x-3 rounded-[4px] py-[6px] px-[13px] text-white">
            <img className="" src="/assets/images/cart-white.svg" alt="" />
            <span>Add to cart</span>
          </button>
          <button className="bg-primary rounded-[4px] py-[6px] px-[13px] text-white">
            Compare
          </button> */}
          <LikeListing
            isLiked={listing.saved_by_user || false}
            doToggle={toggleLikeListing}
          />
        </div>
        <div className="absolute z-10 bg-gray-200 p-3 rounded-md right-4 bottom-10">
          {`Listed ${listing.period_used_in_the_market}`}
        </div>
      </div>
      <div className="shadow-lg relative py-5 h-fit px-[18.5px]">
        <div className="flex items-center h-fit">
          {/* <div className="flex items-center space-x-[2px]">
            <img src="/assets/images/filled-star.svg" alt="" />
            <img src="/assets/images/filled-star.svg" alt="" />
            <img src="/assets/images/filled-star.svg" alt="" />
            <img src="/assets/images/filled-star.svg" alt="" />
            <img src="/assets/images/outline-star.svg" alt="" />
          </div>
          </div> */}
        </div>
        {listing.status === "approved" && (
          <div
            className="flex h-full ml-3 shrink-0 space-x-1 items-center"
            style={{ justifyContent: "flex-end", zoom: 0.8 }}
          >
            <img src="/assets/images/verified.svg" alt="" />
            <span className="text-[#1570EF] text-[14px]">Verified</span>
          </div>
        )}
        <div className="flex justify-between items-center ">
          <Link to={`/listings/${listing.lid}`}>
            <h3 className="property-card__name">{listing.name}</h3>
          </Link>
        </div>
        <p className="property-card__address">{listing.state}</p>
        <div className="flex space-x-2 mb-1 text-[14px] sm:text-[16px]">
          <p className="text-[13px] text-textBlack2 my-1 whitespace-nowrap overflow-hidden text-ellipsis">
            {listing?.features?.map((feature, index: number, features) => (
              <React.Fragment key={index}>
                {/* @ts-ignore */}
                {feature.name} {index < features.length - 1 && <span>| </span>}
              </React.Fragment>
            ))}
            {!listing?.features?.length && <div>--</div>}
          </p>
        </div>
        <div className="flex flex-wrap gap-x-2 gap-y-1">
          <span className="property-card__pill">Certified Developer</span>
          <span className="property-card__pill">Verified Title Deed</span>
          <span className="property-card__pill">Fraud Protection</span>
          <span className="property-card__pill">Certified Land Ownership</span>
          <span className="property-card__pill">No Legal disputes</span>
        </div>
        <div className="flex flex-wrap text-[16px] mt-5">
          <span className="pr-2 border-r-2 border-[#CECECE] mr-2">
            {listing.bedrooms} beds
          </span>
          <span className="mr-auto">{listing.bathrooms} baths</span>
          <p className="font-bold">
            <DisplayAmount amount={listing.price} />
          </p>
        </div>
        <Link to={`/listings/${listing.lid}`}>
          <button className="filled-button !px-0 w-full mt-[17px]">
            VIEW DETAILS
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ListingCard;

const CustomSwiperButton = () => {
  const swiper = useSwiper();
  return (
    <div className="flex z-50 justify-between absolute w-full px-1 top-[48%]">
      <img
        onClick={() => swiper.slidePrev()}
        className="scale-x-[-1] bg-black bg-opacity-45 rounded-full cursor-pointer"
        src="/assets/images/right-arrow.svg"
        alt=""
      />
      <img
        onClick={() => swiper.slideNext()}
        className="bg-black bg-opacity-40 rounded-full cursor-pointer"
        src="/assets/images/right-arrow.svg"
        alt=""
      />
    </div>
  );
};
