import moment from "moment";
import { IPropertyFeatures } from "./interfaces";

export const logoutClearLocalStorage = () => {
  const currentCurrency = localStorage.defaultCurrency;
  localStorage.clear();
  sessionStorage.clear();

  //  restore selected currency
  if (currentCurrency) {
    localStorage.setItem("defaultCurrency", currentCurrency);
  }
};

export const formatDate = (dateInput: string) => {
  const inputMoment = moment(dateInput);
  if (inputMoment.isValid()) {
    return inputMoment.format("YYYY-MMM-DD");
  } else {
    return "--";
  }
};

export const convertToBytes = (quantity: number, unit: string) => {
  const units: any = {
    B: 1,
    KB: 1024,
    MB: 1024 * 1024,
    GB: 1024 * 1024 * 1024,
    TB: 1024 * 1024 * 1024 * 1024,
  };

  const normalizedUnit = unit.toUpperCase();

  if (!units[normalizedUnit]) {
    throw new Error("Invalid unit. Accepted units: B, KB, MB, GB, TB");
  }

  if (isNaN(quantity) || quantity < 0) {
    throw new Error(
      "Invalid quantity. Please provide a positive numeric value."
    );
  }

  return quantity * units[normalizedUnit];
};

export const convertFromBytes = (quantity: number) => {
  const units: any = {
    TB: 1024 * 1024 * 1024 * 1024,
    GB: 1024 * 1024 * 1024,
    MB: 1024 * 1024,
    KB: 1024,
    B: 1,
  };

  let index = 0;

  do {
    const entry = Object.entries(units)[index] as [string, number];
    const quotient = Math.round(quantity / entry[1]);
    if (quotient >= 1) {
      return `${quotient} ${entry[0]}`;
    }

    index++;
  } while (index < Object.entries(units).length);

  return quantity.toString();
};

export const convertInputDataToFormData = (
  data: any,
  imageFiles: File[],
  videoFiles: File[],
  docFiles: any
) => {
  const bodyFormData = new FormData();

  for (let key of Object.entries(data) as [string, any][]) {
    if (key[0] === "features") {
      for (const feature of key[1]) {
        bodyFormData.append("features", feature);
      }
    } else {
      bodyFormData.append(key[0], key[1]);
    }
  }

  for (let file of imageFiles) {
    // bodyFormData.append("images[]", file);
    bodyFormData.append("uploads", file);
  }
  for (let file of videoFiles) {
    // bodyFormData.append("videos[]", file);
    bodyFormData.append("uploads", file);
  }
  for (const key in docFiles) {
    bodyFormData.append(key, docFiles[key][0]);
  }

  return bodyFormData;
};

export const categorizeFeatures = (data: IPropertyFeatures[]) => {
  const featuresCategoryMapping: any = {
    exterior_features: "Exterior Features",
    interior_features: "Interior Features",
    kitchen_features: "Kitchen Features",
    views_and_location: "View & Location",
    accessibility_features: "Assessibility Features",
    additional_space: "Additional Space",
    amenities_and_facilities: "Amenities & Facilities",
    security_and_safety: "Security & Safety",
    smart_home_features: "Smart Home Features",
    energy_and_utilities: "Energy & Utilities",
  };

  const categorized: any = {};

  data?.forEach((feature: IPropertyFeatures) => {
    if (categorized[featuresCategoryMapping[feature.category]]) {
      categorized[featuresCategoryMapping[feature.category]].push(feature);
    } else {
      categorized[featuresCategoryMapping[feature.category]] = [feature];
    }
  });

  return categorized;
};

export const formatNumberString = (numberString?: string | number) => {
  if (!numberString) return "";

  if (typeof numberString === "number") {
    numberString = numberString.toString();
  }
  // Convert the string to a number
  const number = parseFloat(numberString);

  // Check if the conversion is valid
  if (isNaN(number)) {
    return "Invalid number";
  }

  // Format the number to 2 decimal places and add commas
  return number.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

export const extractId = (url: string): string | null => {
  const regex = /\/([a-f0-9-]+)\/?$/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

export const unslugText = (text: string) => {
  if (!text || typeof text !== "string") {
    return;
  }
  let unslugged = text.replace("-", " ");
  unslugged = text.replace("_", " ");

  return unslugged;
};
